import React, { memo, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NVehicleFuelType } from '../../../../../../types';
import {
  GET_ALL_VEHICLE_FUEL_TYPES,
  useVehicleFlueType,
} from '../../../../../../queries';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { UPDATE_VEHICLE_FUEL_TYPE } from '../../../../../../mutations';
import { VehicleFuelTypeFields } from '../components';
import { useHandleSubmit, useSetValues } from '../../../../../../libs/FForm';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderUpdateFuelTypeSidebar = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const vehicleFlueTypeId = useSearchParam(
    searchQuery,
    ESearchQuery.VEHICLE_FUEL_TYPE_ID,
  );
  const handleSubmit = useHandleSubmit();
  const setValues = useSetValues();

  const {
    vehicleFuelType,
    loading: isFetching,
    error,
  } = useVehicleFlueType({
    id: vehicleFlueTypeId,
  });

  const [updateVehicleFuelType, { loading }] = useMutation<
    NVehicleFuelType.Update.Output,
    NVehicleFuelType.Update.Input
  >(UPDATE_VEHICLE_FUEL_TYPE, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NVehicleFuelType.GetAll.Output>(
        { query: GET_ALL_VEHICLE_FUEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            vehicleFuelTypes: record.vehicleFuelTypes.map((vehicleFlueType) => {
              if (vehicleFlueType.id === data.updateVehicleFuelType?.id) {
                return data.updateVehicleFuelType;
              }
              return vehicleFlueType;
            }),
          };
        },
      );
    },
    onCompleted() {
      toast('Vehicle fuel type was successfully updated!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when updating a vehicle fuel type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  useEffect(() => {
    if (vehicleFuelType) {
      setValues(vehicleFuelType);
    }
  }, [vehicleFuelType, setValues]);

  const handleValidSubmit = useCallback(
    async (values: Omit<NVehicleFuelType.Update.Input['input'], 'id'>) => {
      if (vehicleFlueTypeId) {
        void updateVehicleFuelType({
          variables: {
            input: {
              id: vehicleFlueTypeId,
              ...values,
            },
          },
        });
      }
    },
    [vehicleFlueTypeId, updateVehicleFuelType],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!vehicleFuelType) {
    return <NoData title={`No vehicle fuel type data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="update vehicle fuel type" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <VehicleFuelTypeFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
