import { useSubscription } from '@apollo/client';
import { CREATE_USER_VEHICLE_USER_LINK_SUBSCRIPTION } from '../../../subscriptions';
import {
  CreateUserVehicleLinkSubscriptionOutput,
  GetAllUserVehiclesOutput,
} from '../../../types';
import { GET_ALL_USER_VEHICLES } from '../../../queries';

export const useUserVehicleLinkAddedSubscription = (tokenIsValid: boolean) => {
  useSubscription<CreateUserVehicleLinkSubscriptionOutput>(
    CREATE_USER_VEHICLE_USER_LINK_SUBSCRIPTION,
    {
      skip: !tokenIsValid,
      shouldResubscribe: true,
      onData({ client, data: { data } }) {
        if (!data) return;

        const newItem = data.createUserVehicleLinkSubscription;

        client.cache.updateQuery<GetAllUserVehiclesOutput>(
          { query: GET_ALL_USER_VEHICLES },
          (record) => {
            if (!record) return;

            return {
              getAllUserVehicles: [
                ...record?.getAllUserVehicles,
                {
                  id: newItem.id,
                  user: null,
                  vehicle: {
                    title: newItem.vehicle.title,
                  },
                },
              ],
            };
          },
        );
      },
    },
  );
};
