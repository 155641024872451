import { gql } from '@apollo/client';

export const GET_ALL_QUESTIONS_ANSWERS = gql`
  query {
    questionsAnswers {
      id
      answerDe
      answerEn
      questionDe
      questionEn
      descriptionDe
      descriptionEn
      backgroundColor
      titleColor
      descriptionColor
      buttonTitleColor
      circleColor
    }
  }
`;
