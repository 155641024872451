import { gql } from '@apollo/client';

export const UPDATE_USER_SUBSCRIPTION = gql`
  subscription {
    users {
      update {
        id
        email
        firstName
        lastName
        isVerifyEmail
        isVerifyPhoneNumber
        phoneNumber
        photoFileId
        licenceID
        userRole
        #        rating
        isBlocked
      }
    }
  }
`;
