import { gql } from '@apollo/client';

export const GET_ORDER_FIRST_PICK_UP_WAYPOINTS = gql`
  query {
    orderFirstPickUpWaypoints {
      id
      lat
      lng
      orderId
      waypointType
    }
  }
`;
