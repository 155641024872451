import { gql } from '@apollo/client';

export const CREATE_REVIEW_TAG = gql`
  mutation createReviewTag($input: CreateReviewTagInput!) {
    createReviewTag(input: $input) {
      id
      name
    }
  }
`;
