import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { ESearchQuery } from '../../../../constants/query.constant';
import { useSearchQuery } from '../../../../hooks';
import { VehicleLocation } from './VehicleLocation';
import { ESidebarPath } from '../../../../constants/sidebar.constant';
import { useVehicleLocations } from '../../../../queries';

export const VehicleLocations = memo(() => {
  const { push } = useHistory();
  const query = useSearchQuery();
  const userVehicleId = query.get(ESearchQuery.USER_VEHICLE_ID);

  const { vehicleLocations } = useVehicleLocations();

  const handleClick = useCallback(
    (userVehicleLinkId) => () => {
      push({
        search: `?${ESearchQuery.USER_VEHICLE_ID}=${userVehicleLinkId}&${ESearchQuery.USER_VEHICLE_ROUTE}=${userVehicleLinkId}&${ESidebarPath.USER_VEHICLE}`,
      });
    },
    [push],
  );

  return (
    <>
      {vehicleLocations.map((location) => (
        <VehicleLocation
          key={location.userVehicleLinkId}
          isSelectedExists={!!userVehicleId}
          isSelected={location.userVehicleLinkId === userVehicleId}
          onClick={handleClick(location.userVehicleLinkId)}
          {...location}
        />
      ))}
    </>
  );
});
