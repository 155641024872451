import { useContext } from 'react';
import { FContext } from '../context';

export const useSetSetter = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useSetSetter` must be used within a FProvider');
  }

  return context.setSetter;
};

export const useSetGetter = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useSetGetter` must be used within a FProvider');
  }

  return context.setGetter;
};

export const useSetValidator = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useSetValidator` must be used within a FProvider');
  }

  return context.setValidator;
};

export const useSetValues = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useSetValues` must be used within a FProvider');
  }

  return context.setValues;
};

export const useHandleSubmit = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useHandleSubmit` must be used within a FProvider');
  }

  return context.handleSubmit;
};

export const useHandleClear = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useHandleClear` must be used within a FProvider');
  }

  return context.handleClear;
};

export const useHandleReset = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useHandleReset` must be used within a FProvider');
  }

  return context.handleReset;
};

// export const useHandleClearValues = () => {
//   const context = useContext(FContext);
//
//   if (context === undefined) {
//     throw new Error('`useHandleClearValues` must be used within a FProvider');
//   }
//
//   return context.handleClearValues;
// };

export const useStatusSubmitted = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useStatusSubmitted` must be used within a FProvider');
  }

  return context.submitted;
};

export const useUnmountCallback = () => {
  const context = useContext(FContext);

  if (context === undefined) {
    throw new Error('`useUnmountCallback` must be used within a FProvider');
  }

  return context.unmountCallback;
};
