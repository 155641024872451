import { gql } from '@apollo/client';

export const ORDER_UPDATED_VEHICLE_SUBSCRIPTION = gql`
  subscription {
    orderUpdatedVehicle {
      id
      driver {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
        rating
      }
      vehicle {
        id
        title
        vehicleType {
          id
          fuelType {
            id
            titleEn
            titleDe
          }
          photoFileId
          titleEn
          titleDe
          shortDescriptionDe
          shortDescriptionEn
          longDescriptionDe
          longDescriptionEn
          journeyInc5MinPrice
          over2kmPerKmPrice
          pickUpOver5MinPrice
          pickUpOverTimeInSeconds
          stopoverPrice
          cancelPrice
          outsideServiceHoursPercent
          minCoastInc2kmPrice
          bootCapacityLiters
          orderLimit
          volumePerOrder
        }
      }
    }
  }
`;
