import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUserSidebar } from './RenderUserSidebar';

export const UserSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.USER} onCloseStart={handleClear} width={450}>
      <RenderUserSidebar />
    </Sidebar>
  );
});
