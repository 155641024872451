import React, { memo, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import { NChat, NUser } from '../../../../../../../../types';
import { ESearchQuery } from '../../../../../../../../constants/query.constant';
import { ProtectedImage } from '../../../../../../../../components/ProtectedImage';
import { ELocalStorage } from '../../../../../../../../constants/local-storage.constant';
import { SvgArchive, SvgTxtFile } from '../../../../../../../../assets/icons';
import { FILES_URL } from '../../../../../../../../constants';
import { findUnreadMessages } from '../../../../../../../../utilities/findUnreadMessages';
import { UIBadge } from '../../../../../../../../components/ui';
import { useMutation } from '@apollo/client';
import { ARCHIVE_CHAT } from '../../../../../../../../mutations/chats/archive-chat.mutation';
import { toast } from 'react-toastify';
import { GET_CHATS } from '../../../../../../../../queries';

type ChatProps = NChat.Entity & { profileId: string | null | undefined };

export const Chat = memo<ChatProps>(
  ({ id, type, order, messages, members, profileId, logsFileId }) => {
    const lastMessage = messages[messages.length - 1];
    const date = new Date(lastMessage?.createdAt ?? 0);
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');

    const [archiveChat, { loading }] = useMutation<
      NChat.Archive.Output,
      NChat.Archive.Input
    >(ARCHIVE_CHAT, {
      variables: {
        input: {
          id,
        },
      },
      update(cache, { data }) {
        if (!data) return;

        /* Update chat in the list */
        const addMessageToChat = (type: NChat.Type, next?: () => void) => () => {
          let found = false;

          cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
            {
              query: GET_CHATS,
              variables: {
                type,
              },
            },
            (record) => {
              if (!record) return;

              return {
                chats: record.chats.map((x) => {
                  if (x.id !== data.archiveChat.id) return x;

                  found = true;

                  return data.archiveChat;
                }),
              };
            },
          );

          if (!found && next) next();
        };

        addMessageToChat(
          NChat.Type.ORDER_DRIVER,
          addMessageToChat(
            NChat.Type.ORDER_SUPPORT,
            addMessageToChat(NChat.Type.EMPLOYEE),
          ),
        )();
      },
      onCompleted() {
        toast('Chat was successfully archived!', {
          type: 'success',
        });
      },
      onError(err) {
        toast(`Error (when archiving a chat): ${err.message}`, {
          type: 'error',
          autoClose: 2000,
        });
      },
    });

    const member = members?.find(({ userRole }) => {
      if (type === NChat.Type.ORDER_DRIVER) {
        return userRole === NUser.Role.CUSTOMER;
      }
      if (type === NChat.Type.ORDER_SUPPORT) {
        return userRole === NUser.Role.CUSTOMER;
      }
      return userRole === NUser.Role.EMPLOYEE_DRIVER;
    });

    const unreadMessages = useMemo(
      () => findUnreadMessages(messages, profileId),
      [messages, profileId],
    );

    const handleTo = useCallback(
      ({ search }: Location) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set(ESearchQuery.CHAT_ID, id);
        searchParams.set(ESearchQuery.CHAT_TYPE, type);

        return {
          search: searchParams.toString(),
        };
      },
      [id, type],
    );

    const handleIsActive = useCallback(
      (_, { search }: Location) => {
        const searchParams = new URLSearchParams(search);
        const selectedChatId = searchParams.get(ESearchQuery.CHAT_ID);
        return selectedChatId === id;
      },
      [id],
    );

    const onDownload = useCallback(async () => {
      if (!logsFileId) return;

      const accessToken = localStorage.getItem(ELocalStorage.ACCESS_TOKEN);
      const response = await fetch(
        `${FILES_URL}/${logsFileId}?accessToken=${accessToken}`,
      );
      const blob = await response.blob();

      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobURL;
      a.download = `Chat logs - ${id}`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(blobURL);
    }, [id, logsFileId]);

    if (!member) {
      return null;
    }

    return (
      <NavLink
        key={id}
        to={handleTo}
        isActive={handleIsActive}
        className="chat"
        activeClassName="selected"
      >
        <div className="chat__content">
          <div className="chat__avatar">
            {member.photoFileId ? (
              <ProtectedImage
                className="chat__avatar_user-photo"
                id={member.photoFileId}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div className="chat__avatar_short-name">
                {member.firstName[0] ?? ''}
                {member.lastName[0] ?? ''}
              </div>
            )}
          </div>
          <div className="chat__info">
            <div className="d-flex justify-content-between">
              <span className="chat__user-name">
                {order ? (
                  <>Order #{order.uniqueCode}</>
                ) : (
                  `${member.firstName} ${member.lastName}`
                )}
              </span>
              {lastMessage && (
                <span className="chat__last-message-time">
                  {hours}:{minutes}
                </span>
              )}
            </div>
            <div
              className="d-flex position-relative"
              style={{ flexGrow: 1, flexBasis: 0 }}
            >
              <span className="chat__last-message-text">{lastMessage?.text}</span>
              <UIBadge
                value={unreadMessages.length}
                position="right-right"
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="chat__footer">
          {!!messages.length && (
            <div className="chat__button" onClick={() => void archiveChat()}>
              {/*{loading ? (*/}
              <div className="chat__button-icon">
                {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <SvgArchive />
                )}
              </div>
              <span className="chat__button-title">
                {loading ? 'Archiving' : 'Archive'}
              </span>
            </div>
          )}
          {logsFileId && (
            <div className="chat__button" onClick={onDownload}>
              <div className="chat__button-icon">
                <SvgTxtFile />
              </div>
              <span className="chat__button-title">.txt</span>
            </div>
          )}
        </div>
      </NavLink>
    );
  },
);
