import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderUsersPage } from './RenderUsersPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const UsersPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Users')}</title>
    </Helmet>

    <UIBreadcrumbs title="Users" />

    <RenderUsersPage />
  </>
));
