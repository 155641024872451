import { NUser } from './users';

export namespace NChat {
  export interface Entity {
    id: string;
    type: Type;
    order: {
      id: string;
      uniqueCode: number;
    } | null;
    members: Member[];
    messages: Message.Entity[];
    logsFileId: string;
  }

  export enum Type {
    ORDER_DRIVER = 'ORDER_DRIVER',
    ORDER_SUPPORT = 'ORDER_SUPPORT',
    EMPLOYEE = 'EMPLOYEE',
  }

  export interface Member {
    id: string;
    firstName: string;
    lastName: string;
    photoFileId: string;
    userRole: NUser.Role;
  }

  export enum Tab {
    ORDER_DRIVER = 'order_driver',
    ORDER_SUPPORT = 'order_support',
    EMPLOYEE = 'employee',
  }

  /* Query */
  export namespace GetOne {
    export type Input = {
      input: {
        id: Entity['id'];
      };
    };

    export type Output = {
      chat: Entity;
    };
  }

  /* Query */
  export namespace GetMany {
    export type Input = {
      type: Type;
    };

    export type Output = {
      chats: Entity[];
    };
  }

  /* Mutation */
  export namespace Archive {
    export type Output = {
      archiveChat: Entity;
    };

    export type Input = {
      input: Pick<Entity, 'id'>;
    };
  }

  /* Messages */
  export namespace Message {
    export interface Entity {
      id: string;
      text: string;
      createdAt: number;
      user: Member;
      readersSeen: {
        userId: string;
        hasSeen: boolean;
      }[];
    }

    /* Mutation */
    export namespace UpdateMessagesSeen {
      export type Input = {
        input: {
          chatId: string;
        };
      };
      export type Output = {
        chatId: string;
        messageId: string;
      }[];
    }

    /* Mutation */
    export namespace Create {
      export type Input = {
        input: {
          chatId: NChat.Entity['id'];
          text: string;
        };
      };

      export type Output = {
        createMessage: Entity;
      };
    }

    /* Subscriptions */
    export namespace On {
      export namespace Added {
        export type Output = {
          messageAdded: Entity & { chat: Pick<NChat.Entity, 'id' | 'type'> };
        };
      }

      export namespace Seen {
        export type Output = {
          chatMessagesSeen: {
            chatId: string;
            userId: string;
          };
        };
      }
    }
  }
}
