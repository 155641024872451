import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUpdateParcelTypeSidebar } from './RenderUpdateParcelTypeSidebar';

export const UpdateParcelTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.UPDATE_PARCEL_TYPE} onClose={handleClear}>
      <RenderUpdateParcelTypeSidebar />
    </Sidebar>
  );
});
