import { useMemo } from 'react';
import { NSettings } from '../../types';
import { GET_SETTINGS } from '../settings';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NSettings.Get.Output> {}

export const useSettings = (options?: Params) => {
  const { data, loading } = useAppQuery<NSettings.Get.Output>(GET_SETTINGS, options);

  const settings = useMemo(() => data?.getSettings ?? null, [data?.getSettings]);

  return { settings, loading };
};
