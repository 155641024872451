import { useMemo } from 'react';
import { GET_BARCODE } from '../barcodes';
import { NBarcode } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NBarcode.GetOne.Output, NBarcode.GetOne.Input> {
  id: string | null | undefined;
}

export const useBarcode = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NBarcode.GetOne.Output,
    NBarcode.GetOne.Input
  >(GET_BARCODE, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    ...options,
  });

  const barcode = useMemo(() => data?.getBarcode ?? null, [data?.getBarcode]);

  return { barcode, loading, error };
};
