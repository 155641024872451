import React, { memo } from 'react';
import { NOrder } from '../types';

type OrderStatusBadgeProps = Pick<NOrder.Entity, 'orderStatus'>;

export const OrderStatusBadge = memo<OrderStatusBadgeProps>(({ orderStatus }) => {
  if (orderStatus === NOrder.Status.AVAILABLE) {
    return <span className="badge badge-soft-warning">Available</span>;
  }
  if (orderStatus === NOrder.Status.ACCEPTED) {
    return <span className="badge badge-soft-dark">Accepted</span>;
  }
  if (orderStatus === NOrder.Status.PROCESSING) {
    return <span className="badge badge-soft-dark">Processing</span>;
  }
  if (orderStatus === NOrder.Status.COMPLETED) {
    return <span className="badge badge-soft-success">Completed</span>;
  }
  if (orderStatus === NOrder.Status.CANCELED) {
    return <span className="badge badge-soft-danger">Canceled</span>;
  }
  if (orderStatus === NOrder.Status.REJECTED) {
    return <span className="badge badge-soft-danger">Rejected</span>;
  }
  if (orderStatus === NOrder.Status.EXPIRED) {
    return (
      <span className="badge" style={{ backgroundColor: '#7402ff', color: 'white' }}>
        Expired
      </span>
    );
  }
  return <>{orderStatus}</>;
});
