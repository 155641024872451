import { gql } from '@apollo/client';

export const UPDATE_VEHICLE_FUEL_TYPE = gql`
  mutation updateVehicleFuelType($input: UpdateVehicleFuelTypeInput!) {
    updateVehicleFuelType(input: $input) {
      id
      titleEn
      titleDe
    }
  }
`;
