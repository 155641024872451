import { CSSProperties, memo } from 'react';
import { NParcel } from '../../types';

interface UIParcelProps extends Pick<NParcel.Entity, 'description' | 'parcelType'> {
  style?: CSSProperties;
}

export const UIParcel = memo<UIParcelProps>(({ style, description, parcelType }) => {
  return (
    <div className="parcel" style={style}>
      <div className="parcel__designation">{parcelType?.designation}</div>
      <div className="parcel__description">{description}</div>
    </div>
  );
});
