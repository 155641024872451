import { ApolloClient, InMemoryCache } from '@apollo/client';
import { API_URL } from '../constants';
import { ELocalStorage } from '../constants/local-storage.constant';

export const client = new ApolloClient({
  uri: API_URL,
  headers: {
    Authorization: localStorage.getItem(ELocalStorage.ACCESS_TOKEN) ?? '',
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getVehicleLocations: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          // chats: {
          //   keyArgs: ['type'],
          //   merge(existing, incoming) {
          //     return incoming;
          //   },
          // },
          // messages: {
          //   keyArgs: ['$input'],
          //   merge(existing, incoming) {
          //     return incoming;
          //   },
          // },
          getAllParcelTypes: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          vehicleTypes: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getFirstOrderPickUpWaypoints: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getVehicleLocation: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Subscription: {
        fields: {
          chatMessagesSeen: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      BarcodeSubscriptionOutput: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      UserVehicleQueryOutput: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      // MessagesSubscriptionOutput: {
      //   merge(existing, incoming) {
      //     return incoming;
      //   },
      // },
      ParcelTypeSubscriptionOutput: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      // Chat: {
      //   fields: {
      //     messages: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //   },
      // },
      // ChatOutputMessage: {
      //   fields: {
      //     readers: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //   },
      // },
    },
  }),
});
