import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from 'classnames';
import useConnector from '../hooks/useConnector';
import { useValidator } from '../hooks';
import classNames from 'classnames';

interface IFTextareaProps {
  label?: string;
  name: string;
  placeholder?: string;
  labelClassName?: string;
  defaultValue?: string;
  onChangeValue?: (value?: string) => void;
  required?: boolean;
  errorMessage?: string;
  maxLength?: number;
  disabled?: boolean;
}

const FTextarea: React.FC<IFTextareaProps> = ({
  label = '',
  name,
  defaultValue = '',
  labelClassName,
  onChangeValue = () => {},
  required = false,
  errorMessage = '',
  maxLength,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSetValue = useCallback(
    (v: string) => {
      setValue(v);
      if (onChangeValue) {
        onChangeValue(v);
      }
    },
    [onChangeValue],
  );

  useConnector(name, value, handleSetValue);

  const isValid = useValidator(name, () => {
    if (required) return !!value;
    return true;
  });

  useEffect(() => {
    if (!textareaRef.current) return;

    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [value]);

  const handleChangeValue = useCallback(
    (e) => {
      handleSetValue(e.target.value);
    },
    [handleSetValue],
  );

  return (
    <div
      className={classes({
        'mb-3': true,
        'text-danger': !isValid,
      })}
    >
      <div className="d-flex justify-content-between">
        {label && (
          <label className={classNames('form-label', labelClassName)}>
            {label}{' '}
            {required ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-secondary">(optional)</span>
            )}
          </label>
        )}
        {maxLength !== undefined && maxLength - value.length <= 20 && (
          <label className="form-label">{maxLength - value.length}</label>
        )}
      </div>
      <textarea
        ref={textareaRef}
        className={classes({
          'form-control': true,
          'is-invalid': !isValid,
        })}
        value={value}
        onChange={handleChangeValue}
        {...props}
      />
      {!isValid && (
        <div className="form-text text-danger fw-bold">Please fill in the field</div>
      )}
    </div>
  );
};

export default FTextarea;
