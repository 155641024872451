import { useSubscription } from '@apollo/client';
import { CHAT_MESSAGES_SEEN_SUBSCRIPTION } from '../../../subscriptions';
import { NChat } from '../../../types';
import { GET_CHATS } from '../../../queries';

export const useMessagesSeenSubscription = (tokenIsValid: boolean) => {
  useSubscription<NChat.Message.On.Seen.Output>(CHAT_MESSAGES_SEEN_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { chatMessagesSeen } = data;

      const { chatId, userId } = chatMessagesSeen;

      /* Update the message in the chat */
      const updateMessageInChat = (type: NChat.Type, next?: () => void) => () => {
        let found = false;

        client.cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
          {
            query: GET_CHATS,
            variables: {
              type,
            },
          },
          (record) => {
            if (!record) return;

            return {
              chats: record.chats.map((x) => {
                if (x.id !== chatId) return x;

                return {
                  ...x,
                  messages: x.messages.map((message) => ({
                    ...message,
                    readersSeen: message.readersSeen.map((readerSeen) => {
                      if (readerSeen.userId === userId) {
                        return { ...readerSeen, hasSeen: true };
                      }
                      return readerSeen;
                    }),
                  })),
                };
              }),
            };
          },
        );

        if (!found && next) next();
      };

      updateMessageInChat(
        NChat.Type.ORDER_SUPPORT,
        updateMessageInChat(NChat.Type.EMPLOYEE),
      )();

      let updated = false;

      client.cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
        {
          query: GET_CHATS,
          variables: {
            type: NChat.Type.ORDER_SUPPORT,
          },
        },
        (record) => {
          if (!record) return;

          updated = true;

          return {
            chats: record.chats.map((chat) => {
              if (chat.id !== chatId) return chat;

              return {
                ...chat,
                messages: chat.messages.map((message) => ({
                  ...message,
                  readersSeen: message.readersSeen.map((readerSeen) => {
                    if (readerSeen.userId === userId) {
                      return { ...readerSeen, hasSeen: true };
                    }
                    return readerSeen;
                  }),
                })),
              };
            }),
          };
        },
      );

      if (updated) return;

      client.cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
        {
          query: GET_CHATS,
          variables: {
            type: NChat.Type.ORDER_DRIVER,
          },
        },
        (record) => {
          if (!record) return;

          return {
            chats: record.chats.map((chat) => {
              if (chat.id !== chatId) return chat;

              return {
                ...chat,
                messages: chat.messages.map((message) => ({
                  ...message,
                  readersSeen: message.readersSeen.map((readerSeen) => {
                    if (readerSeen.userId === userId) {
                      return { ...readerSeen, hasSeen: true };
                    }
                    return readerSeen;
                  }),
                })),
              };
            }),
          };
        },
      );
    },
  });
};
