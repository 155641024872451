import { gql } from '@apollo/client';

export const UPDATE_REVIEW_TAG = gql`
  mutation updateReviewTag($input: UpdateReviewTagInput!) {
    updateReviewTag(input: $input) {
      id
      name
    }
  }
`;
