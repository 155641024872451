import React, { memo, useMemo, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSearchQuery } from '../../../../../../../hooks';
import { ESearchQuery } from '../../../../../../../constants/query.constant';
import { GET_CHAT, useChat } from '../../../../../../../queries';
import { useAuth } from '../../../../../../../context/Auth';
import { UIScrollableContainer } from '../../../../../../../components/ui';
import { DataIsFetching } from '../../../../../../../components/DataIsFetching';
import { Message } from './components';
import { NChat } from '../../../../../../../types';
import { UPDATE_MESSAGES_SEEN } from '../../../../../../../mutations';
import { findUnreadMessages } from '../../../../../../../utilities/findUnreadMessages';

export const MessageList = memo(() => {
  const client = useApolloClient();
  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);

  const listRef = useRef<HTMLDivElement>(null);

  const { profile } = useAuth();

  const [updateMessagesSeen] = useMutation<
    NChat.Message.UpdateMessagesSeen.Output,
    NChat.Message.UpdateMessagesSeen.Input
  >(UPDATE_MESSAGES_SEEN, {
    variables: {
      input: {
        chatId: chatId ?? '',
      },
    },
    update() {
      if (!profile || !chatId) return;

      client.cache.updateQuery<NChat.GetOne.Output, NChat.GetOne.Input>(
        { query: GET_CHAT, variables: { input: { id: chatId } } },
        (record) => {
          if (!record) return;

          return {
            chat: {
              ...record.chat,
              messages: record.chat.messages.map((message) => {
                if (message.user.id !== profile.id) {
                  return {
                    ...message,
                    readersSeen: message.readersSeen.map((readerSeen) => {
                      if (readerSeen.userId === profile?.id) {
                        return { ...readerSeen, hasSeen: true };
                      }
                      return readerSeen;
                    }),
                  };
                }

                return message;
              }),
            },
          };
        },
      );
    },
    onError: (e) => console.log(e),
  });

  const { chat, loading } = useChat({
    shouldTriggerEventsAfterRefetching: true,
    chatId,
    onCompleted(data) {
      if (findUnreadMessages(data.chat.messages, profile?.id).length) {
        void updateMessagesSeen();
      }
    },
  });

  useMemo(() => {
    if (chatId) {
      if (listRef.current) {
        listRef.current.scrollTo({ top: 0 });
      }
    }
  }, [chatId]);

  if (!chatId) return null;

  return (
    <UIScrollableContainer ref={listRef} className="flex-column-reverse">
      <div className="message-list">
        {loading && <DataIsFetching padding={'0 12px 24px 12px'} />}
        {profile &&
          chat?.messages.map((chatMessage, i) => (
            <Message key={chatMessage.id} {...chatMessage} profileId={profile.id} />
          ))}
      </div>
    </UIScrollableContainer>
  );
});
