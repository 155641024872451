import { useSubscription } from '@apollo/client';
import { VEHICLE_TYPE_UPDATED_SUBSCRIPTION } from '../../../subscriptions';
import { NVehicleType } from '../../../types';
import { GET_ALL_VEHICLE_TYPES } from '../../../queries';

export const useVehicleTypeUpdatedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NVehicleType.On.Updated.Output>(VEHICLE_TYPE_UPDATED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { vehicleTypeUpdated } = data;

      client.cache.updateQuery<NVehicleType.GetAll.Output>(
        { query: GET_ALL_VEHICLE_TYPES },
        (record) => {
          if (!record) return;

          return {
            vehicleTypes: record.vehicleTypes.map((vehicleType) => {
              if (vehicleType.id === vehicleTypeUpdated.id) {
                return vehicleTypeUpdated;
              }
              return vehicleType;
            }),
          };
        },
      );
    },
  });
};
