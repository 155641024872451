import { gql } from '@apollo/client';

export const UPDATE_BARCODE_SUBSCRIPTION = gql`
  subscription {
    barcodes {
      update {
        id
        isUsed
        uniqueCode
      }
    }
  }
`;
