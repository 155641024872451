import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

interface UIBreadcrumbsProps {
  children?: ReactNode | undefined;
  title: string;
  className?: string;
  titleClassName?: string;
}

export const UIBreadcrumbs = memo<UIBreadcrumbsProps>(
  ({ children, title, className, titleClassName }) => (
    <div className={classNames('ui__breadcrumbs', className)}>
      <span className={classNames('ui__h1', titleClassName)}>{title}</span>
      {children}
    </div>
  ),
);
