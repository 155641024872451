import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateBarcodeSidebar } from './RenderCreateBarcodeSidebar';

export const CreateBarcodeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_BARCODE} onClose={handleClear}>
      <RenderCreateBarcodeSidebar />
    </Sidebar>
  );
});
