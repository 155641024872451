import React, { memo } from 'react';
import { Circle, Marker } from '@react-google-maps/api';
import { SSvgPickUp } from '../../../../assets/icons';
import { IOrderStartingWaypoint } from '../../../../interfaces/waypoint.interface';
import { Colors } from '../../../../constants/color.constant';
import { NOrder } from '../../../../types';

interface FirstOrderPickUpWaypointProps extends IOrderStartingWaypoint {
  isSelectedExists: boolean;
  isSelected: boolean;
  shiftId: string | null;
  onClick: () => void;
  pickUpTimeType: NOrder.PickUpTimeType;
  isShown?: boolean;
}

export const FirstOrderPickUpWaypoint = memo<FirstOrderPickUpWaypointProps>(
  ({
    id,
    lat,
    lng,
    isSelected,
    isSelectedExists,
    shiftId,
    pickUpTimeType,
    onClick,
    isShown = true,
  }) => {
    if (!isShown) {
      return null;
    }

    return (
      <Marker
        key={id}
        position={{ lat, lng }}
        opacity={isSelectedExists && !isSelected ? 0.7 : 1}
        icon={SSvgPickUp({
          fill: isSelected
            ? Colors.SELECTED_ORDER_START_POINT
            : shiftId
            ? Colors.ORDER_IS_ACCEPTED
            : Colors[pickUpTimeType],
        })}
        onClick={onClick}
      >
        {isSelected && (
          <>
            <Circle center={{ lat, lng }} radius={1000} options={closeOptions} />
            <Circle center={{ lat, lng }} radius={2000} options={middleOptions} />
            <Circle center={{ lat, lng }} radius={4000} options={farOptions} />
          </>
        )}
      </Marker>
    );
  },
);

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};

const closeOptions = {
  ...defaultOptions,
  zIndex: -3,
  fillOpacity: 0.05,
  strokeColor: 'rgb(16,121,60)',
  fillColor: 'rgb(39, 174, 96)',
};

const middleOptions = {
  ...defaultOptions,
  zIndex: -2,
  fillOpacity: 0.05,
  strokeColor: 'rgb(248,152,0)',
  fillColor: 'rgb(255,161,9)',
};

const farOptions = {
  ...defaultOptions,
  zIndex: -1,
  fillOpacity: 0.05,
  strokeColor: '#dc1a1a',
  fillColor: '#FF5252',
};
