import React, { memo } from 'react';
import { NOrder } from '../types';

interface PaymentStatusBadgeProps {
  paymentStatus: NOrder.PaymentStatus;
}

export const PaymentStatusBadge = memo<PaymentStatusBadgeProps>(({ paymentStatus }) => {
  if (paymentStatus === NOrder.PaymentStatus.UNCONFIRMED) {
    return <span className="badge badge-soft-secondary">Unconfirmed</span>;
  }
  if (paymentStatus === NOrder.PaymentStatus.UNCAPTURED) {
    return <span className="badge badge-soft-warning">Uncaptured</span>;
  }
  if (paymentStatus === NOrder.PaymentStatus.REFUND) {
    return <span className="badge badge-soft-warning">Refund</span>;
  }
  if (paymentStatus === NOrder.PaymentStatus.PARTIAL_REFUND) {
    return <span className="badge badge-soft-warning">Partial refund</span>;
  }
  if (paymentStatus === NOrder.PaymentStatus.PAID) {
    return <span className="badge badge-soft-success">Paid</span>;
  }
  if (paymentStatus === NOrder.PaymentStatus.UNCHARGED) {
    return <span className="badge badge-soft-danger">Uncharged</span>;
  }
  return <>{paymentStatus}</>;
});
