import React, { memo } from 'react';
import { UIContainer } from '../../../../../components/ui';
import { Header, Footer, MessageList } from './components';
import { useSearchQuery } from '../../../../../hooks';
import { ESearchQuery } from '../../../../../constants/query.constant';

export const Room = memo(() => {
  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);

  return (
    <UIContainer className="chat-room">
      {chatId && (
        <>
          <Header />
          <MessageList />
          <Footer />
        </>
      )}
    </UIContainer>
  );
});
