import { gql } from '@apollo/client';

export const GET_USER_VEHICLE_DETAILS = gql`
  query getUserVehicleDetails($input: UserVehicleQueryInput!) {
    userVehicle(input: $input) {
      user {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
        userRole
      }
      vehicle {
        id
        title
        vehicleType {
          titleEn
          titleDe
        }
      }
      orders {
        id
        uniqueCode
        orderStatus
        pickUpTime
        pickUpTimeType
      }
      favoriteOrders {
        id
        uniqueCode
        orderStatus
      }
    }
  }
`;
