export const SSvgDropOff = ({ fill }: { fill: string }): string => {
  return (
    'data:image/svg+xml;utf8,<svg width="30" height="30" viewBox="0 0 12 15" ' +
    'fill="' +
    fill +
    '" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M6 0C2.85 0 0 2.415 0 6.15C0 8.535 1.8375 11.34 5.505 14.5725C5.79 14.82 6.2175 14.82 6.5025 14.5725C10.1625 11.34 12 8.535 12 6.15C12 2.415 9.15 0 6 0Z"/>' +
    '</svg>'
  );
};
