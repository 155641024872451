import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from 'classnames';
import { useValidator, useConnector } from '../hooks';
import classNames from 'classnames';

interface FInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  defaultValue?: string;
  containerClassName?: string;
  className?: string;
  labelClassName?: string;
  getter?: (value: string | number | null) => any;
  setter?: (value: string) => string;
  onChangeValue?: (value: string) => void;
  type?: 'text' | 'number' | 'date' | 'color';
  required?: boolean;
  errorMessage?: string;
  autofocus?: boolean;
  disabled?: boolean;
  min?: number;
}

const FInput: React.FC<FInputProps> = ({
  label,
  name,
  className,
  containerClassName,
  labelClassName,
  defaultValue,
  getter = (value) => value,
  setter = (value) => value,
  onChangeValue,
  type,
  required,
  errorMessage,
  autofocus,
  min,
  ...props
}) => {
  const [value, setValue] = useState(
    defaultValue ?? (type === 'number' ? '0' : type === 'color' ? '#000000' : ''),
  );
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      if (!ref.current) return;
      ref.current.focus();
    }
  }, [autofocus]);

  const handleSetValue = useCallback(
    (v: string) => {
      const value: string = setter(v);
      setValue(value ?? '');
      if (onChangeValue) {
        onChangeValue(value);
      }
    },
    [onChangeValue, setter],
  );

  useConnector(
    name,
    getter(type === 'text' ? value : type === 'number' ? +value : value),
    handleSetValue,
    defaultValue,
  );

  const isValid = useValidator(name, () => {
    if (!required) return true;

    if (!value) return false;

    if (type !== 'number') return true;

    return min !== undefined ? +value >= min : value !== '0';
  });

  const handleChangeValue = useCallback(
    (e) => {
      handleSetValue(e.target.value);
    },
    [handleSetValue],
  );

  return (
    <div className={classes('mb-3', { 'text-danger': !isValid }, containerClassName)}>
      <div className={className}>
        {label && (
          <label className={classNames('form-label', labelClassName)}>
            {label}{' '}
            {required ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-secondary">(optional)</span>
            )}
          </label>
        )}
        <input
          ref={ref}
          type={type}
          className={classes('form-control', { 'is-invalid': !isValid })}
          value={value}
          onChange={handleChangeValue}
          min={type === 'number' ? (min ? min : '0') : ''}
          {...props}
        />
      </div>
      {!isValid && (
        <div className="form-text text-danger fw-bold">
          {!value
            ? errorMessage || 'Please fill in the field'
            : min !== undefined && +value < min
            ? `Minimum number is ${min}`
            : value === '0' && type === 'number'
            ? 'The number cannot be 0'
            : ''}
        </div>
      )}
    </div>
  );
};

export default FInput;
