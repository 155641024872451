import { gql } from '@apollo/client';

export const GET_USER_VEHICLE_WAYPOINTS = gql`
  query getUserVehicleWaypoints($input: UserVehicleQueryInput!) {
    userVehicle(input: $input) {
      waypoints {
        id
        lat
        lng
        waypointType
      }
    }
  }
`;
