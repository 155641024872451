import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { GlobalMapProvider } from '../../../context/GlobalMap';
import { ERoutes } from '../../../constants/route.constant';
import {
  FirstOrderPickUpWaypoints,
  OrderRoutes,
  VehicleLocations,
  VehicleRoutes,
} from './components';
import { useOrders } from '../../../queries';
import { UIBreadcrumbs, UIContainer, UIOrder } from '../../../components/ui';
import { SvgArrowRight } from '../../../assets/icons';
import { NOrder } from '../../../types';
import { pageTitle } from '../../../utilities/pageTitle';

const options = {
  mapId: 'bec114859e5e2a3a',
  disableDefaultUI: true,
  clickableIcons: false,
};

const timeTypes = {
  [NOrder.PickUpTimeType.URGENT]: 1,
  [NOrder.PickUpTimeType.NOW]: 2,
  [NOrder.PickUpTimeType.LATER]: 3,
};

const LeftSidebar = memo(() => {
  const [isHidden, setIsHidden] = useState(false);
  const { orders } = useOrders();

  const sortedOrders = useMemo(() => {
    return orders
      .filter(
        (order) =>
          order.orderStatus === NOrder.Status.AVAILABLE &&
          order.attachType === NOrder.AttachType.MANUAL,
      )
      .sort((a, b) => {
        return b.pickUpTime - a.pickUpTime;
      })
      .sort((a, b) => {
        return timeTypes[a.pickUpTimeType] - timeTypes[b.pickUpTimeType];
      });
  }, [orders]);

  return (
    <div className="map-page__sidebar_left" style={{ left: isHidden ? -300 : 0 }}>
      <UIBreadcrumbs title="Unassigned Orders" />

      <div
        className={classNames('map-page__btn_toggle-sidebar', {
          rotate_left: !isHidden,
        })}
        onClick={() => setIsHidden(!isHidden)}
      >
        <SvgArrowRight />
      </div>

      <UIContainer
        style={{ padding: '0 12px', overflow: 'auto', flexGrow: 1, flexBasis: 0 }}
      >
        {sortedOrders.map((order) => (
          <UIOrder key={order.id} {...order} />
        ))}
      </UIContainer>
    </div>
  );
});

export const MapPage = memo(() => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(ERoutes.MAP);
  }, [history]);

  const googleMapRef = useRef<GoogleMap>(null);

  const center = useMemo(() => {
    const lat = localStorage.getItem('LAT');
    const lng = localStorage.getItem('LNG');

    return {
      lat: lat !== null ? +lat : 48.465,
      lng: lng !== null ? +lng : 33.05,
    };
  }, []);

  const zoom = useMemo(() => {
    const zoom = localStorage.getItem('ZOOM');
    return zoom !== null ? +zoom : 12;
  }, []);

  const handleCenterChanged = useCallback(() => {
    const map = googleMapRef.current?.state.map;
    if (map) {
      const center = map.getCenter();

      if (center) {
        localStorage.setItem('LAT', `${center.lat()}`);
        localStorage.setItem('LNG', `${center.lng()}`);
      }
    }
  }, []);

  const handleZoomChanged = useCallback(() => {
    const map = googleMapRef.current?.state.map;
    if (map) {
      const zoom = map.getZoom();
      localStorage.setItem('ZOOM', `${zoom}`);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Map')}</title>
      </Helmet>

      <LeftSidebar />

      <GlobalMapProvider>
        <GoogleMap
          ref={googleMapRef}
          mapContainerClassName="map-page__map"
          onClick={handleClick}
          zoom={zoom}
          center={center}
          options={options}
          onCenterChanged={handleCenterChanged}
          onZoomChanged={handleZoomChanged}
        >
          <FirstOrderPickUpWaypoints />
          <OrderRoutes />
          <VehicleLocations />
          <VehicleRoutes />
        </GoogleMap>
      </GlobalMapProvider>
    </>
  );
});
