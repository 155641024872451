import React, { memo } from 'react';
import { OrderSidebar } from './Order';
import { AttachOrderSidebar } from './AttachOrder';
import { ReattachOrderSidebar } from './ReattachOrder';

export const OrderSidebars = memo(() => (
  <>
    <OrderSidebar />
    <AttachOrderSidebar />
    <ReattachOrderSidebar />
  </>
));
