import { useMemo } from 'react';
import { NVehicleFuelType } from '../../types';
import { GET_ALL_VEHICLE_FUEL_TYPES } from '../vehicle-fuel-types';
import { useAppQuery } from '../../apollo';

export const useVehicleFlueTypes = () => {
  const { data, loading } = useAppQuery<NVehicleFuelType.GetAll.Output>(
    GET_ALL_VEHICLE_FUEL_TYPES,
  );

  const vehicleFuelTypes = useMemo(
    () => data?.vehicleFuelTypes ?? [],
    [data?.vehicleFuelTypes],
  );

  return { vehicleFuelTypes, loading };
};
