import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { getMainDefinition } from '@apollo/client/utilities';
import { useHistory } from 'react-router';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { toast, ToastOptions } from 'react-toastify';
import { ERoutes } from '../../constants/route.constant';
import { useNetwork } from '../Network';
import { API_URL, SUBSCRIPTION_URL } from '../../constants';
import { NProfile } from '../../types';
import { GET_PROFILE } from '../../queries';
import { AuthContextProps, JwtToken } from './types';
import { SIGN_OUT, UPDATE_ACCESS_TOKEN } from '../../mutations';
import { ELocalStorage } from '../../constants/local-storage.constant';

export const AuthContext = createContext<AuthContextProps | null>(null);

const getMinutesLeft = (time: number) => {
  return (new Date(time).getTime() * 1000 - Date.now()) / 1000 / 60;
};

export const AuthProvider = memo(({ children }) => {
  const authClient = useRef(
    new ApolloClient({
      uri: API_URL,
      cache: new InMemoryCache(),
    }),
  ).current;

  const client = useApolloClient();
  const { push } = useHistory();

  const {
    isConnected,
    pageIsHidden,
    isExtendingSession,
    accessTokenIsExpired,
    subscriptionsAreConnected,
    setIsInitializing,
    setAccessTokenIsExpired,
    setIsExtendingSession,
    setSubscriptionsAreConnected,
    setSubscriptionsCanBeConnected,
    setIsRefetching,
  } = useNetwork();

  const skip = !(
    isConnected &&
    !pageIsHidden &&
    !accessTokenIsExpired &&
    subscriptionsAreConnected
  );

  const firstFetchIsSkippedRef = useRef(false);
  const subscriptionsShouldBeReconnectedRef = useRef(false);

  const shouldRefetchRef = useRef(skip);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const accessJwtTokenExpTimeRef = useRef(0);
  const refreshJwtTokenExpTimeRef = useRef(0);
  const isInitializedRef = useRef(false);

  const isExtendingSessionRef = useRef(false);
  const accessTokenRef = useRef<string | null>(null);
  const accessTokenExpirationTimeoutRef = useRef<NodeJS.Timeout>();
  const accessShouldBeExtendedTimeoutRef = useRef<NodeJS.Timeout>();
  const refreshTokenExpirationTimeoutRef = useRef<NodeJS.Timeout>();

  const toastIdRef = useRef<string | number | null>(null);
  const toastOptions = useMemo<ToastOptions>(
    () => ({
      position: 'bottom-left',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: 'light',
      type: 'default',
      onClose() {
        toastIdRef.current = null;
      },
    }),
    [],
  );

  const [updateAccessToken] = useMutation<NProfile.UpdateAccessToken.Output>(
    UPDATE_ACCESS_TOKEN,
    {
      fetchPolicy: 'network-only',
      client: authClient,
    },
  );

  const [getProfile, { data }] = useLazyQuery<NProfile.Get.Output>(GET_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const profile = useMemo(() => data?.getProfile ?? null, [data?.getProfile]);

  const clearTimeouts = useCallback(() => {
    if (accessTokenExpirationTimeoutRef.current) {
      clearTimeout(accessTokenExpirationTimeoutRef.current);
    }
    if (accessShouldBeExtendedTimeoutRef.current) {
      clearTimeout(accessShouldBeExtendedTimeoutRef.current);
    }
    if (refreshTokenExpirationTimeoutRef.current) {
      clearTimeout(refreshTokenExpirationTimeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (pageIsHidden) {
      subscriptionsShouldBeReconnectedRef.current = true;

      setAccessTokenIsExpired(true);

      clearTimeouts();
    }
  }, [clearTimeouts, pageIsHidden, setAccessTokenIsExpired]);

  useEffect(() => {
    if (subscriptionsAreConnected) {
      subscriptionsShouldBeReconnectedRef.current = false;
    }
  }, [subscriptionsAreConnected]);

  useEffect(() => {
    if (skip) {
      shouldRefetchRef.current = true;
    } else {
      if (shouldRefetchRef.current && !isExtendingSession) {
        shouldRefetchRef.current = false;

        if (!firstFetchIsSkippedRef.current) {
          firstFetchIsSkippedRef.current = true;
          return;
        }

        void (async () => {
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              ...toastOptions,
              render: <Loader label="Updating..." />,
            });
          }

          try {
            setIsRefetching(true);

            await client.refetchQueries({ include: 'all' });

            setIsRefetching(false);

            if (toastIdRef.current) {
              toast.update(toastIdRef.current, {
                ...toastOptions,
                render: 'Updated',
                type: 'success',
                hideProgressBar: false,
                autoClose: 500,
              });
            }
          } catch (e: any) {
            setIsRefetching(false);

            if (toastIdRef.current) {
              toast.update(toastIdRef.current, {
                render: `An error occurs during the update: ${e.message}`,
                type: 'error',
              });
            }
          }
        })();
      }
    }
  }, [client, isExtendingSession, setIsRefetching, skip, toastOptions]);

  const reset = useCallback(async () => {
    clearTimeouts();

    authClient.setLink(new HttpLink({ uri: API_URL, headers: {} }));

    client.setLink(new HttpLink({ uri: API_URL, headers: {} }));

    await authClient.clearStore();
    await client.clearStore();

    accessJwtTokenExpTimeRef.current = 0;
    refreshJwtTokenExpTimeRef.current = 0;

    setAccessToken(null);
    setAccessTokenIsExpired(true);
    setIsExtendingSession(false);
    setSubscriptionsAreConnected(false);
    setSubscriptionsCanBeConnected(true);
    setIsRefetching(false);

    accessTokenRef.current = null;
    firstFetchIsSkippedRef.current = false;
    // isInitializedRef.current = false;
    shouldRefetchRef.current = false;
    subscriptionsShouldBeReconnectedRef.current = false;
  }, [
    authClient,
    clearTimeouts,
    client,
    setAccessTokenIsExpired,
    setIsExtendingSession,
    setIsRefetching,
    setSubscriptionsAreConnected,
    setSubscriptionsCanBeConnected,
  ]);

  const [logout] = useMutation<NProfile.SingOut.Output>(SIGN_OUT, {
    onError: async (err) => {
      console.warn(err.message);
      await reset();
    },
  });

  const signOut = useCallback(async () => {
    toast.dismiss();

    localStorage.removeItem(ELocalStorage.ACCESS_TOKEN);
    localStorage.removeItem(ELocalStorage.REFRESH_TOKEN);

    setIsLoggedIn(false);

    push(ERoutes.LOGIN);

    try {
      await logout();
    } catch (e: any) {
      //
    }

    await reset();
  }, [logout, push, reset]);

  const setSplitLink = useCallback(() => {
    const accessToken = accessTokenRef.current;

    if (!accessToken) return;

    const accessJwtTokenDecode: JwtToken = jwtDecode(`Bearer ${accessToken}`);
    const accessJwtTokenMinutesLeft = getMinutesLeft(accessJwtTokenDecode.exp);

    if (accessJwtTokenMinutesLeft <= 0) return;

    const httpLink = new HttpLink({
      uri: API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const wsClient = createClient({
      url: SUBSCRIPTION_URL,
      connectionParams: {
        // reconnect: true,
        Authorization: `Bearer ${accessToken}`,
      },
      retryAttempts: 20,
      retryWait: (n) => new Promise((resolve) => setTimeout(resolve, n * 250)),
      shouldRetry: () => true,
      on: {
        connecting() {
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              ...toastOptions,
              render: <Loader label="Connecting..." />,
            });
          } else {
            toastIdRef.current = toast(<Loader label="Connecting..." />, {
              ...toastOptions,
            });
          }
        },
        connected() {
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              ...toastOptions,
              render: 'Connected',
              type: 'success',
              hideProgressBar: false,
              autoClose: 500,
            });
          } else {
            toastIdRef.current = toast('Connected', {
              ...toastOptions,
              type: 'success',
              hideProgressBar: false,
              autoClose: 500,
            });
          }
          setSubscriptionsAreConnected(true);
        },
        closed(e) {
          const event = e as { code?: number; reason?: string; message?: string };
          const reason = event.reason ?? event.message ?? '';
          const message = `Connection closed: ${reason}`;

          // Handling the errors
          // 1000 - "Normal Closure"
          // 1001 - "Stream end encountered"
          if (event.code === 1000 || event.code === 1001) {
            setSplitLink();
          } else if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              ...toastOptions,
              render: message,
              type: 'error',
            });
          } else {
            toastIdRef.current = toast(message, {
              ...toastOptions,
              type: 'error',
            });
          }
          setSubscriptionsAreConnected(false);
        },
        error(e) {
          const error = e as { message?: string; reason?: string };
          const reason = error.message ?? error.reason ?? '';
          const message = `Connection error: ${reason}`;

          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              ...toastOptions,
              render: message,
              type: 'error',
            });
          } else {
            toastIdRef.current = toast(message, {
              ...toastOptions,
              type: 'error',
            });
          }
          setSubscriptionsAreConnected(false);
        },
      },
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    client.setLink(splitLink);
  }, [client, setSubscriptionsAreConnected, toastOptions]);

  const checkAccessTokenExpiration = useCallback(() => {
    const accessJwtTokenMinutesLeft = getMinutesLeft(accessJwtTokenExpTimeRef.current);

    if (accessTokenExpirationTimeoutRef.current) {
      clearTimeout(accessTokenExpirationTimeoutRef.current);
    }

    // console.info(
    //   `[${new Date().toLocaleTimeString()}] access token expires in ${Math.max(
    //     accessJwtTokenMinutesLeft * 60,
    //     0,
    //   ).toFixed(2)} s`,
    // );

    setAccessTokenIsExpired(accessJwtTokenMinutesLeft <= 0);

    accessTokenExpirationTimeoutRef.current = setTimeout(() => {
      setAccessTokenIsExpired(true);
    }, Math.max(accessJwtTokenMinutesLeft * 60 * 1000, 0));
  }, [setAccessTokenIsExpired]);

  const checkRefreshTokenExpiration = useCallback(() => {
    const refreshJwtTokenMinutesLeft = getMinutesLeft(refreshJwtTokenExpTimeRef.current);

    if (refreshTokenExpirationTimeoutRef.current) {
      clearTimeout(refreshTokenExpirationTimeoutRef.current);
    }

    // console.info(
    //   `[${new Date().toLocaleTimeString()}] refresh token expires in ${Math.max(
    //     refreshJwtTokenMinutesLeft * 60,
    //     0,
    //   ).toFixed(2)} s`,
    // );

    refreshTokenExpirationTimeoutRef.current = setTimeout(() => {
      void signOut();
    }, Math.max(refreshJwtTokenMinutesLeft * 60 * 1000, 0));
  }, [signOut]);

  const extendSession = useCallback(
    async (callback: (accessToken: string, refreshToken: string) => void) => {
      isExtendingSessionRef.current = true;
      setIsExtendingSession(true);

      if (subscriptionsShouldBeReconnectedRef.current) {
        setSubscriptionsCanBeConnected(false);
      }

      const refreshToken = localStorage.getItem(ELocalStorage.REFRESH_TOKEN);

      if (!refreshToken) {
        return { message: 'No refresh token' };
      }

      const refreshJwtTokenDecode: JwtToken = jwtDecode(refreshToken);

      if (getMinutesLeft(refreshJwtTokenDecode.exp) <= 0) {
        return { message: 'Refresh token has expired' };
      }

      authClient.setLink(
        new HttpLink({
          uri: API_URL,
          headers: { Authorization: `Bearer ${refreshToken}` },
        }),
      );

      localStorage.setItem(ELocalStorage.IS_EXTENDING_SESSION, 'true');

      try {
        const result = await updateAccessToken();

        if (!result?.data) {
          throw new Error('An error occurred while expanding the session');
        }

        const {
          updateAccessToken: { accessToken },
        } = result.data;

        localStorage.setItem(ELocalStorage.ACCESS_TOKEN, accessToken);

        accessTokenRef.current = accessToken;

        setSplitLink();

        callback(accessToken, refreshToken);

        localStorage.removeItem(ELocalStorage.IS_EXTENDING_SESSION);

        isExtendingSessionRef.current = false;
        setIsExtendingSession(false);
        subscriptionsShouldBeReconnectedRef.current = false;
        setSubscriptionsCanBeConnected(true);
      } catch (e: any) {
        return { message: e.message };
      }
    },
    [
      authClient,
      setIsExtendingSession,
      setSplitLink,
      setSubscriptionsCanBeConnected,
      updateAccessToken,
    ],
  );

  const checkThatAccessTokenShouldBeExpanded = useCallback(
    (callback: (accessToken: string, refreshToken: string) => void) => {
      const accessJwtTokenMinutesLeft = getMinutesLeft(accessJwtTokenExpTimeRef.current);

      if (accessShouldBeExtendedTimeoutRef.current) {
        clearTimeout(accessShouldBeExtendedTimeoutRef.current);
      }

      // console.info(
      //   `[${new Date().toLocaleTimeString()}] will be extended in ${Math.max(
      //     accessJwtTokenMinutesLeft * 60 - 100,
      //     0,
      //   ).toFixed(2)} s`,
      // );

      accessShouldBeExtendedTimeoutRef.current = setTimeout(() => {
        (async () => {
          const error = await extendSession(callback);

          if (error) {
            await signOut();
          }
        })();
      }, Math.max(accessJwtTokenMinutesLeft * 60 * 1000 - 60000, 0));
    },
    [extendSession, signOut],
  );

  const setExpTime = useCallback(
    (accessToken: string | null | undefined, refreshToken: string | null | undefined) => {
      if (!accessToken) {
        return { message: 'No access token' };
      }

      if (!refreshToken) {
        return { message: 'No refresh token' };
      }

      accessTokenRef.current = accessToken;
      setAccessToken(accessToken);

      const accessJwtTokenDecode: JwtToken = jwtDecode(`Bearer ${accessToken}`);
      accessJwtTokenExpTimeRef.current = accessJwtTokenDecode.exp;
      checkAccessTokenExpiration();

      checkThatAccessTokenShouldBeExpanded(setExpTime);

      const refreshJwtTokenDecode: JwtToken = jwtDecode(`Bearer ${refreshToken}`);
      refreshJwtTokenExpTimeRef.current = refreshJwtTokenDecode.exp;
      checkRefreshTokenExpiration();
    },
    [
      checkAccessTokenExpiration,
      checkRefreshTokenExpiration,
      checkThatAccessTokenShouldBeExpanded,
    ],
  );

  useEffect(() => {
    if (!isLoggedIn || !isConnected || pageIsHidden) return;

    checkRefreshTokenExpiration();
    checkAccessTokenExpiration();
    checkThatAccessTokenShouldBeExpanded(setExpTime);
  }, [
    pageIsHidden,
    checkAccessTokenExpiration,
    checkRefreshTokenExpiration,
    checkThatAccessTokenShouldBeExpanded,
    isConnected,
    isLoggedIn,
    setExpTime,
  ]);

  useEffect(() => {
    if (!isConnected || isLoggedIn || isInitializedRef.current) {
      return;
    }

    isInitializedRef.current = true;

    setIsInitializing(true);

    void (async () => {
      try {
        /* After opening the application, extend the user session */
        const error = await extendSession(setExpTime);

        if (error) {
          setIsInitializing(false);

          await reset();

          push(ERoutes.LOGIN);
          return;
        }

        await getProfile();

        setIsLoggedIn(true);

        setIsInitializing(false);
      } catch (e) {
        setIsInitializing(false);

        await reset();

        push(ERoutes.LOGIN);
      }
    })();
  }, [
    extendSession,
    getProfile,
    isConnected,
    isLoggedIn,
    push,
    reset,
    setExpTime,
    setIsInitializing,
  ]);

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.storageArea !== localStorage) return;

      try {
        if (event.key === ELocalStorage.ACCESS_TOKEN) {
          const accessToken = event.newValue;

          if (!accessToken) {
            return;
          }

          const accessJwtTokenDecode: JwtToken = jwtDecode(`Bearer ${accessToken}`);
          accessJwtTokenExpTimeRef.current = accessJwtTokenDecode.exp;

          accessTokenRef.current = accessToken;

          setSplitLink();

          isInitializedRef.current = true;

          setIsLoggedIn(true);

          void getProfile();
        } else if (event.key === ELocalStorage.REFRESH_TOKEN) {
          const refreshToken = event.newValue;

          if (!refreshToken) {
            return;
          }

          const refreshJwtTokenDecode: JwtToken = jwtDecode(`Bearer ${refreshToken}`);
          refreshJwtTokenExpTimeRef.current = refreshJwtTokenDecode.exp;

          isInitializedRef.current = true;

          setIsLoggedIn(true);
        } else if (event.key === ELocalStorage.IS_EXTENDING_SESSION) {
          const isExtendingSession = !!event.newValue;

          isExtendingSessionRef.current = isExtendingSession;
          setIsExtendingSession(isExtendingSession);
        }
      } catch (e: any) {
        void signOut();
      }
    },
    [getProfile, setIsExtendingSession, setSplitLink, signOut],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [handleStorage]);

  const signIn = useCallback(
    async ({
      accessToken,
      refreshToken,
    }: {
      accessToken: string;
      refreshToken: string;
    }) => {
      localStorage.setItem(ELocalStorage.ACCESS_TOKEN, accessToken);
      localStorage.setItem(ELocalStorage.REFRESH_TOKEN, refreshToken);

      accessTokenRef.current = accessToken;

      setSplitLink();

      setExpTime(accessToken, refreshToken);

      await getProfile();

      setIsLoggedIn(true);
    },
    [getProfile, setExpTime, setSplitLink],
  );

  const value = useMemo(
    () => ({
      signIn,
      signOut,
      isLoggedIn,
      profile,
      accessToken,
    }),
    [signIn, signOut, isLoggedIn, profile, accessToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

const Loader = memo<{ label: string }>(({ label }) => (
  <div className="d-flex align-items-center">
    <div className="spinner-border text-primary spinner-border-sm" role="status" />
    <div className="ms-2">{label}</div>
  </div>
));
