import React, { CSSProperties, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface UILayoutProps {
  style?: CSSProperties;
  className?: string;
  row?: boolean;
}

export const UILayout = memo<PropsWithChildren<UILayoutProps>>(
  ({ children, style, className, row }) => (
    <div
      className={classNames('ui__layout', className, {
        ui_direction_row: row,
      })}
      style={style}
    >
      {children}
    </div>
  ),
);
