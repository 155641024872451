import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_BY_REASON_EXPECTED_REVENUE } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardCardLostOrdersByReason = memo(() => {
  const [reason, setReason] = useState(NAnalytics.LostOrdersReason.CANCELED);

  const { data, loading } = useQuery<
    NAnalytics.Static.GetForLostOrdersByReasonExpectedRevenue.Output,
    NAnalytics.Static.GetForLostOrdersByReasonExpectedRevenue.Input
  >(GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_BY_REASON_EXPECTED_REVENUE, {
    variables: {
      input: {
        reason,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value as NAnalytics.LostOrdersReason);
  }, []);

  let value =
    data?.analyticsStaticForLostOrdersByReasonExpectedRevenue.expectedRevenue.toFixed(2);

  return (
    <Card className="dashboard-card bg_red">
      <CardBody>
        <CardTitle className="dashboard-card__title">Lost Orders by Reason</CardTitle>

        <div className="dashboard-card__content">
          {loading ? (
            <div className="spinner-border spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="dashboard-card__value">
              {value ? `${value} Euro` : 'No data'}
            </span>
          )}
        </div>

        <div className="d-flex">
          <select className="dashboard-select" onChange={handleChange}>
            <option value={NAnalytics.LostOrdersReason.CANCELED}>canceled</option>
            <option value={NAnalytics.LostOrdersReason.REJECTED}>rejected</option>
            <option value={NAnalytics.LostOrdersReason.EXPIRED}>expired</option>
          </select>
          <span className="dashboard-select-icon">
            <SvgExpandMore />
          </span>
        </div>
      </CardBody>
    </Card>
  );
});
