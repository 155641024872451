import { gql } from '@apollo/client';

export const GET_ANALYTICS_DYNAMIC_LOST_ORDERS_BY_REASON_PERCENT = gql`
  query analyticsLostOrdersByReasonPercent($input: GetDynamicInput!) {
    analyticsLostOrdersByReasonPercent(input: $input) {
      canceledOrdersPercent
      rejectedOrdersPercent
      expiredOrdersPercent
    }
  }
`;
