import { NUser } from './users';

export namespace NProfile {
  export interface Entity {
    id: string;
    userRole: NUser.Role;
  }

  /* Query */
  export namespace Get {
    export type Output = { getProfile: Entity };
  }

  /* Mutation */
  export namespace UpdateAccessToken {
    export type Output = { updateAccessToken: { accessToken: string } };
  }

  /* Mutation */
  export namespace SingOut {
    export type Output = { signOut: boolean };
  }
}
