import { useMemo } from 'react';
import { GET_ALL_VEHICLES } from '../vehicles';
import { NVehicle } from '../../types';
import { useAppQuery } from '../../apollo';

export const useVehicles = () => {
  const { data, loading } = useAppQuery<NVehicle.GetAll.Output>(GET_ALL_VEHICLES);

  const vehicles = useMemo(() => data?.getAllVehicles ?? [], [data?.getAllVehicles]);

  return { vehicles, loading };
};
