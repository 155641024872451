import React, { memo, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { NParcelType } from '../../../../../../types';
import { useParcelType } from '../../../../../../queries';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { UPDATE_PARCEL_TYPE } from '../../../../../../mutations';
import { ParcelTypeFields } from '../components';
import { useHandleSubmit, useSetValues } from '../../../../../../libs/FForm';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { toast } from 'react-toastify';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderUpdateParcelTypeSidebar = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const parcelTypeId = useSearchParam(searchQuery, ESearchQuery.PARCEL_TYPE_ID);
  const handleSubmit = useHandleSubmit();
  const setValues = useSetValues();

  const {
    parcelType,
    loading: isFetching,
    error,
  } = useParcelType({
    id: parcelTypeId,
  });

  const [updateParcelType, { loading }] = useMutation<
    NParcelType.Update.Output,
    NParcelType.Update.Input
  >(UPDATE_PARCEL_TYPE, {
    onCompleted() {
      toast('Parcel type was successfully updated!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when updating a parcel type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  useEffect(() => {
    if (parcelType) {
      setValues(parcelType);
    }
  }, [parcelType, setValues]);

  const handleValidSubmit = useCallback(
    async (values: Omit<NParcelType.Update.Input['input'], 'id'>) => {
      if (parcelTypeId) {
        void updateParcelType({
          variables: {
            input: {
              id: parcelTypeId,
              ...values,
            },
          },
        });
      }
    },
    [parcelTypeId, updateParcelType],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!parcelType) {
    return <NoData title={`No parcel type data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="update parcel type" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <ParcelTypeFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
