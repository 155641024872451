import { gql } from '@apollo/client';

export const ADD_USER_VEHICLE_LOCATION_SUBSCRIPTION = gql`
  subscription {
    addUserVehicleLocationSubscription {
      userVehicleLinkId
      lat
      lng
    }
  }
`;
