import { NUser } from './users';
import { NOrder } from './orders';

export namespace NReview {
  export interface Entity {
    id: string;
    safety: number;
    service: number;
    recommendation: boolean;
    reviewTags: string[];
    comment: string;
    rating: {
      score: number;
    } | null;
    customer: Pick<
      NUser.Entity,
      'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'photoFileId'
    >;
    driver: Pick<
      NUser.Entity,
      'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'photoFileId'
    > | null;
    order: Pick<
      NOrder.Entity,
      | 'id'
      | 'uniqueCode'
      | 'orderStatus'
      | 'pickUpTime'
      | 'pickUpTimeType'
      | 'attachType'
      | 'paymentStatus'
    >;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { review: Entity | null };
  }

  /* Query */
  export namespace GetAll {
    export type Output = {
      reviews: Entity[];
    };
  }

  /* Subscriptions */
  export namespace On {
    export namespace Added {
      export interface Output {
        reviewAdded: Entity;
      }
    }
  }
}
