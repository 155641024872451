import { useSubscription } from '@apollo/client';
import { UPDATE_LOCATION_SUBSCRIPTION } from '../../../subscriptions';
import { NVehicle } from '../../../types';
import { GET_VEHICLE_LOCATIONS } from '../../../queries';

export const useDriverLocationUpdatedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NVehicle.Location.On.Update.Output>(UPDATE_LOCATION_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const changedData = data.updateLocationSubscription;

      client.cache.updateQuery<NVehicle.Location.GetAll.Output>(
        { query: GET_VEHICLE_LOCATIONS },
        (record) => {
          if (!record) return;

          let vehicleLocationWasFound = false;

          let getVehicleLocations = record.getVehicleLocations.map((vehicleLocation) => {
            if (vehicleLocation.userVehicleLinkId === changedData.id) {
              vehicleLocationWasFound = true;

              return { ...vehicleLocation, ...changedData };
            }
            return vehicleLocation;
          });

          if (!vehicleLocationWasFound) {
            getVehicleLocations = [
              ...getVehicleLocations,
              {
                userVehicleLinkId: changedData.id,
                lat: changedData.lat,
                lng: changedData.lng,
              },
            ];
          }

          return {
            getVehicleLocations: getVehicleLocations,
          };
        },
      );
    },
  });
};
