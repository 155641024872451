import { gql } from '@apollo/client';

export const GET_ANALYTICS_DYNAMIC_FOR_REVENUE = gql`
  query analyticsDynamicForRevenue($input: GetDynamicInput!) {
    analyticsDynamicForRevenue(input: $input) {
      dynamicRevenueStats {
        period
        revenue
      }
    }
  }
`;
