import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

interface UIButtonGroupProps {
  children?: ReactNode | undefined;
  className?: string;
}

export const UIButtonGroup = memo<UIButtonGroupProps>(({ children, className }) => (
  <div className={classNames('ui__button-group', className)}>{children}</div>
));
