import { gql } from '@apollo/client';

export const AVAILABLE_NEW_PAID_ORDER_SUBSCRIPTION = gql`
  subscription {
    availableNewPaidOrderSubscription {
      id
      uniqueCode
      orderStatus
      pickUpTime
      pickUpTimeType
      attachType
      paymentStatus
    }
  }
`;
