import React, { useCallback, useState } from 'react';
import classes from 'classnames';
import useConnector from '../hooks/useConnector';
import { useValidator } from '../hooks';

interface FSwitchProps
  extends Omit<React.HTMLAttributes<HTMLInputElement>, 'defaultValue'> {
  label?: string;
  name: string;
  setter?: (value: any) => any;
  getter?: (value: any) => any;
  defaultValue?: boolean;
  onChangeValue?: (value: boolean) => void;
  required?: boolean;
  errorMessage?: string;
}

const FSwitch: React.FC<FSwitchProps> = ({
  label,
  name,
  setter,
  getter,
  defaultValue,
  onChangeValue,
  required,
  errorMessage,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultValue);

  useConnector(
    name,
    getter && getter(checked),
    (value) => setChecked(setter && setter(value)),
    defaultValue
  );

  const isValid = useValidator(name, () => {
    if (required) return !!checked;
    return true;
  });

  const handleChange = useCallback(
    (e) => {
      setChecked(e.target.checked);
      if (onChangeValue) {
        onChangeValue(e.target.checked);
      }
    },
    [onChangeValue]
  );

  return (
    <div
      className={classes({
        'mb-3': true,
        'text-danger': !isValid,
      })}
    >
      <label className="d-flex justify-content-between align-items-center form-check-label">
        <span className="ms-2 w-75">{label}</span>
        <div className="form-check form-switch form-switch-md">
          <input
            type="checkbox"
            className="form-check-input"
            checked={checked}
            onChange={handleChange}
            {...props}
          />
        </div>
      </label>
      {!isValid && (
        <div className="form-text text-danger fw-bold">
          {errorMessage || 'Field must be marked'}
        </div>
      )}
    </div>
  );
};

FSwitch.defaultProps = {
  label: '',
  name: '',
  setter: (value) => value,
  getter: (value) => value,
  defaultValue: false,
  onChangeValue: () => {},
  required: false,
  errorMessage: '',
};

export default FSwitch;
