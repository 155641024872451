import React, { memo, useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { REMOVE_VEHICLE_TYPE } from '../../../../mutations';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { GET_ALL_VEHICLE_TYPES } from '../../../../queries';
import { NVehicleType } from '../../../../types';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);
  const client = useApolloClient();

  const [removeVehicleType, { loading }] = useMutation<
    NVehicleType.Remove.Output,
    NVehicleType.Remove.Input
  >(REMOVE_VEHICLE_TYPE, {
    onCompleted() {
      toast('Vehicle type successfully deleted!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when deleting a vehicle type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(async () => {
    if (!unlock) {
      setUnlock(true);
    } else {
      await removeVehicleType({ variables: { input: { id } } });

      client.cache.updateQuery<NVehicleType.GetAll.Output>(
        {
          query: GET_ALL_VEHICLE_TYPES,
        },
        (record) => {
          if (!record) return;

          return {
            vehicleTypes: record.vehicleTypes.filter(
              (vehicleType) => vehicleType.id !== id,
            ),
          };
        },
      );
    }
  }, [removeVehicleType, client, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
