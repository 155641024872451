import { gql } from '@apollo/client';

export const CHAT_MESSAGES_SEEN_SUBSCRIPTION = gql`
  subscription {
    chatMessagesSeen {
      chatId
      userId
    }
  }
`;
