import { useMemo } from 'react';
import { GET_ATTACH_USER_VEHICLES } from '../user-vehicles';
import { GetAttachUserVehiclesInput, GetAttachUserVehiclesOutput } from '../../types';
import { useAppQuery } from '../../apollo';

interface Params {
  orderId: string | null | undefined;
}

export const useAttachUserVehicles = ({ orderId }: Params) => {
  const { data, loading, error } = useAppQuery<
    GetAttachUserVehiclesOutput,
    GetAttachUserVehiclesInput
  >(GET_ATTACH_USER_VEHICLES, {
    skip: !orderId,
    variables: {
      input: {
        orderId: orderId ?? '',
      },
    },
    fetchPolicy: 'no-cache',
  });

  const attachUserVehicles = useMemo(
    () => data?.getAttachUserVehicles ?? [],
    [data?.getAttachUserVehicles],
  );

  return { attachUserVehicles, loading, error };
};
