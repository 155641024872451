import { useMemo } from 'react';
import { GET_VEHICLE_LOCATIONS } from '../vehicles';
import { NVehicle } from '../../types';
import { useAppQuery } from '../../apollo';

export const useVehicleLocations = () => {
  const { data, loading } =
    useAppQuery<NVehicle.Location.GetAll.Output>(GET_VEHICLE_LOCATIONS);

  const vehicleLocations = useMemo(
    () => data?.getVehicleLocations ?? [],
    [data?.getVehicleLocations],
  );

  return { vehicleLocations, loading };
};
