import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EWindowState, NetworkContextProps } from './types';

export const NetworkContext = createContext<NetworkContextProps | null>(null);

export const NetworkProvider = memo(({ children }) => {
  const [isInitializing, setIsInitializing] = useState(false);

  const [isConnected, setIsConnected] = useState(window.navigator.onLine);
  const [pageIsHidden, setPageIsHidden] = useState(false);
  const [accessTokenIsExpired, setAccessTokenIsExpired] = useState(true);
  const [isExtendingSession, setIsExtendingSession] = useState(false);
  const [subscriptionsAreConnected, setSubscriptionsAreConnected] = useState(false);
  const [subscriptionsCanBeConnected, setSubscriptionsCanBeConnected] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);

  const getState = useCallback(() => {
    if (document.visibilityState === EWindowState.HIDDEN) {
      return EWindowState.HIDDEN;
    }
    if (document.hasFocus()) {
      return EWindowState.FOCUSED;
    }
    return EWindowState.NOT_FOCUSED;
  }, []);

  const prevStateRef = useRef(getState());

  const handleStateChange = useCallback((nextState: EWindowState) => {
    let prevState = prevStateRef.current;

    if (nextState !== prevState) {
      if (nextState === EWindowState.FROZEN) {
        setPageIsHidden(true);
      }

      if (nextState === EWindowState.HIDDEN) {
        setPageIsHidden(true);
      }

      if (nextState === EWindowState.FOCUSED || nextState === EWindowState.NOT_FOCUSED) {
        setPageIsHidden(false);
      }

      prevStateRef.current = nextState;
    }
  }, []);

  const onPageStateChange = useCallback(() => {
    handleStateChange(getState());
  }, [getState, handleStateChange]);

  const onFreeze = useCallback(() => {
    handleStateChange(EWindowState.FROZEN);
  }, [handleStateChange]);

  const onPageHide = useCallback(
    (event: PageTransitionEvent) => {
      if (event.persisted) {
        // If the event's persisted property is `true` the page is about
        // to enter the page navigation cache, which is also in the frozen state.
        handleStateChange(EWindowState.FROZEN);
      } else {
        // If the event's persisted property is not `true` the page is about to be unloaded.
        handleStateChange(EWindowState.TERMINATED);
      }
    },
    [handleStateChange],
  );

  const onOnline = useCallback(() => {
    setIsConnected(true);
  }, []);

  const onOffline = useCallback(() => {
    setIsConnected(false);
  }, []);

  const cleanUp = useCallback(() => {
    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
      window.removeEventListener(type, onPageStateChange, { capture: true });
    });

    window.removeEventListener('freeze', onFreeze, { capture: true });
    window.removeEventListener('pagehide', onPageHide, { capture: true });

    window.removeEventListener('online', onOnline);
    window.removeEventListener('offline', onOffline);
  }, [onFreeze, onOffline, onOnline, onPageHide, onPageStateChange]);

  useEffect(() => {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
      window.addEventListener(type, onPageStateChange, { capture: true });
    });

    window.addEventListener('freeze', onFreeze, { capture: true });
    window.addEventListener('pagehide', onPageHide, { capture: true });

    return () => {
      cleanUp();
    };
  }, [
    cleanUp,
    getState,
    handleStateChange,
    onFreeze,
    onOffline,
    onOnline,
    onPageHide,
    onPageStateChange,
  ]);

  const value = useMemo(
    () => ({
      isConnected,
      pageIsHidden,

      isInitializing,
      setIsInitializing,

      accessTokenIsExpired,
      setAccessTokenIsExpired,

      isExtendingSession,
      setIsExtendingSession,

      subscriptionsAreConnected,
      setSubscriptionsAreConnected,

      subscriptionsCanBeConnected,
      setSubscriptionsCanBeConnected,

      isRefetching,
      setIsRefetching,
    }),
    [
      accessTokenIsExpired,
      isConnected,
      isExtendingSession,
      isRefetching,
      isInitializing,
      pageIsHidden,
      subscriptionsAreConnected,
      subscriptionsCanBeConnected,
    ],
  );

  return (
    <NetworkContext.Provider value={value}>
      {!isConnected && (
        <div className="no-internet-connection">
          <div className="no-internet-connection__title">No internet connection</div>
        </div>
      )}
      {children}
    </NetworkContext.Provider>
  );
});
