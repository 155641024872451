import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { NVehicleFuelType } from '../../../../../../types';
import { VehicleFuelTypeFields } from '../components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { CREATE_VEHICLE_FUEL_TYPE } from '../../../../../../mutations';
import { GET_ALL_VEHICLE_FUEL_TYPES } from '../../../../../../queries';

export const RenderCreateFuelTypeSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();

  const [createVehicleFlueType, { loading }] = useMutation<
    NVehicleFuelType.Create.Output,
    NVehicleFuelType.Create.Input
  >(CREATE_VEHICLE_FUEL_TYPE, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NVehicleFuelType.GetAll.Output>(
        { query: GET_ALL_VEHICLE_FUEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            vehicleFuelTypes: [...record.vehicleFuelTypes, data.createVehicleFuelType],
          };
        },
      );
    },
    onCompleted() {
      toast('Vehicle fuel type was successfully created!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when creating a vehicle fuel type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NVehicleFuelType.Create.Input['input']) => {
      void createVehicleFlueType({
        variables: {
          input: values,
        },
      });
    },
    [createVehicleFlueType],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new vehicle fuel type" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <VehicleFuelTypeFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
