import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateReviewTagSidebar } from './CreateReviewTag';
import { UpdateReviewTagSidebar } from './UpdateReviewTag';

export const ReviewTagSidebars = memo(() => (
  <>
    <FProvider>
      <CreateReviewTagSidebar />
    </FProvider>
    <FProvider>
      <UpdateReviewTagSidebar />
    </FProvider>
  </>
));
