import React, { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useHandleSubmit, useSetValues } from '../../../../../../libs/FForm';
import { GET_ALL_USERS, GET_USER, useUser } from '../../../../../../queries';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { UPDATE_USER } from '../../../../../../mutations';
import { NUser } from '../../../../../../types';
import { useAuth } from '../../../../../../context/Auth';
import { UserRoleBadge } from '../../../../../../components/UserRoleBadge';
import { UserFields } from '../components';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderUpdateUserSidebar = memo(() => {
  const { profile } = useAuth();
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const userId = useSearchParam(searchQuery, ESearchQuery.USER_ID);
  const handleSubmit = useHandleSubmit();
  const setValues = useSetValues();

  const { user, error, loading: isFetching } = useUser({ id: userId });

  const [updateUser, { loading: updateUserLoading }] = useMutation<
    NUser.Update.Output,
    NUser.Update.Input
  >(UPDATE_USER, {
    update(cache, { data: response }) {
      if (!response || !userId) return;

      cache.updateQuery<NUser.GetOne.Output, NUser.GetOne.Input>(
        { query: GET_USER, variables: { input: { id: userId } } },
        (record) => {
          if (!record) return;

          return {
            getUser: {
              ...record.getUser,
              ...response.users.update,
            },
          };
        },
      );

      cache.updateQuery<NUser.GetAll.Output>({ query: GET_ALL_USERS }, (record) => {
        if (!record) return;

        return {
          getAllUsers: record.getAllUsers.map((user) => {
            if (user.id === response.users.update.id) {
              return {
                ...user,
                ...response.users.update,
              };
            }
            return user;
          }),
        };
      });
    },
    onCompleted() {
      toast('User was successfully updated!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when updating a user): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  useEffect(() => {
    if (!user) return;

    if (user.userRole === NUser.Role.EMPLOYEE_ADMIN) {
      history.push({ pathname: history.location.pathname, search: '' });
      return;
    }

    if (user.userRole === NUser.Role.EMPLOYEE_FLEET_MANAGER) {
      if (profile?.userRole !== NUser.Role.EMPLOYEE_ADMIN) {
        history.push({ pathname: history.location.pathname, search: '' });
        return;
      }
    }

    setValues(user);
  }, [history, profile?.userRole, setValues, user]);

  const handleValidSubmit = useCallback(
    (values: Omit<NUser.Update.Input['input'], 'id'>) => {
      if (userId) {
        void updateUser({
          variables: {
            input: {
              id: userId,
              ...values,
            },
          },
        });
      }
    },
    [updateUser, userId],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!user) {
    return <NoData title={`No user data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs
        title="update user"
        className="d-flex align-items-center"
        titleClassName="me-1"
      >
        {user && <UserRoleBadge role={user.userRole} />}
      </UIBreadcrumbs>

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <UserFields
          loading={updateUserLoading}
          userRole={user.userRole}
          isCreating={false}
        />
        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={updateUserLoading}
          >
            {updateUserLoading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
