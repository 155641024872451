import { gql } from '@apollo/client';

export const ARCHIVE_CHAT = gql`
  mutation archiveChat($input: ArchiveChatInput!) {
    archiveChat(input: $input) {
      id
      type
      members {
        id
        firstName
        lastName
        photoFileId
        userRole
      }
      messages {
        id
        text
        createdAt
        user {
          id
          firstName
          lastName
          photoFileId
          userRole
        }
        readersSeen {
          userId
          hasSeen
        }
      }
      order {
        id
        uniqueCode
      }
      logsFileId
    }
  }
`;
