import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { NAnalytics } from '../../../../../types';
import { GET_ANALYTICS_STATIC_FOR_TOTAL_ORDERS } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardCardTotalOrders = memo(() => {
  const [timePeriod, setTimePeriod] = useState(NAnalytics.TimePeriod.CURRENT_WEEK);

  const { data, loading } = useQuery<
    NAnalytics.Static.GetForTotalOrders.Output,
    NAnalytics.Static.GetForTotalOrders.Input
  >(GET_ANALYTICS_STATIC_FOR_TOTAL_ORDERS, {
    variables: {
      input: {
        timePeriod,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as NAnalytics.TimePeriod);
  }, []);

  let value = data?.analyticsStaticForTotalOrders.currentPeriodTotalOrders;
  let percent = data?.analyticsStaticForTotalOrders.percentOrdersPreviousToCurrent;

  return (
    <Card className="dashboard-card bg_blue">
      <CardBody>
        <CardTitle className="dashboard-card__title">Total Orders</CardTitle>

        <div className="dashboard-card__content">
          {loading ? (
            <div className="spinner-border spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="dashboard-card__value">{value ?? 'No data'}</span>
          )}
          <span className="dashboard-card__percentages">
            {percent
              ? percent === 0
                ? '0%'
                : percent > 0
                ? `+${percent.toFixed(2)}%`
                : `${percent.toFixed(2)}%`
              : 'N/A'}
          </span>
        </div>

        <div className="d-flex">
          <select className="dashboard-select" onChange={handleChange}>
            <option value={NAnalytics.TimePeriod.CURRENT_WEEK}>this week</option>
            <option value={NAnalytics.TimePeriod.CURRENT_MONTH}>this month</option>
            <option value={NAnalytics.TimePeriod.CURRENT_YEAR}>this year</option>
          </select>
          <span className="dashboard-select-icon">
            <SvgExpandMore />
          </span>
        </div>
      </CardBody>
    </Card>
  );
});
