import { gql } from '@apollo/client';

export const UPDATE_MESSAGES_SEEN = gql`
  mutation messages($input: UpdateMessagesSeenInput!) {
    updateMessagesSeen(input: $input) {
      messageId
      chatId
    }
  }
`;
