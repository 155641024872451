import { gql } from '@apollo/client';

export const UPDATE_VEHICLE = gql`
  mutation updateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      id
      title
      vehicleType {
        id
        fuelType {
          id
          titleEn
          titleDe
        }
        photoFileId
        titleEn
        titleDe
        shortDescriptionDe
        shortDescriptionEn
        longDescriptionDe
        longDescriptionEn
        journeyInc5MinPrice
        over2kmPerKmPrice
        pickUpOver5MinPrice
        pickUpOverTimeInSeconds
        stopoverPrice
        cancelPrice
        outsideServiceHoursPercent
        minCoastInc2kmPrice
        bootCapacityLiters
        orderLimit
        volumePerOrder
      }
    }
  }
`;
