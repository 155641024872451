import { gql } from '@apollo/client';

export const UPDATE_LOCATION_SUBSCRIPTION = gql`
  subscription {
    updateLocationSubscription {
      id
      lat
      lng
    }
  }
`;
