import { useContext } from 'react';
import { DirectionsContext } from '../DirectionsContext';

export const useDirections = () => {
  const context = useContext(DirectionsContext);

  if (context === null) {
    throw new Error('useDirections must be within a DirectionsProvider');
  }

  return context;
};
