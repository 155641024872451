import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateParcelTypeSidebar } from './RenderCreateParcelTypeSidebar';

export const CreateParcelTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_PARCEL_TYPE} onClose={handleClear}>
      <RenderCreateParcelTypeSidebar />
    </Sidebar>
  );
});
