import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { useVehicleTypes, GET_ALL_VEHICLES } from '../../../../../../queries';
import { NVehicle } from '../../../../../../types';
import { CREATE_VEHICLE } from '../../../../../../mutations';
import { ESidebarPath } from '../../../../../../constants/sidebar.constant';
import { FInput, FSelect } from '../../../../../../libs/FForm/components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { toast } from 'react-toastify';

export const RenderCreateVehicleSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();

  const { vehicleTypes } = useVehicleTypes();

  const [createVehicle, { loading }] = useMutation<
    NVehicle.Create.Output,
    NVehicle.Create.Input
  >(CREATE_VEHICLE, {
    update: (cache, { data }) => {
      if (data?.createVehicle) {
        cache.updateQuery<NVehicle.GetAll.Output>(
          { query: GET_ALL_VEHICLES },
          (record) => {
            if (!record) return;

            return {
              getAllVehicles: [...record?.getAllVehicles, data.createVehicle],
            };
          },
        );
      }
    },
    onCompleted() {
      toast('Vehicle was successfully created!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when creating a vehicle): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NVehicle.Create.Input['input']) => {
      void createVehicle({
        variables: {
          input: values,
        },
      });
    },
    [createVehicle],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleAddNew = useCallback(() => {
    history.push({ search: ESidebarPath.CREATE_VEHICLE_TYPE });
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new vehicle" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <FInput
          label="Title"
          name="title"
          placeholder="Title"
          required
          disabled={loading}
        />
        <div>
          <label className="form-label">
            Vehicle type <span className="text-danger">*</span>
          </label>
          <div className="d-flex">
            <FSelect
              name="vehicleTypeId"
              data={vehicleTypes}
              placeholder="Vehicle type"
              extractor={(item) => [item.id, item.id, item.titleEn]}
              required
              disabled={loading}
            />
            <div className="ms-2">
              <Button variant="outline-light" onClick={handleAddNew}>
                <i className="mdi mdi-plus" />
              </Button>
            </div>
          </div>
        </div>

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
