import { gql } from '@apollo/client';

export const UPDATE_QUESTION_ANSWER = gql`
  mutation updateQuestionAnswer($input: UpdateQuestionAnswerInput!) {
    updateQuestionAnswer(input: $input) {
      id
      answerDe
      answerEn
      questionDe
      questionEn
      descriptionDe
      descriptionEn
      backgroundColor
      titleColor
      descriptionColor
      buttonTitleColor
      circleColor
    }
  }
`;
