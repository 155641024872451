import React, { memo, useMemo } from 'react';
import { useAuth } from '../../../../../../context/Auth';
import { FSelect } from '../../../../../../libs/FForm';
import { NUser } from '../../../../../../types';

interface UserRolesProps {
  selected: NUser.Role;
  onChange: (role: NUser.Role) => void;
}

export const UserRoleSelect = memo<UserRolesProps>(({ selected, onChange }) => {
  const { profile } = useAuth();
  const isSuperAdmin = profile?.userRole === NUser.Role.EMPLOYEE_ADMIN;

  const data = useMemo(
    () => [
      { role: NUser.Role.CUSTOMER, title: 'Customer' },
      { role: NUser.Role.BUSINESS_CUSTOMER, title: 'Business Customer' },
      { role: NUser.Role.EMPLOYEE_DRIVER, title: 'Driver' },
      ...(isSuperAdmin
        ? [
            {
              role: NUser.Role.EMPLOYEE_FLEET_MANAGER,
              title: 'Fleet Manager',
            },
          ]
        : []),
    ],
    [isSuperAdmin],
  );

  return (
    <FSelect
      label="Role"
      name="userRole"
      extractor={(item) => [item.role, item.role, item.title]}
      data={data}
      defaultValue={NUser.Role.EMPLOYEE_DRIVER}
      onChangeValue={(value) => onChange(value as NUser.Role)}
      required
    />
  );
});
