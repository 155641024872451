import React, { memo } from 'react';
import { GoogleMapDirections } from '../../../../components/GoogleMapDirections';
import { Colors } from '../../../../constants/color.constant';
import { useUserVehicleWaypoints } from '../../../../queries';

interface VehicleRouteProps {
  userVehicleId: string;
}

export const VehicleRoute = memo<VehicleRouteProps>(({ userVehicleId }) => {
  const { waypoints } = useUserVehicleWaypoints({
    id: userVehicleId,
  });

  if (waypoints.length) {
    return (
      <GoogleMapDirections waypoints={waypoints} strokeColor={Colors.VEHICLE_ROUTE} />
    );
  }

  return null;
});
