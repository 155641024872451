import React, { memo } from 'react';
import { FInput } from '../../../../../../libs/FForm/components';

interface VehicleFlueTypeFieldsProps {
  loading?: boolean;
}

export const VehicleFuelTypeFields = memo<VehicleFlueTypeFieldsProps>(({ loading }) => (
  <>
    <FInput
      label="Title (en)"
      name="titleEn"
      placeholder="In English"
      required
      disabled={loading}
    />
    <FInput
      label="Title (de)"
      name="titleDe"
      placeholder="In German"
      required
      disabled={loading}
    />
  </>
));
