import { EIss } from '../constants/iss.constant';
import { NUser } from './users';

export namespace NAuth {
  /* Mutation */
  export namespace SignIn {
    export type Input = {
      input: {
        email: string;
        password: string;
        userRole: NUser.RoleAvailableForLogin;
        iss: EIss;
        deviceType: string;
        fcmToken: string;
      };
    };

    export type Output = {
      signInWithEmailAndPassword: {
        accessToken: string;
        refreshToken: string;
      };
    };
  }
}
