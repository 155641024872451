import { gql } from '@apollo/client';

export const GET_ALL_VEHICLE_FUEL_TYPES = gql`
  query {
    vehicleFuelTypes {
      id
      titleEn
      titleDe
    }
  }
`;
