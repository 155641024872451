import { useMemo } from 'react';
import { GET_VEHICLE } from '../vehicles';
import { NVehicle } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NVehicle.GetOne.Output, NVehicle.GetOne.Input> {
  id: string | null | undefined;
}

export const useVehicle = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NVehicle.GetOne.Output,
    NVehicle.GetOne.Input
  >(GET_VEHICLE, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const vehicle = useMemo(() => data?.getVehicle ?? null, [data?.getVehicle]);

  return { vehicle, loading, error };
};
