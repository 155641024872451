import React, { memo, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NQuestionAnswer } from '../../../../../../types';
import { GET_ALL_QUESTIONS_ANSWERS, useQuestionAnswer } from '../../../../../../queries';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { QuestionAnswerFields } from '../components';
import { useHandleSubmit, useSetValues } from '../../../../../../libs/FForm';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { UPDATE_QUESTION_ANSWER } from '../../../../../../mutations/questions-answers';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderUpdateQuestionAnswerSidebar = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const questionAnswerId = useSearchParam(searchQuery, ESearchQuery.QUESTION_ANSWER_ID);
  const handleSubmit = useHandleSubmit();
  const setValues = useSetValues();

  const {
    questionAnswer,
    loading: isFetching,
    error,
  } = useQuestionAnswer({
    id: questionAnswerId,
  });

  const [updateQuestionAnswer, { loading }] = useMutation<
    NQuestionAnswer.Update.Output,
    NQuestionAnswer.Update.Input
  >(UPDATE_QUESTION_ANSWER, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NQuestionAnswer.GetAll.Output>(
        { query: GET_ALL_QUESTIONS_ANSWERS },
        (record) => {
          if (!record) return;

          return {
            questionsAnswers: record.questionsAnswers.map((questionAnswer) => {
              if (questionAnswer.id === data.updateQuestionAnswer.id) {
                return data.updateQuestionAnswer;
              }
              return questionAnswer;
            }),
          };
        },
      );
    },
    onCompleted() {
      toast('FAQ was successfully updated!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when updating a FAQ): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  useEffect(() => {
    if (questionAnswer) {
      setValues(questionAnswer);
    }
  }, [questionAnswer, setValues]);

  const handleValidSubmit = useCallback(
    async (values: Omit<NQuestionAnswer.Update.Input['input'], 'id'>) => {
      if (questionAnswerId) {
        void updateQuestionAnswer({
          variables: {
            input: {
              id: questionAnswerId,
              ...values,
            },
          },
        });
      }
    },
    [questionAnswerId, updateQuestionAnswer],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!questionAnswer) {
    return <NoData title={`No FAQ data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="update faq" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <QuestionAnswerFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
