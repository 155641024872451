const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const pastDays = ['Yesterday'];
const comingDays = ['Tomorrow'];

export const parseDate = (time: number): string => {
  const date = new Date(time);

  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setMilliseconds(0);

  const diff = date.getTime() - currentDate.getTime();
  const diffInDays = diff / 1000 / 60 / 60 / 24;

  const pickUpDate = new Date(date.getTime());
  const pickUpDay = pickUpDate.getDate();
  const pickUpMonth = pickUpDate.getMonth();
  const pickUpFullYear = pickUpDate.getFullYear();

  const pickUpHours = pickUpDate.getHours();
  const pickUpMinutes = `${pickUpDate.getMinutes()}`.padStart(2, '0');

  if (diffInDays >= 1) {
    // Coming days
    let dayTitle = days[pickUpDate.getDay()];

    if (Math.floor(diffInDays) === 1) {
      // Tomorrow
      dayTitle = comingDays[Math.floor(diffInDays) - 1];
    }

    return `${dayTitle} at ${pickUpHours}:${pickUpMinutes}`;
  }

  if (diffInDays < 0) {
    // Past days
    let dayTitle = `${months[pickUpMonth]} ${pickUpDay}, ${pickUpFullYear}`;

    if (Math.abs(Math.floor(diffInDays)) === 1) {
      // Yesterday
      dayTitle = pastDays[Math.abs(Math.floor(diffInDays)) - 1];
    }

    return `${dayTitle} at ${pickUpHours}:${pickUpMinutes}`;
  }

  return `at ${pickUpHours}:${pickUpMinutes}`;
};
