import React, { FC, memo } from 'react';
import { FInput } from '../../../../../../libs/FForm/components';
import { UIHSeparator } from '../../../../../../components/ui';
import FileUploader from '../../../../../../libs/FForm/components/FileUploader';
import FTextarea from '../../../../../../libs/FForm/components/FTextarea';
import { useVehicleFlueTypes } from '../../../../../../queries';
import { FSelect } from '../../../../../../libs/FForm';
import { UPLOAD_VEHICLE_TYPE_PHOTO_URL } from '../../../../../../constants';

export const VehicleTypeFields: FC<{ loading: boolean }> = memo(({ loading }) => {
  const { vehicleFuelTypes } = useVehicleFlueTypes();

  return (
    <>
      <FInput
        label="Title (en)"
        name="titleEn"
        labelClassName="mb-0"
        placeholder="In English"
        required
        disabled={loading}
      />
      <FInput
        label="Title (de)"
        name="titleDe"
        labelClassName="mb-0"
        placeholder="In German"
        required
        disabled={loading}
      />
      <FTextarea
        label="Short description (en)"
        name="shortDescriptionEn"
        placeholder="In English"
        required
        disabled={loading}
      />
      <FTextarea
        label="Short description (de)"
        name="shortDescriptionDe"
        placeholder="In German"
        required
        disabled={loading}
      />
      <FTextarea
        label="Long description (en)"
        name="longDescriptionEn"
        placeholder="In English"
        required
        disabled={loading}
      />
      <FTextarea
        label="Long description (de)"
        name="longDescriptionDe"
        placeholder="In German"
        required
        disabled={loading}
      />

      <FSelect
        label="Fuel Type"
        name="fuelTypeId"
        data={vehicleFuelTypes}
        extractor={(item) => [item.id, item.id, item.titleEn]}
        required
        disabled={loading}
      />

      <FileUploader
        label="Photo"
        name="photoFileId"
        url={UPLOAD_VEHICLE_TYPE_PHOTO_URL}
        required
      />

      <UIHSeparator />

      <FInput
        label="Journey inc 5 min charging time"
        name="journeyInc5MinPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="€"
        required
        disabled={loading}
      />
      <FInput
        label="Over 2 km price per km"
        name="over2kmPerKmPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="€"
        required
        disabled={loading}
      />
      <FInput
        label="Additional loading time over 5 minutes"
        name="pickUpOver5MinPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="€"
        required
        disabled={loading}
      />
      <FInput
        label="Additional loading time (sec)"
        name="pickUpOverTimeInSeconds"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="Sec."
        required
        disabled={loading}
      />
      <FInput
        label="Stopover"
        name="stopoverPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="€"
        required
        disabled={loading}
      />
      <FInput
        label="Cancelation"
        name="cancelPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="€"
        required={false}
        disabled
      />
      <FInput
        label="Booking on weekend / outside service hours"
        name="outsideServiceHoursPercent"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="%"
        required
        disabled={loading}
      />
      <FInput
        label="Minimum costs incl. 2km minimum"
        name="minCoastInc2kmPrice"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="%"
        required
        disabled={loading}
      />
      <FInput
        label="Boot capacity, liters"
        name="bootCapacityLiters"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="Liters"
        required
        disabled={loading}
      />
      <FInput
        label="Order limit per vehicle"
        name="orderLimit"
        type="number"
        style={{ width: '120px' }}
        className="d-flex align-items-center justify-content-between"
        labelClassName="mb-0"
        placeholder="Limit"
        required
        disabled={loading}
      />

      {/*<UIHSeparator />*/}
    </>
  );
});
