import { Dispatch, SetStateAction } from 'react';

export interface NetworkContextProps {
  isConnected: boolean;
  pageIsHidden: boolean;

  isInitializing: boolean;
  setIsInitializing: Dispatch<SetStateAction<boolean>>;

  accessTokenIsExpired: boolean;
  setAccessTokenIsExpired: Dispatch<SetStateAction<boolean>>;

  isExtendingSession: boolean;
  setIsExtendingSession: Dispatch<SetStateAction<boolean>>;

  subscriptionsAreConnected: boolean;
  setSubscriptionsAreConnected: Dispatch<SetStateAction<boolean>>;

  subscriptionsCanBeConnected: boolean;
  setSubscriptionsCanBeConnected: Dispatch<SetStateAction<boolean>>;

  isRefetching: boolean;
  setIsRefetching: Dispatch<SetStateAction<boolean>>;
}

export enum EWindowState {
  FOCUSED = 'focused',
  NOT_FOCUSED = 'not_focused',
  HIDDEN = 'hidden',
  FROZEN = 'frozen',
  TERMINATED = 'terminated',
}
