import { gql } from '@apollo/client';

export const GET_ALL_REVIEW_TAGS = gql`
  query {
    reviewTags {
      id
      name
    }
  }
`;
