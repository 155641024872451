import { gql } from '@apollo/client';

export const GET_VEHICLE_LOCATIONS = gql`
  query {
    getVehicleLocations {
      userVehicleLinkId
      lat
      lng
    }
  }
`;
