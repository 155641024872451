export const SSvgCar = ({ fill }: { fill: string }): string => {
  return (
    'data:image/svg+xml;utf8,<svg width="30" height="30" viewBox="0 0 24 24" ' +
    'fill="' +
    fill +
    '" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M19.5 8H17V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2 0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h1c.55 0 1-.45 1-1v-3.33c0-.43-.14-.85-.4-1.2L20.3 8.4c-.19-.25-.49-.4-.8-.4zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm13.5-8.5l1.96 2.5H17V9.5h2.5zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />' +
    '</svg>'
  );
};
