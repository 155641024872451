import { memo } from 'react';
import { useNetwork } from '../../context/Network';
import { useAuth } from '../../context/Auth';
import {
  useBarcodesAddedSubscription,
  useBarcodeUpdatedSubscription,
  useDriverLocationAddedSubscription,
  useDriverLocationRemovedSubscription,
  useDriverLocationUpdatedSubscription,
  useFirstOrderPickUpWaypointAddedSubscription,
  useFirstOrderPickUpWaypointRemovedSubscription,
  useMessageCreatedSubscription,
  useMessagesSeenSubscription,
  useOrderAddedSubscription,
  useOrderUpdatedVehicleSubscription,
  useOrderUpdatedSubscription,
  // useOrderUpdatedPaymentsSubscription,
  useParcelTypeAddedSubscription,
  useParcelTypeRemovedSubscription,
  useParcelTypeUpdatedSubscription,
  useReviewAddedSubscription,
  useSettingsUpdatedSubscription,
  useUserUpdatedSubscription,
  useUserVehicleLinkAddedSubscription,
  useVehicleTypeAddedSubscription,
  useVehicleTypeRemovedSubscription,
  useVehicleTypeUpdatedSubscription,
} from './subscriptions';
import { NUser } from '../../types';

export const SubscriptionListener = memo(() => {
  const { profile } = useAuth();
  const { isConnected, pageIsHidden, accessTokenIsExpired, subscriptionsCanBeConnected } =
    useNetwork();

  const isEmployeeAdmin = profile?.userRole === NUser.Role.EMPLOYEE_ADMIN;

  const tokenIsValid =
    isConnected && !pageIsHidden && !accessTokenIsExpired && subscriptionsCanBeConnected;

  /* First order pick up waypoint subscriptions */
  useFirstOrderPickUpWaypointAddedSubscription(tokenIsValid);
  useFirstOrderPickUpWaypointRemovedSubscription(tokenIsValid);

  /* Order subscriptions */
  useOrderAddedSubscription(tokenIsValid);
  useOrderUpdatedSubscription(tokenIsValid);
  useOrderUpdatedVehicleSubscription(tokenIsValid);
  // useOrderUpdatedPaymentsSubscription(tokenIsValid);

  /* Driver location subscriptions */
  useDriverLocationAddedSubscription(tokenIsValid);
  useDriverLocationUpdatedSubscription(tokenIsValid);
  useDriverLocationRemovedSubscription(tokenIsValid);

  /* Chat subscriptions */
  useMessagesSeenSubscription(tokenIsValid);
  useMessageCreatedSubscription(tokenIsValid);

  /* User subscriptions*/
  useUserUpdatedSubscription(tokenIsValid);

  /* Review subscriptions */
  useReviewAddedSubscription(tokenIsValid);

  /* Barcode subscriptions */
  useBarcodesAddedSubscription(tokenIsValid);
  useBarcodeUpdatedSubscription(tokenIsValid);

  /* Vehicle type subscriptions */
  useVehicleTypeAddedSubscription(tokenIsValid && isEmployeeAdmin);
  useVehicleTypeUpdatedSubscription(tokenIsValid && isEmployeeAdmin);
  useVehicleTypeRemovedSubscription(tokenIsValid && isEmployeeAdmin);

  /* Parcel type subscriptions */
  useParcelTypeAddedSubscription(tokenIsValid && isEmployeeAdmin);
  useParcelTypeUpdatedSubscription(tokenIsValid && isEmployeeAdmin);
  useParcelTypeRemovedSubscription(tokenIsValid && isEmployeeAdmin);

  /* Settings subscriptions */
  useSettingsUpdatedSubscription(tokenIsValid && isEmployeeAdmin);

  /* User vehicle link subscriptions */
  useUserVehicleLinkAddedSubscription(tokenIsValid);

  return null;
});
