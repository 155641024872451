import React, { memo, useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { BLOCK_USER, UNBLOCK_USER } from '../../../../mutations';
import { NUser } from '../../../../types';
import { GET_ALL_USERS } from '../../../../queries';

interface BlockButtonProps {
  id: string;
  isBlocked: boolean;
}

export const BlockButton = memo<BlockButtonProps>(({ id, isBlocked }) => {
  const client = useApolloClient();
  const [unlock, setUnlock] = useState(false);

  const [blockUser, { loading: blockUserLoading }] = useMutation<
    NUser.Block.Output,
    NUser.Block.Input
  >(BLOCK_USER, {
    onCompleted() {
      client.cache.updateQuery<NUser.GetAll.Output>(
        { query: GET_ALL_USERS },
        (record) => {
          if (!record) return;

          return {
            getAllUsers: record.getAllUsers.map((user) => {
              if (user.id === id) {
                return { ...user, isBlocked: true };
              }
              return user;
            }),
          };
        },
      );
      setUnlock(false);
      toast('User was successfully blocked!', {
        type: 'success',
      });
    },
    onError(err) {
      setUnlock(false);
      toast(`Error (when blocking a user): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const [unblockUser, { loading: unblockUserLoading }] = useMutation<
    NUser.Unblock.Output,
    NUser.Unblock.Input
  >(UNBLOCK_USER, {
    onCompleted() {
      client.cache.updateQuery<NUser.GetAll.Output>(
        { query: GET_ALL_USERS },
        (record) => {
          if (!record) return;

          return {
            getAllUsers: record.getAllUsers.map((user) => {
              if (user.id === id) {
                return { ...user, isBlocked: false };
              }
              return user;
            }),
          };
        },
      );
      setUnlock(false);
      toast('User was successfully unblocked!', {
        type: 'success',
      });
    },
    onError(err) {
      setUnlock(false);
      toast(`Error (when unblocking a user): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(() => {
    if (!unlock) {
      setUnlock(true);
    } else {
      if (isBlocked) {
        void unblockUser({ variables: { input: { id } } });
      } else {
        void blockUser({ variables: { input: { id } } });
      }
    }
  }, [blockUser, id, isBlocked, unblockUser, unlock]);

  return (
    <Button
      color={isBlocked ? 'primary' : 'danger'}
      className="d-flex align-items-center justify-content-center"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={blockUserLoading || unblockUserLoading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : isBlocked ? (
        <i className="fas fa-arrow-up text-white" />
      ) : (
        <i className="fas fa-ban text-white" />
      )}
    </Button>
  );
});
