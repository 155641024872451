import { gql } from '@apollo/client';

export const DELETE_PARCEL_TYPE_SUBSCRIPTION = gql`
  subscription {
    parcelTypes {
      delete {
        id
      }
    }
  }
`;
