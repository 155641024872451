import { useSubscription } from '@apollo/client';
import { DELETE_USER_VEHICLE_LOCATION_SUBSCRIPTION } from '../../../subscriptions';
import { NVehicle } from '../../../types';
import { GET_VEHICLE_LOCATIONS } from '../../../queries';

export const useDriverLocationRemovedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NVehicle.Location.On.Delete.Output>(
    DELETE_USER_VEHICLE_LOCATION_SUBSCRIPTION,
    {
      skip: !tokenIsValid,
      shouldResubscribe: true,
      onData({ client, data: { data } }) {
        if (!data) return;

        const { userVehicleLinkId } = data.deleteUserVehicleLocationSubscription;

        client.cache.updateQuery<NVehicle.Location.GetAll.Output>(
          { query: GET_VEHICLE_LOCATIONS },
          (record) => {
            if (!record) return;

            return {
              getVehicleLocations:
                record?.getVehicleLocations.filter(
                  (item) => item.userVehicleLinkId !== userVehicleLinkId,
                ) ?? [],
            };
          },
        );
      },
    },
  );
};
