import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { NVehicleFuelType } from '../../../../../../types';
import { REMOVE_VEHICLE_FUEL_TYPE } from '../../../../../../mutations';
import { GET_ALL_VEHICLE_FUEL_TYPES } from '../../../../../../queries';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);

  const [removeVehicleFuelType, { loading }] = useMutation<
    NVehicleFuelType.Remove.Output,
    NVehicleFuelType.Remove.Input
  >(REMOVE_VEHICLE_FUEL_TYPE, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NVehicleFuelType.GetAll.Output>(
        { query: GET_ALL_VEHICLE_FUEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            vehicleFuelTypes: record.vehicleFuelTypes.filter(
              (vehicleFlueType) => vehicleFlueType.id !== id,
            ),
          };
        },
      );
    },
    onCompleted() {
      toast('Review tag was successfully deleted!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when deleting a review tag): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(() => {
    if (!unlock) {
      setUnlock(true);
    } else {
      void removeVehicleFuelType({ variables: { input: { id } } });
    }
  }, [removeVehicleFuelType, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center ms-1"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
