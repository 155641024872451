import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateFuelTypeSidebar } from './RenderCreateFuelTypeSidebar';

export const CreateFuelTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_VEHICLE_FUEL_TYPE} onClose={handleClear}>
      <RenderCreateFuelTypeSidebar />
    </Sidebar>
  );
});
