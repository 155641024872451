import { useMemo } from 'react';
import { GET_CHATS } from '../chats';
import { NChat } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NChat.GetMany.Output, NChat.GetMany.Input> {
  type: NChat.Type;
}

export const useChats = ({ type, ...options }: Params) => {
  const { data, loading } = useAppQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
    GET_CHATS,
    {
      skip: !type,
      variables: {
        type,
      },
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const chats = useMemo(() => {
    if (!data?.chats) return [];

    return [...data.chats].sort((a, b) => {
      if (a.messages.length && b.messages.length) {
        return (
          new Date(b.messages[b.messages.length - 1].createdAt).getTime() -
          new Date(a.messages[a.messages.length - 1].createdAt).getTime()
        );
      }

      if (a.messages.length && !b.messages.length) {
        return -1;
      }

      if (!a.messages.length && b.messages.length) {
        return 1;
      }

      return 0;
    });
  }, [data]);

  return { chats, loading };
};
