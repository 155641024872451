import { gql } from '@apollo/client';

export const CREATE_BARCODES_SUBSCRIPTION = gql`
  subscription {
    barcodes {
      create {
        id
        isUsed
        uniqueCode
      }
    }
  }
`;
