import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FInput } from '../../../../../../libs/FForm/components';

interface ParcelTypeFieldsProps {
  loading?: boolean;
}

export const ParcelTypeFields = memo<ParcelTypeFieldsProps>(({ loading }) => (
  <>
    <FInput label="Title" name="title" placeholder="Title" required disabled={loading} />
    <FInput
      label="Acronym"
      name="designation"
      placeholder="Acronym"
      required
      disabled={loading}
    />
    <FInput
      label="Description"
      name="description"
      placeholder="Description"
      required
      disabled={loading}
    />
    <Row>
      <Col>
        <FInput
          label="Width"
          type="number"
          name="width"
          placeholder="Width"
          required
          disabled={loading}
        />
      </Col>
      <Col>
        <FInput
          label="Height"
          type="number"
          name="height"
          placeholder="Height"
          required
          disabled={loading}
        />
      </Col>
      <Col>
        <FInput
          label="Length"
          type="number"
          name="length"
          placeholder="Length"
          required
          disabled={loading}
        />
      </Col>
    </Row>
    <FInput
      label="Max weight (grams)"
      type="number"
      name="maxWeightInGrams"
      placeholder="Grams"
      required
      disabled={loading}
      min={10}
    />
  </>
));
