import React, { FC, memo } from 'react';
import { useSearchQuery } from '../../../../../../../hooks';
import { ESearchQuery } from '../../../../../../../constants/query.constant';
import { EmployeeChatTab, OrderDriverChatTab, OrderSupportChatTab } from './componets';
import { NChat } from '../../../../../../../types';

const tabs: {
  [key in NChat.Tab]: FC<any>;
} = {
  [NChat.Tab.ORDER_DRIVER]: OrderDriverChatTab,
  [NChat.Tab.ORDER_SUPPORT]: OrderSupportChatTab,
  [NChat.Tab.EMPLOYEE]: EmployeeChatTab,
};

export const Tabs = memo(() => {
  const searchQuery = useSearchQuery();
  const chatTab = searchQuery.get(ESearchQuery.CHAT_TAB) as NChat.Tab | null;

  const Tab = chatTab !== null && chatTab in tabs ? tabs[chatTab] : null;

  return <>{Tab && <Tab />}</>;
});
