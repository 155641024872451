import React, { memo } from 'react';
import { SvgCar } from '../../assets/icons';
import { NVehicle, NVehicleType } from '../../types';

export type UIVehicleProps = Pick<NVehicle.Entity, 'title'> & {
  vehicleType: Pick<NVehicleType.Entity, 'titleEn'>;
};

export const UIVehicle = memo<UIVehicleProps>(({ title, vehicleType }) => (
  <div className="item">
    <div className="item__icon">
      <SvgCar />
    </div>

    <div className="item__details">
      <div className="item__title">{title}</div>
      <div className="item__subtitle">{vehicleType.titleEn}</div>
    </div>

    {/*<div className="item__button_more">*/}
    {/*  <SvgArrowRight />*/}
    {/*</div>*/}
  </div>
));
