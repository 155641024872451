import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { NOrder } from '../../types';
import SvgPackage from '../../assets/icons/SvgPackage';
import { OrderStatusBadge } from '../OrderStatusBadge';
import { ESearchQuery } from '../../constants/query.constant';
import { ESidebarPath } from '../../constants/sidebar.constant';
import { SvgArrowRight } from '../../assets/icons';

type UIOrderProps = Pick<
  NOrder.Entity,
  'id' | 'uniqueCode' | 'orderStatus' | 'pickUpTimeType'
> & {
  isInFavorites?: boolean;
};

export const UIOrder = memo<UIOrderProps>(
  ({ id, uniqueCode, orderStatus, pickUpTimeType, isInFavorites }) => {
    const search = `${ESearchQuery.ORDER_ID}=${id}&${ESearchQuery.ORDER_ROUTE}=${id}&${ESidebarPath.ORDER}`;

    return (
      <NavLink to={{ search }} className="item">
        <div className="item__icon">
          <SvgPackage />
        </div>

        <div className="item__details">
          <div
            className={classNames('item__title', { item__title_disabled: isInFavorites })}
          >
            Order #{uniqueCode}
          </div>
          <div className="item__subtitle">
            <OrderStatusBadge orderStatus={orderStatus} />
            {pickUpTimeType === NOrder.PickUpTimeType.URGENT && (
              <span className="badge badge-soft-danger ms-1">Urgent</span>
            )}
          </div>
        </div>

        <div className="item__button_more">
          <SvgArrowRight />
        </div>
      </NavLink>
    );
  },
);
