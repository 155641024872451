import React, { CSSProperties, memo } from 'react';
import { FILES_URL } from '../constants';
import { useAuth } from '../context/Auth';

export interface ImageProps {
  id: string | null;
  style?: CSSProperties;
  className?: string;
}

export const ProtectedImage = memo<ImageProps>(({ id, style, className }) => {
  const { accessToken } = useAuth();

  if (!id || !accessToken) {
    return (
      <img
        src={'https://cdn-icons-png.flaticon.com/512/107/107817.png'}
        alt="icons"
        style={{ ...style, padding: 8 }}
        className={className}
        width="100%"
        height="100%"
      />
    );
  }

  const src = `${FILES_URL}/${id}?accessToken=${accessToken}`;

  return <img src={src} alt="icons" style={style} className={className} />;
});
