import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSearchQuery } from '../../../../../../../hooks';
import { ESearchQuery } from '../../../../../../../constants/query.constant';
import { useChat } from '../../../../../../../queries';
import { SvgBack } from '../../../../../../../assets/icons';
import { NChat, NUser } from '../../../../../../../types';

export const Header = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);

  const { chat } = useChat({ chatId });

  const user = chat?.members.find(({ userRole }) => {
    if (chat?.type === NChat.Type.ORDER_DRIVER) {
      return userRole === NUser.Role.CUSTOMER;
    }
    if (chat?.type === NChat.Type.ORDER_SUPPORT) {
      return userRole === NUser.Role.CUSTOMER;
    }
    return userRole === NUser.Role.EMPLOYEE_DRIVER;
  });

  const handleClick = useCallback(() => {
    searchQuery.delete(ESearchQuery.CHAT_ID);
    searchQuery.delete(ESearchQuery.CHAT_TYPE);
    history.push({ search: searchQuery.toString() });
  }, [history, searchQuery]);

  if (!chatId) return null;

  return (
    <div className="chat-room__header">
      <div className="btn_back" onClick={handleClick}>
        <div className="btn_back__icon">
          <SvgBack />
        </div>
        <span className="btn_back__title">Back</span>
      </div>
      <span className="chat-room__user-name">
        {user ? `${user.firstName} ${user.lastName}` : 'Loading...'}
      </span>
    </div>
  );
});
