import React, { memo } from 'react';
import { FInput } from '../../../../../../libs/FForm/components';
import FTextarea from '../../../../../../libs/FForm/components/FTextarea';

interface PraiseTagFieldsProps {
  loading?: boolean;
}

export const QuestionAnswerFields = memo<PraiseTagFieldsProps>(({ loading }) => (
  <>
    <FInput
      label="Question (en)"
      name="questionEn"
      placeholder="In English"
      required
      disabled={loading}
    />
    <FInput
      label="Question (de)"
      name="questionDe"
      placeholder="In German"
      required
      disabled={loading}
    />

    <FTextarea
      label="Description (en)"
      name="descriptionEn"
      placeholder="In English"
      required
      disabled={loading}
    />
    <FTextarea
      label="Description (de)"
      name="descriptionDe"
      placeholder="In German"
      required
      disabled={loading}
    />

    <FTextarea
      label="Answer (en)"
      name="answerEn"
      placeholder="In English"
      required
      disabled={loading}
    />
    <FTextarea
      label="Answer (de)"
      name="answerDe"
      placeholder="In German"
      required
      disabled={loading}
    />

    <FInput
      label="Background color"
      type="color"
      name="backgroundColor"
      required
      style={{ padding: 0 }}
    />
    <FInput
      label="Title color"
      type="color"
      name="titleColor"
      required
      style={{ padding: 0 }}
    />
    <FInput
      label="Description color"
      type="color"
      name="descriptionColor"
      required
      style={{ padding: 0 }}
    />
    <FInput
      label="Button title color"
      type="color"
      name="buttonTitleColor"
      required
      style={{ padding: 0 }}
    />
    <FInput
      label="Circle color"
      type="color"
      name="circleColor"
      required
      style={{ padding: 0 }}
    />
  </>
));
