export namespace NQuestionAnswer {
  export interface Entity {
    id: string;
    questionDe: string;
    questionEn: string;
    answerDe: string;
    answerEn: string;
    descriptionDe: string;
    descriptionEn: string;
    backgroundColor: string;
    titleColor: string;
    descriptionColor: string;
    buttonTitleColor: string;
    circleColor: string;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { questionAnswer: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { questionsAnswers: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createQuestionAnswer: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Entity };
    export type Output = { updateQuestionAnswer: Entity };
  }

  /* Mutation */
  export namespace Remove {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { removeQuestionAnswer: Pick<Entity, 'id'> };
  }
}
