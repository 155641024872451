import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderQuestionsAnswersPage } from './RenderQuestionsAnswersPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const QuestionsAnswersPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('FAQ')}</title>
    </Helmet>

    <UIBreadcrumbs title="FAQ" />

    <RenderQuestionsAnswersPage />
  </>
));
