import { gql } from '@apollo/client';

export const GET_ORDER = gql`
  query getOrder($input: GetOrderInput!) {
    order(input: $input) {
      id
      downTimeInSeconds
      customer {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
      }
      driver {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
        rating
      }
      orderStatus
      orderWaypointsLengthInKm
      pickUpTime
      pickUpTimeType
      rejectionReason
      uniqueCode
      vehicle {
        id
        title
      }
      waypoints {
        id
        address
        comment
        contactName
        contactPhoneNumber
        isReceiverSignature
        isVerifyReceiverID
        lat
        lng
        waypointType
        waypointStatus
        parcels {
          id
          description
          parcelType {
            id
            title
            designation
            description
            height
            length
            width
          }
        }
        history {
          id
          waypointStatus
        }
        createdAt
        deliveryConfirmationFileId
      }
      orderSummaryFileId
    }
  }
`;
