import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useAuth } from '../../context/Auth';
import { Preloader } from '../../components/Preloader';
import { useNetwork } from '../../context/Network';

type AuthRouteProps = Omit<RouteProps, 'component'>;

export const AuthRoute = memo<AuthRouteProps>(({ children, ...rest }) => {
  const { isLoggedIn } = useAuth();
  const { isInitializing } = useNetwork();

  /**
   * If the session is extended,
   * return the loader
   * */
  if (isInitializing) {
    return <Preloader />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const from: Location | undefined = (location?.state as { from?: Location })?.from;

        /**
         * If the user is logged in,
         * redirect him to the location he came from or to the homepage
         * */
        if (isLoggedIn) {
          if (from) {
            return <Redirect to={from} />;
          } else {
            return <Redirect to={'/'} />;
          }
        }

        /* Otherwise return some auth page */
        return children;
      }}
    />
  );
});
