import { useMemo } from 'react';
import { NParcelType } from '../../types';
import { GET_PARCEL_TYPE } from '../parcel-types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<NParcelType.GetOne.Output, NParcelType.GetOne.Input> {
  id: string | null | undefined;
}

export const useParcelType = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NParcelType.GetOne.Output,
    NParcelType.GetOne.Input
  >(GET_PARCEL_TYPE, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const parcelType = useMemo(() => data?.getParcelType ?? null, [data?.getParcelType]);

  return { parcelType, loading, error };
};
