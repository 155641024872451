import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateFuelTypeSidebar } from './CreateVehicleFuelType';
import { UpdateFuelTypeSidebar } from './UpdateVehicleFuelType';

export const VehicleFuelTypeSidebars = memo(() => (
  <>
    <FProvider>
      <CreateFuelTypeSidebar />
    </FProvider>
    <FProvider>
      <UpdateFuelTypeSidebar />
    </FProvider>
  </>
));
