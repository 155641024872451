import React, { memo } from 'react';
import { useHandleClear } from '../../../../../../libs/FForm';
import { Sidebar } from '../../../Sidebar';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderAttachOrderSidebar } from './RenderAttachOrderSidebar';

export const AttachOrderSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.ATTACH_ORDER} onClose={handleClear} width={400}>
      <RenderAttachOrderSidebar />
    </Sidebar>
  );
});
