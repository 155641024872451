import { useContext } from 'react';
import { LayoutMethodsContext } from '../LayoutProvider';

export const useLayoutMethods = () => {
  const context = useContext(LayoutMethodsContext);

  if (context === null) {
    throw new Error('useLayoutMethods must be inside a LayoutProvider');
  }

  return context;
};
