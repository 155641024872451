import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

interface UIButtonProps {
  children?: ReactNode | undefined;
  selected?: boolean;
  onClick?: () => void;
}

export const UIButton = memo<UIButtonProps>(({ children, selected, onClick }) => (
  <div
    className={classNames('ui__button', {
      ui__button_selected: selected,
    })}
    onClick={onClick}
  >
    {children}
  </div>
));
