import { useSubscription } from '@apollo/client';
import { UPDATE_PARCEL_TYPE_SUBSCRIPTION } from '../../../subscriptions';
import { NParcelType } from '../../../types';
import { GET_ALL_PARCEL_TYPES } from '../../../queries';

export const useParcelTypeUpdatedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NParcelType.On.Update.Output>(UPDATE_PARCEL_TYPE_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { update } = data.parcelTypes;

      if (!update) {
        return;
      }

      client.cache.updateQuery<NParcelType.GetAll.Output>(
        { query: GET_ALL_PARCEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            getAllParcelTypes: record.getAllParcelTypes.map((parcelType) => {
              if (parcelType.id === update.id) {
                return update;
              }
              return parcelType;
            }),
          };
        },
      );
    },
  });
};
