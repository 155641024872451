import React, { memo } from 'react';
import classNames from 'classnames';

interface UIBadgeProps {
  value: string | number | null | undefined;
  position: 'right' | 'right-top' | 'right-right';
  size?: 'small';
}

export const UIBadge = memo<UIBadgeProps>(({ value, position, size }) => (
  <div
    className={classNames('ui__badge', {
      [`ui__badge_position_${position}`]: !!position,
      [`ui__badge_size_${size}`]: !!size,
      ui__badge_hidden: !value,
    })}
  >
    {value}
  </div>
));
