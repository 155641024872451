import { gql } from '@apollo/client';

export const GET_VEHICLE_TYPE = gql`
  query vehicleType($input: GetVehicleTypeInput!) {
    vehicleType(input: $input) {
      id
      fuelType {
        id
        titleDe
        titleEn
      }
      photoFileId
      titleEn
      titleDe
      shortDescriptionDe
      shortDescriptionEn
      longDescriptionDe
      longDescriptionEn
      journeyInc5MinPrice
      over2kmPerKmPrice
      pickUpOver5MinPrice
      pickUpOverTimeInSeconds
      stopoverPrice
      cancelPrice
      outsideServiceHoursPercent
      minCoastInc2kmPrice
      bootCapacityLiters
      orderLimit
    }
  }
`;
