import { useMemo } from 'react';
import { GET_ORDER_FIRST_PICK_UP_WAYPOINTS } from '../orders';
import { GetOrderFirstPickUpWaypointsOutput } from '../../types';
import { useAppQuery } from '../../apollo';

export const useFirstOrderPickUpWaypoints = () => {
  const { data, loading } = useAppQuery<GetOrderFirstPickUpWaypointsOutput>(
    GET_ORDER_FIRST_PICK_UP_WAYPOINTS,
  );

  const firstOrderPickUpWaypoints = useMemo(
    () => data?.orderFirstPickUpWaypoints ?? [],
    [data?.orderFirstPickUpWaypoints],
  );

  return { firstOrderPickUpWaypoints, loading };
};
