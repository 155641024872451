import { gql } from '@apollo/client';

export const GET_PARCEL_TYPE = gql`
  query getParcelType($input: GetParcelTypeInput!) {
    getParcelType(input: $input) {
      id
      title
      designation
      description
      height
      length
      width
      maxWeightInGrams
    }
  }
`;
