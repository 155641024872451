import React, { memo, useEffect, useState } from 'react';
import { DirectionsRenderer, Marker } from '@react-google-maps/api';
import { SSvgDropOff, SSvgPickUp } from '../assets/icons';
import { Colors } from '../constants/color.constant';
import { useDirections } from '../context/Directions/hooks/useDirections';
import { NWaypoint } from '../types';

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type DirectionsWaypoint = google.maps.DirectionsWaypoint;

interface GoogleMapDirectionsProps {
  cacheRecordId?: string;
  waypoints: Pick<NWaypoint.Entity, 'id' | 'waypointType' | 'lat' | 'lng'>[];
  strokeColor: string;
}

export const GoogleMapDirections = memo<GoogleMapDirectionsProps>(
  ({ cacheRecordId = null, waypoints, strokeColor }) => {
    const cache = useDirections();
    const [directions, setDirections] = useState<DirectionsResult | null>(
      cacheRecordId ? cache[cacheRecordId] : null,
    );

    useEffect(() => {
      if (directions) {
        return;
      }

      if (waypoints.length >= 2) {
        /* #region Fetch directions */
        const LatLng = google.maps.LatLng;

        const service = new google.maps.DirectionsService();

        const origin: LatLngLiteral = { lat: waypoints[0].lat, lng: waypoints[0].lng };
        const destination: LatLngLiteral = {
          lat: waypoints[waypoints.length - 1].lat,
          lng: waypoints[waypoints.length - 1].lng,
        };

        const mappedData: DirectionsWaypoint[] = waypoints
          .slice(1, waypoints.length - 1)
          .map((point) => ({
            location: new LatLng(point.lat, point.lng),
            stopover: true,
          }));

        service.route(
          {
            origin,
            waypoints: mappedData,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
          },
          (result, status) => {
            if (status === 'OK' && result) {
              setDirections(result);
              if (cacheRecordId) {
                cache[cacheRecordId] = result;
              }
            }
          },
        );
        /* #endregion */
      }
    }, [cache, cacheRecordId, directions, waypoints]);

    if (directions) {
      return (
        <>
          <DirectionsRenderer
            directions={directions}
            options={{
              directions,
              markerOptions: { visible: false },
              preserveViewport: true,
              polylineOptions: {
                strokeColor: strokeColor,
                strokeWeight: 5,
              },
            }}
          />
          {directions &&
            waypoints.map(({ id, lat, lng, waypointType }, i) => {
              // if (i === 0) {
              //   return null;
              // }

              const icon =
                waypointType === NWaypoint.Type.PICK_UP
                  ? SSvgPickUp({ fill: Colors.SELECTED_ORDER_PICK_UP })
                  : SSvgDropOff({ fill: Colors.SELECTED_ORDER_DROP_OFF });

              return <Marker key={id} position={{ lat, lng }} icon={icon} />;
            })}
        </>
      );
    }

    return null;
  },
);
