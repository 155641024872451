import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import classes from 'classnames';
import { useValidator, useConnector } from '../hooks';
import DatePicker, { DateObject } from 'react-multi-date-picker';

interface FInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  defaultValue?: string;
  getter?: (value: string | number | null) => any;
  setter?: (value: string) => string;
  onChangeValue?: (value: string) => void;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const getDate = (value?: string | undefined) => {
  const date = new Date(value ?? Date.now());
  const year = date.getFullYear();
  const month = ('00' + (date.getMonth() + 1)).slice(-2);
  const day = ('00' + date.getDate()).slice(-2);
  return `${month}/${day}/${year}`;
};

const FDatePicker: React.FC<FInputProps> = ({
  label,
  name,
  defaultValue,
  getter = (value) => value,
  setter = (value) => getDate(value),
  onChangeValue,
  required,
  errorMessage,
  disabled,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue ?? getDate());
  const ref = useRef<HTMLInputElement>(null);

  const handleSetValue = useCallback(
    (v: string) => {
      const value: string = setter(v);
      setValue(value);
      if (onChangeValue) {
        onChangeValue(value);
      }
    },
    [onChangeValue, setter],
  );

  useConnector(name, getter(value), handleSetValue, defaultValue ?? getDate());

  const isValid = useValidator(name, () => {
    if (!required) return true;

    if (!value) return false;

    return !!/\d{2}\/\d{2}\/\d{4}/.exec(value);
  });

  return (
    <DatePicker
      containerStyle={{ display: 'block' }}
      format="MM/DD/YYYY"
      value={value}
      calendarPosition="top-start"
      onChange={(date: DateObject | null) => {
        if (date) {
          const year = date.year;
          const month = ('00' + date.month.number).slice(-2);
          const day = ('00' + date.day).slice(-2);
          handleSetValue(`${month}/${day}/${year}`);
        }
      }}
      render={(
        value: string,
        openCalendar: () => void,
        handleValueChange: (e: ChangeEvent<HTMLInputElement>) => void,
      ) => {
        return (
          <div
            className={classes({
              'mb-3': true,
              'text-danger': !isValid,
            })}
          >
            {label && (
              <label className="form-label">
                {label}{' '}
                {required ? (
                  <span className="text-danger">*</span>
                ) : (
                  <span className="text-secondary">(optional)</span>
                )}
              </label>
            )}
            <input
              ref={ref}
              type="text"
              className={classes({
                'form-control': true,
                'is-invalid': !isValid,
              })}
              value={value}
              onChange={handleValueChange}
              onFocus={openCalendar}
              disabled={disabled}
              placeholder="MM/DD/YYYY"
              {...props}
            />
            {!isValid && (
              <div className="form-text text-danger fw-bold">Incorrect date</div>
            )}
          </div>
        );
      }}
    />
  );
};

export default FDatePicker;
