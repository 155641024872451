import { useSubscription } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { NReview } from '../../../types';
import { REVIEW_ADDED_SUBSCRIPTION } from '../../../subscriptions';
import { ERoutes } from '../../../constants/route.constant';
import { ESearchQuery } from '../../../constants/query.constant';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { GET_ALL_REVIEWS } from '../../../queries';

export const useReviewAddedSubscription = (tokenIsValid: boolean) => {
  const { push } = useHistory();

  useSubscription<NReview.On.Added.Output>(REVIEW_ADDED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { reviewAdded } = data;

      let message: JSX.Element | null = null;

      if (reviewAdded.service <= 2) {
        message = (
          <b>Added new review with a service rating of "{reviewAdded.service}"</b>
        );
      } else if (reviewAdded.safety <= 2) {
        message = <b>Added new review with a safety rating of "{reviewAdded.safety}"</b>;
      }

      if (message) {
        toast(message, {
          type: 'error',
          autoClose: 10000,
          onClick() {
            push(
              `${ERoutes.REVIEWS}?${ESearchQuery.REVIEW_ID}=${reviewAdded.id}&${ESidebarPath.REVIEW}`,
            );
          },
        });
      }

      client.cache.updateQuery<NReview.GetAll.Output>(
        { query: GET_ALL_REVIEWS },
        (record) => {
          if (!record) return;

          return {
            reviews: [reviewAdded, ...record.reviews],
          };
        },
      );
    },
  });
};
