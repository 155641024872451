import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useSetValues } from '../../../../../../libs/FForm';
import { useUser } from '../../../../../../queries';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { useAuth } from '../../../../../../context/Auth';
import { UserRoleBadge } from '../../../../../../components/UserRoleBadge';
import { UserFields } from '../components';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';
import { NUser } from '../../../../../../types';

export const RenderUserSidebar = memo(() => {
  const { profile } = useAuth();
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const userId = useSearchParam(searchQuery, ESearchQuery.USER_ID);
  const setValues = useSetValues();

  const { user, error, loading } = useUser({ id: userId });

  useEffect(() => {
    if (!user) return;

    if (user.userRole === NUser.Role.EMPLOYEE_ADMIN) {
      history.push({ pathname: history.location.pathname, search: '' });
      return;
    }

    if (user.userRole === NUser.Role.EMPLOYEE_FLEET_MANAGER) {
      if (profile?.userRole !== NUser.Role.EMPLOYEE_ADMIN) {
        history.push({ pathname: history.location.pathname, search: '' });
        return;
      }
    }

    setValues(user);
  }, [history, profile?.userRole, setValues, user]);

  if (loading) {
    return <DataIsFetching />;
  }

  if (!user) {
    return <NoData title={`No user data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs
        title="about the user"
        className="d-flex align-items-center"
        titleClassName="me-1"
      >
        {user && <UserRoleBadge role={user.userRole} />}
      </UIBreadcrumbs>

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <UserFields
          loading={true}
          userRole={user.userRole}
          isCreating={false}
          showDeleteButtonInFileUploader={false}
        />
      </UIContainer>
    </>
  );
});
