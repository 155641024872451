import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderParcelTypesPage } from './RenderParcelTypesPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const ParcelTypesPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Parcel Types')}</title>
    </Helmet>

    <UIBreadcrumbs title="Parcel Types" />

    <RenderParcelTypesPage />
  </>
));
