import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Chart } from 'chart.js';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_DYNAMIC_FOR_LOSSES_BY_REASON_PERIOD } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardChartAverageNumberOfLostOrdersByReason = memo(() => {
  const cnvRef = useRef<HTMLCanvasElement>(null);

  const [timePeriod, setTimePeriod] = useState(NAnalytics.TimePeriod.CURRENT_WEEK);
  const [reason, setReason] = useState(NAnalytics.LostOrdersReason.CANCELED);

  const { data } = useQuery<
    NAnalytics.Dynamic.GetForLossesByReasonPeriod.Output,
    NAnalytics.Dynamic.GetForLossesByReasonPeriod.Input
  >(GET_ANALYTICS_DYNAMIC_FOR_LOSSES_BY_REASON_PERIOD, {
    variables: {
      input: {
        timePeriod,
        reason,
      },
    },
  });

  useEffect(() => {
    if (!cnvRef.current || !data?.analyticsDynamicForOrderLossesByReasonPeriod) return;

    let value = [
      ...data.analyticsDynamicForOrderLossesByReasonPeriod.dynamicLossesByPeriods,
    ];

    sortByMonth(value);

    const chart = new Chart(cnvRef.current, {
      type: 'bar',
      data: {
        labels: value.map((x) => x.period),
        datasets: [
          {
            label: getLabel(reason),
            data: value.map((x) => x.lossesByReason),
            borderWidth: 1,
            ...getColors(reason),
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data?.analyticsDynamicForOrderLossesByReasonPeriod, reason]);

  const handleChangeReason = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value as NAnalytics.LostOrdersReason);
  }, []);

  const handleChangeTimePeriod = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as NAnalytics.TimePeriod);
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>Average Number of Lost Orders by Reason</CardTitle>

        <div className="d-flex">
          <select
            className="dashboard-select text-black"
            style={{ marginBottom: 10 }}
            onChange={handleChangeTimePeriod}
          >
            <option value={NAnalytics.TimePeriod.CURRENT_WEEK}>this week</option>
            <option value={NAnalytics.TimePeriod.CURRENT_MONTH}>this month</option>
            <option value={NAnalytics.TimePeriod.CURRENT_YEAR}>this year</option>
          </select>
          <span className="dashboard-select-icon fill-black">
            <SvgExpandMore />
          </span>
        </div>

        <div className="d-flex">
          <select className="dashboard-select text-black" onChange={handleChangeReason}>
            <option value={NAnalytics.LostOrdersReason.CANCELED}>canceled</option>
            <option value={NAnalytics.LostOrdersReason.REJECTED}>rejected</option>
            <option value={NAnalytics.LostOrdersReason.EXPIRED}>expired</option>
          </select>
          <span className="dashboard-select-icon fill-black">
            <SvgExpandMore />
          </span>
        </div>

        <div className="dashboard-bar-chart">
          <canvas ref={cnvRef} />
        </div>
      </CardBody>
    </Card>
  );
});

const getLabel = (reason: NAnalytics.LostOrdersReason) => {
  switch (reason) {
    case NAnalytics.LostOrdersReason.CANCELED:
      return 'Canceled';
    case NAnalytics.LostOrdersReason.REJECTED:
      return 'Rejected';
    case NAnalytics.LostOrdersReason.EXPIRED:
      return 'Expired';
  }
};

const getColors = (reason: NAnalytics.LostOrdersReason) => {
  switch (reason) {
    case NAnalytics.LostOrdersReason.CANCELED:
      return {
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgb(255, 99, 132)'],
      };
    case NAnalytics.LostOrdersReason.REJECTED:
      return {
        backgroundColor: ['rgba(255, 159, 64, 0.2)'],
        borderColor: ['rgb(255, 159, 64)'],
      };
    case NAnalytics.LostOrdersReason.EXPIRED:
      return {
        backgroundColor: ['rgba(153, 102, 255, 0.2)'],
        borderColor: ['rgb(153, 102, 255)'],
      };
  }
};

const sortByMonth = (
  arr: NAnalytics.Dynamic.GetForLossesByReasonPeriod.Output['analyticsDynamicForOrderLossesByReasonPeriod']['dynamicLossesByPeriods'],
) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  arr.sort((a, b) => months.indexOf(a.period) - months.indexOf(b.period));
};
