import { gql } from '@apollo/client';

export const UPDATE_SETTINGS = gql`
  subscription {
    settings {
      update {
        breakTime
        defaultParcelTypeId
        pauseTimeBeforeBreak
        urgentAmount
        orderAmountPercent
        additionalPaymentFee
        stripeMinPaymentAmount
        vehiclesMinRadiusInM
        vehiclesMidRadiusInM
        vehiclesMaxRadiusInM
        timeForOrderAcceptanceInMs
        pickUpTypeLaterExpirationTimeAnotherDayInMinutes
        pickUpTypeLaterExpirationTimeSameDayInMinutes
        pickUpTypeNowExpirationTimeInMinutes
        pickUpTypeUrgentExpirationTimeInMinutes
        analyticsStartDate
      }
    }
  }
`;
