import { useMemo } from 'react';
import { GET_ALL_BARCODES } from '../barcodes';
import { NBarcode } from '../../types';
import { useAppQuery } from '../../apollo';

export const useBarcodes = () => {
  const { data, loading } = useAppQuery<NBarcode.GetAll.Output>(GET_ALL_BARCODES);

  const barcodes = useMemo(() => data?.getAllBarcodes ?? [], [data?.getAllBarcodes]);

  return { barcodes, loading };
};
