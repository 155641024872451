import { gql } from '@apollo/client';

export const GET_REVIEW_TAG = gql`
  query getReviewTag($input: GetReviewTagInput!) {
    reviewTag(input: $input) {
      id
      name
    }
  }
`;
