import { useEffect, useRef } from 'react';
import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { LazyQueryExecFunction, QueryResult } from '@apollo/client/react/types/types';
import { useNetwork } from '../context/Network';
import { ApolloError } from '@apollo/client/errors';

export interface AppLazyQueryOptions<TData = any, TVariables = OperationVariables>
  extends LazyQueryHookOptions<TData, TVariables> {
  testID?: string;
  shouldTriggerEventsAfterRefetching?: boolean;
}

export const useAppLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: AppLazyQueryOptions<TData, TVariables>,
): [
  LazyQueryExecFunction<TData, TVariables>,
  QueryResult<TData, TVariables> & { __skip: boolean },
] => {
  const {
    isConnected,
    pageIsHidden,
    accessTokenIsExpired,
    subscriptionsAreConnected,
    isRefetching,
  } = useNetwork();

  const skip = !(
    isConnected &&
    !pageIsHidden &&
    !accessTokenIsExpired &&
    subscriptionsAreConnected &&
    !isRefetching
  );

  const wasCalledRef = useRef(false);
  const onCompletedResultRef = useRef<TData | null>(null);
  const onErrorResultRef = useRef<ApolloError | null>(null);

  useEffect(() => {
    if (!skip) {
      wasCalledRef.current = false;

      if (onCompletedResultRef.current) {
        options?.onCompleted && options.onCompleted(onCompletedResultRef.current);
        onCompletedResultRef.current = null;
      }
      if (onErrorResultRef.current) {
        options?.onError && options.onError(onErrorResultRef.current);
        onErrorResultRef.current = null;
      }
    }
  }, [skip, options]);

  const result = useLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
    ...options,
    onCompleted(result) {
      if (skip) {
        if (wasCalledRef.current || options?.shouldTriggerEventsAfterRefetching) {
          onCompletedResultRef.current = result;
        }
      } else {
        wasCalledRef.current = false;
        options?.onCompleted && options.onCompleted(result);
      }
    },
    onError(result) {
      if (skip) {
        if (wasCalledRef.current || options?.shouldTriggerEventsAfterRefetching) {
          onErrorResultRef.current = result;
        }
      } else {
        wasCalledRef.current = false;
        options?.onError && options.onError(result);
      }
    },
  });

  return [
    (data) => {
      wasCalledRef.current = true;
      return result[0](data);
    },
    {
      ...result[1],
      loading: result[1].loading || isRefetching,
      __skip: skip,
    },
  ];
};
