import { NChat } from '../types';

export const findUnreadMessages = (
  messages: NChat.Message.Entity[],
  profileId: string | null | undefined,
): NChat.Message.Entity[] => {
  if (!profileId) return [];

  return messages.filter(
    ({ user, readersSeen }) =>
      user.id !== profileId &&
      readersSeen.find(({ userId, hasSeen }) => userId === profileId && !hasSeen),
  );
};
