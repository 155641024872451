import { useMemo } from 'react';
import { GET_ALL_USERS } from '../users';
import { NUser } from '../../types';
import { useAppQuery } from '../../apollo';

export const useUsers = () => {
  const { data, loading } = useAppQuery<NUser.GetAll.Output>(GET_ALL_USERS);

  const users = useMemo(() => data?.getAllUsers ?? [], [data?.getAllUsers]);

  return { users, loading };
};
