import { useRef } from 'react';
import { useSubscription } from '@apollo/client';
import { MESSAGE_ADDED_SUBSCRIPTION } from '../../../subscriptions';
import { NChat } from '../../../types';
import { GET_CHATS } from '../../../queries';
import { ProtectedImage } from '../../../components/ProtectedImage';
import { toast } from 'react-toastify';
import { ERoutes } from '../../../constants/route.constant';
import { ESearchQuery } from '../../../constants/query.constant';
import { useHistory } from 'react-router';
import { useSearchQuery } from '../../../hooks';

export const useMessageCreatedSubscription = (tokenIsValid: boolean) => {
  const { push } = useHistory();

  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);
  const chatIdRef = useRef(chatId);
  chatIdRef.current = chatId;

  useSubscription<NChat.Message.On.Added.Output>(MESSAGE_ADDED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { messageAdded } = data;

      let { chat, ...message } = messageAdded;

      /* Add a new created message to the chat */
      const addMessageToChat = (type: NChat.Type, next?: () => void) => () => {
        let found = false;

        client.cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
          {
            query: GET_CHATS,
            variables: {
              type,
            },
          },
          (record) => {
            if (!record) return;

            return {
              chats: record.chats.map((x) => {
                if (x.id !== chat.id) return x;

                found = true;

                return {
                  ...x,
                  messages: [...x.messages, message],
                };
              }),
            };
          },
        );

        if (!found && next) next();
      };

      addMessageToChat(NChat.Type.ORDER_SUPPORT, addMessageToChat(NChat.Type.EMPLOYEE))();

      const shouldAddToUnread = chatIdRef.current !== messageAdded.chat.id;

      if (!shouldAddToUnread) {
        return;
      }

      const notification = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ProtectedImage
            id={message.user.photoFileId}
            style={{ width: 40, height: 40, borderRadius: '20px', marginRight: 10 }}
          />
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <div style={{ fontWeight: 'bold' }}>
              {message.user.firstName} {message.user.lastName}
            </div>
            <div>{message.text}</div>
          </div>
        </div>
      );

      const types: { [key in NChat.Type]: string } = {
        [NChat.Type.ORDER_DRIVER]: NChat.Tab.ORDER_DRIVER,
        [NChat.Type.ORDER_SUPPORT]: NChat.Tab.ORDER_SUPPORT,
        [NChat.Type.EMPLOYEE]: NChat.Tab.EMPLOYEE,
      };

      if (chat.type === NChat.Type.ORDER_SUPPORT) {
        toast(notification, {
          type: 'default',
          autoClose: 10000,
          onClick() {
            push(
              `${ERoutes.CHATS}?${ESearchQuery.CHAT_TAB}=${types[chat.type]}&${
                ESearchQuery.CHAT_ID
              }=${chat.id}&${ESearchQuery.CHAT_TYPE}=${chat.type}`,
            );
          },
          hideProgressBar: true,
        });
      }

      /* Add a chat to the chat list, if it does not already exist */
      // client.cache.updateQuery<NChat.GetMany.Output, NChat.GetMany.Input>(
      //   { query: GET_CHATS, variables: { type: chat.type } },
      //   (record) => {
      //     if (!record) return;
      //
      //     const chatExists = record.chats.find(({ id }) => id === chat.id);
      //
      //     if (!chatExists) {
      //       return { chats: [chat, ...record.chats] };
      //     }
      //     return record;
      //   },
      // );
    },
  });
};
