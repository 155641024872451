import React, { memo } from 'react';
import classNames from 'classnames';
import { TabHeader, Tabs } from './components';
import { useSearchQuery } from '../../../../../hooks';
import { ESearchQuery } from '../../../../../constants/query.constant';

export const LeftSidebar = memo(() => {
  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);

  return (
    <div
      className={classNames('chats-page__left-sidebar', {
        'chats-page__left-sidebar_can-be-hidden': !!chatId,
      })}
      style={{ position: 'relative' }}
    >
      <TabHeader />
      <Tabs />
    </div>
  );
});
