import React, { memo } from 'react';

interface NoDataProps {
  title: string;
}

export const NoData = memo<NoDataProps>(({ title }) => (
  <div
    style={{ padding: `24px` }}
    className="d-flex justify-content-center align-items-center"
  >
    {title}
  </div>
));
