import { gql } from '@apollo/client';

export const CREATE_BARCODES = gql`
  mutation createBarcodes($input: CreateBarcodesInput!) {
    createBarcodes(input: $input) {
      id
      isUsed
      uniqueCode
    }
  }
`;
