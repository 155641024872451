import { useEffect, useRef } from 'react';
import { useSetSetter, useSetGetter, useUnmountCallback } from '../index';

const useConnector = (
  name: string,
  value: any,
  setValue: (value: string) => void,
  defaultValue?: any
) => {
  const setSetter = useSetSetter();
  const setGetter = useSetGetter();
  const unmountCallback = useUnmountCallback();
  const setterRef = useRef((_: string) => {});
  const getterRef = useRef(() => {});

  useEffect(() => {
    getterRef.current = () => value;
  }, [value]);

  useEffect(() => {
    setterRef.current = setValue;
  }, [setValue]);

  useEffect(() => {
    if (name) {
      setSetter(name, (v) => setterRef.current(v), defaultValue);
      setGetter(name, () => getterRef.current());
    }
  }, [name, setSetter, setGetter, defaultValue]);

  useEffect(() => () => unmountCallback(name), [name, unmountCallback]);
};

export default useConnector;
