import { gql } from '@apollo/client';

export const GET_BARCODE = gql`
  query getBarcode($input: GetBarcodeInput!) {
    getBarcode(input: $input) {
      id
      isUsed
      uniqueCode
    }
  }
`;
