import { useMemo } from 'react';
import { NQuestionAnswer } from '../../types';
import { GET_ALL_QUESTIONS_ANSWERS } from '../questions-answers';
import { useAppQuery } from '../../apollo';

export const useQuestionsAnswers = () => {
  const { data, loading } = useAppQuery<NQuestionAnswer.GetAll.Output>(
    GET_ALL_QUESTIONS_ANSWERS,
  );

  const questionsAnswers = useMemo(
    () => data?.questionsAnswers ?? [],
    [data?.questionsAnswers],
  );

  return { questionsAnswers, loading };
};
