import { gql } from '@apollo/client';

export const ATTACH_USER = gql`
  mutation attachUser($input: AttachUserInput!) {
    attachUser(input: $input) {
      firstName
      lastName
    }
  }
`;
