import { gql } from '@apollo/client';

export const GET_ANALYTICS_DYNAMIC_FOR_LOSSES_BY_REASON_PERIOD = gql`
  query analyticsDynamicForOrderLossesByReasonPeriod(
    $input: GetDynamicForOrderLossesByReasonPeriodInput!
  ) {
    analyticsDynamicForOrderLossesByReasonPeriod(input: $input) {
      dynamicLossesByPeriods {
        period
        lossesByReason
      }
    }
  }
`;
