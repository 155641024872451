import { gql } from '@apollo/client';

export const CREATE_PARCEL_TYPE_SUBSCRIPTION = gql`
  subscription {
    parcelTypes {
      create {
        id
        title
        designation
        description
        height
        length
        width
        maxWeightInGrams
      }
    }
  }
`;
