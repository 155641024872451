import { useContext } from 'react';
import { NetworkContext } from '../NetworkContext';

export const useNetwork = () => {
  const context = useContext(NetworkContext);

  if (context === null) {
    throw new Error('useNetwork must be within a NetworkProvider');
  }

  return context;
};
