import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateVehicleTypeSidebar } from './CreateVehicle';
import { UpdateVehicleTypeSidebar } from './UpdateVehicle';

export const VehicleTypeSidebars = memo(() => (
  <>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <CreateVehicleTypeSidebar />
    </FProvider>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <UpdateVehicleTypeSidebar />
    </FProvider>
  </>
));
