import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { useParcelTypes } from '../../../queries';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { NParcelType, NUser } from '../../../types';
import { ESearchQuery } from '../../../constants/query.constant';
import { DeleteButton } from './components';
import { useAuth } from '../../../context/Auth';

export const RenderParcelTypesPage = memo(() => {
  const { profile } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (profile?.userRole !== NUser.Role.EMPLOYEE_ADMIN) {
      history.push('/');
    }
  }, [history, profile?.userRole]);

  const { parcelTypes } = useParcelTypes();

  const tableData = parcelTypes;

  const onCreateNew = useCallback(() => {
    history.push({
      search: `${ESidebarPath.CREATE_PARCEL_TYPE}`,
    });
  }, [history]);

  const onRowClick = useCallback(
    (e, row: NParcelType.Entity) => {
      if (e.target) {
        let currentNode: HTMLDivElement = e.target;

        if (currentNode.nodeName !== 'TD') {
          while (currentNode?.parentNode && currentNode.parentNode.nodeName !== 'TD') {
            currentNode = currentNode.parentNode as HTMLDivElement;
          }
          if (currentNode.dataset.hasOwnProperty('unclickable')) {
            return;
          }
        }
        history.push({
          search: `${ESearchQuery.PARCEL_TYPE_ID}=${row.id}&${ESidebarPath.UPDATE_PARCEL_TYPE}`,
        });
      }
    },
    [history],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'id',
        order: 'desc',
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={onCreateNew}
                              >
                                <i className="mdi mdi-plus me-1" />
                                New parcel type
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

const columns: ColumnDescription[] = [
  {
    dataField: 'title',
    text: 'Title',
    sort: true,
  },
  {
    dataField: 'designation',
    text: 'Designation',
    sort: true,
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true,
  },
  {
    dataField: 'width',
    text: 'Width',
    sort: true,
  },
  {
    dataField: 'height',
    text: 'Height',
    sort: true,
  },
  {
    dataField: 'length',
    text: 'Length',
    sort: true,
  },
  {
    dataField: 'maxWeightInGrams',
    text: 'Max weight (grams)',
    sort: true,
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: 'Action',
    headerStyle: {
      width: '50px',
    },
    formatter: (cellContent: any, row: NParcelType.Entity) => (
      <div className="d-flex" style={{ height: '36px' }} data-unclickable="">
        <DeleteButton id={row.id} />
      </div>
    ),
  },
];
