import { NVehicleFuelType } from './vehicle-fuel-types';

export namespace NVehicleType {
  export interface Entity {
    id: string;
    fuelType: NVehicleFuelType.Entity;
    photoFileId: string | null;
    titleDe: string;
    titleEn: string;
    shortDescriptionDe: string;
    shortDescriptionEn: string;
    longDescriptionDe: string;
    longDescriptionEn: string;
    journeyInc5MinPrice: number;
    over2kmPerKmPrice: number;
    pickUpOver5MinPrice: number;
    pickUpOverTimeInSeconds: number;
    stopoverPrice: number;
    cancelPrice: number;
    outsideServiceHoursPercent: number;
    minCoastInc2kmPrice: number;
    bootCapacityLiters: number;
    orderLimit: number;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { vehicleType: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { vehicleTypes: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createVehicleType: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Entity };
    export type Output = { updateVehicleType: Entity };
  }

  /* Mutation */
  export namespace Remove {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { remoteVehicleType: Pick<Entity, 'id'> };
  }

  /* Subscriptions */
  export namespace On {
    export namespace Added {
      export type Output = { vehicleTypeAdded: Entity };
    }

    export namespace Updated {
      export type Output = { vehicleTypeUpdated: Entity };
    }

    export namespace Removed {
      export type Output = { vehicleTypeRemoved: Pick<Entity, 'id'> };
    }
  }
}
