import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderVehicleTypesPage } from './RenderVehicleTypesPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const VehicleTypesPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Vehicle Types')}</title>
    </Helmet>

    <UIBreadcrumbs title="Vehicle Types" />

    <RenderVehicleTypesPage />
  </>
));
