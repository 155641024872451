import { useContext } from 'react';
import { LayoutContext } from '../LayoutProvider';

export const useLayout = () => {
  const context = useContext(LayoutContext);

  if (context === null) {
    throw new Error('useLayout must be inside a LayoutProvider');
  }

  return context;
};
