export enum ERoutes {
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  MAP = '/map',
  ORDERS = '/orders',
  BARCODES = '/barcodes',
  USERS = '/users',
  VEHICLES = '/vehicles',
  ASSIGNED_VEHICLES = '/assigned_vehicles',
  VEHICLE_TYPES = '/vehicle-types',
  PARCEL_TYPES = '/parcel-types',
  SETTINGS = '/settings',
  CHATS = '/chats',
  REVIEW_TAGS = '/review-tags',
  REVIEWS = '/reviews',
  FAQ = '/faq',
  ERROR_404 = '/404',
}
