import React, { memo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useAttachUserVehicles } from '../../../../../../queries';
import {
  UIBreadcrumbs,
  UIContainer,
  UIContainerOfItems,
  UIHSeparator,
  UIUser,
} from '../../../../../../components/ui';
import { UIVehicle } from '../../../../../../components/ui/UIVehicle';
import { ATTACH_ORDER } from '../../../../../../mutations';
import { NOrder } from '../../../../../../types';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderAttachOrderSidebar = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const orderId = useSearchParam(searchQuery, ESearchQuery.ORDER_ID);
  const userVehicleId = useSearchParam(searchQuery, ESearchQuery.USER_VEHICLE_ID);

  searchQuery.delete(ESearchQuery.USER_VEHICLE_ROUTE);

  const {
    attachUserVehicles,
    loading: isFetching,
    error,
  } = useAttachUserVehicles({ orderId });

  const [attachOrder, { loading }] = useMutation<
    NOrder.Attach.Output,
    NOrder.Attach.Input
  >(ATTACH_ORDER, {
    onCompleted() {
      toast('Order was successfully assigned!', {
        type: 'success',
      });
      history.push(history.location.pathname);
    },
    onError(err) {
      toast(`Error (when assigning an order): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleAttach = useCallback(() => {
    if (orderId && userVehicleId) {
      void attachOrder({
        variables: {
          input: {
            orderId,
            userVehicleLinkId: userVehicleId,
          },
        },
      });
    }
  }, [attachOrder, orderId, userVehicleId]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!attachUserVehicles) {
    return <NoData title={`No drivers for assignment: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="available drivers" />

      <UIContainer style={{ padding: '0 12px 24px 12px' }}>
        {attachUserVehicles.map((userVehicle) => {
          searchQuery.set(ESearchQuery.USER_VEHICLE_ID, userVehicle.userVehicleLinkId);
          searchQuery.set(ESearchQuery.USER_VEHICLE_ROUTE, userVehicle.userVehicleLinkId);

          return (
            <Link
              key={userVehicle.userVehicleLinkId}
              to={{ search: searchQuery.toString() }}
            >
              <UIContainerOfItems
                selected={userVehicleId === userVehicle.userVehicleLinkId}
              >
                <UIUser
                  firstName={userVehicle.user?.firstName ?? ''}
                  lastName={userVehicle.user?.lastName ?? ''}
                  photoFileId={null}
                  phoneNumber={''}
                />
                <UIVehicle
                  title={userVehicle.vehicle?.title ?? ''}
                  vehicleType={userVehicle.vehicleType}
                />
              </UIContainerOfItems>
            </Link>
          );
        })}

        {!!attachUserVehicles.length && userVehicleId && (
          <>
            <UIHSeparator />
            <div style={{ padding: '0', marginTop: 12 }}>
              <Button color="primary" className="w-100" onClick={handleAttach}>
                {loading ? 'Loading...' : 'Assign'}
              </Button>
            </div>
          </>
        )}
      </UIContainer>
    </>
  );
});
