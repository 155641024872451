export default (values) => {
  const obj = {};

  // TODO: do it with recursion
  Object.entries(values).forEach(([key, value]) => {
    if (value !== null) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        Object.entries(value).forEach(([k, v]) => {
          obj[`${key}.${k}`] = v;
        });
      } else {
        obj[key] = value;
      }
    } else {
      obj[key] = value;
    }
  });

  return obj;
};
