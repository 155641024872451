import React, { memo, useMemo } from 'react';
import { VehicleRoute } from './VehicleRoute';
import { useSearchQuery } from '../../../../hooks';
import { ESearchQuery } from '../../../../constants/query.constant';

export const VehicleRoutes = memo(() => {
  const searchQuery = useSearchQuery();
  const userVehicleIds = useMemo(
    () => searchQuery.getAll(ESearchQuery.USER_VEHICLE_ID),
    [searchQuery],
  );

  return (
    <>
      {userVehicleIds.map((id: string) => (
        <VehicleRoute key={id} userVehicleId={id} />
      ))}
    </>
  );
});
