import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { pageTitle } from '../../../utilities/pageTitle';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderDashboardPage } from './RenderDashboardPage';

export const DashboardPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Dashboard')}</title>
    </Helmet>

    <UIBreadcrumbs title="Dashboard" />

    <RenderDashboardPage />
  </>
));
