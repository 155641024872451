import { gql } from '@apollo/client';

export const MESSAGE_ADDED_SUBSCRIPTION = gql`
  subscription {
    messageAdded {
      id
      text
      createdAt
      user {
        id
        firstName
        lastName
        photoFileId
        userRole
      }
      readersSeen {
        userId
        hasSeen
      }
      chat {
        id
        type
      }
    }
  }
`;
