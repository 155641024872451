import React, { memo } from 'react';
import { FInput } from '../../../../../../libs/FForm/components';

interface ReviewTagFieldsProps {
  loading?: boolean;
}

export const ReviewTagFields = memo<ReviewTagFieldsProps>(({ loading }) => (
  <>
    <FInput label="Name" name="name" placeholder="Name" required disabled={loading} />
  </>
));
