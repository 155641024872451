import React, { createContext, memo, useRef } from 'react';

type DirectionsResult = google.maps.DirectionsResult;

export const DirectionsContext = createContext<Record<string, DirectionsResult> | null>(
  null,
);

export const DirectionsProvider = memo(({ children }) => {
  const cache = useRef<Record<string, DirectionsResult>>({});

  return (
    <DirectionsContext.Provider value={cache.current}>
      {children}
    </DirectionsContext.Provider>
  );
});
