import { gql } from '@apollo/client';

export const CREATE_PARCEL_TYPE = gql`
  mutation createParcelType($input: CreateParcelTypeInput!) {
    createParcelType(input: $input) {
      id
      title
      designation
      description
      height
      length
      width
      maxWeightInGrams
    }
  }
`;
