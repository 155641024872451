import { useSubscription } from '@apollo/client';
import { CREATE_BARCODES_SUBSCRIPTION } from '../../../subscriptions';
import { NBarcode } from '../../../types';
import { GET_ALL_BARCODES } from '../../../queries';

export const useBarcodesAddedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NBarcode.On.CreateMany.Output>(CREATE_BARCODES_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { create } = data.barcodes;

      if (!create) {
        return;
      }

      client.cache.updateQuery<NBarcode.GetAll.Output>(
        { query: GET_ALL_BARCODES },
        (record) => {
          if (!record) return;

          return {
            getAllBarcodes: [...create, ...record.getAllBarcodes],
          };
        },
      );
    },
  });
};
