import React, { memo, useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { DELETE_PARCEL_TYPE } from '../../../../mutations';
import { GET_ALL_PARCEL_TYPES } from '../../../../queries';
import { NParcelType } from '../../../../types';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);
  const client = useApolloClient();

  const [deleteParcelType, { loading }] = useMutation<
    NParcelType.Delete.Output,
    NParcelType.Delete.Input
  >(DELETE_PARCEL_TYPE, {
    onCompleted() {
      toast('Parcel type was successfully deleted!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when deleting a parcel type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(async () => {
    if (!unlock) {
      setUnlock(true);
    } else {
      const response = await deleteParcelType({ variables: { input: { id } } });

      if (!response.data?.deleteParcelType) {
        return;
      }

      client.cache.updateQuery<NParcelType.GetAll.Output>(
        { query: GET_ALL_PARCEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            getAllParcelTypes: record.getAllParcelTypes.filter(
              (parcelType) => parcelType.id !== id,
            ),
          };
        },
      );
    }
  }, [deleteParcelType, client, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
