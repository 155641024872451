import { gql } from '@apollo/client';

export const UPDATE_PARCEL_TYPE_SUBSCRIPTION = gql`
  subscription {
    parcelTypes {
      update {
        id
        title
        designation
        description
        height
        length
        width
        maxWeightInGrams
      }
    }
  }
`;
