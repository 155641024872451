import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { NReviewTag } from '../../../../types';
import { GET_ALL_REVIEW_TAGS } from '../../../../queries';
import { DELETE_REVIEW_TAG } from '../../../../mutations';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);

  const [deleteReviewTag, { loading }] = useMutation<
    NReviewTag.Delete.Output,
    NReviewTag.Delete.Input
  >(DELETE_REVIEW_TAG, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NReviewTag.GetAll.Output>(
        { query: GET_ALL_REVIEW_TAGS },
        (record) => {
          if (!record) return;

          return {
            reviewTags: record.reviewTags.filter((reviewTag) => reviewTag.id !== id),
          };
        },
      );
    },
    onCompleted() {
      toast('Review tag was successfully deleted!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when deleting a review tag): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(() => {
    if (!unlock) {
      setUnlock(true);
    } else {
      void deleteReviewTag({ variables: { input: { id } } });
    }
  }, [deleteReviewTag, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center ms-1"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
