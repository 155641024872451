import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateQuestionAnswerSidebar } from './RenderCreateQuestionAnswerSidebar';

export const CreateQuestionAnswerSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_QUESTION_ANSWER} onClose={handleClear}>
      <RenderCreateQuestionAnswerSidebar />
    </Sidebar>
  );
});
