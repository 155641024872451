import { useMemo } from 'react';
import { GET_CHAT } from '../chats';
import { NChat } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NChat.GetOne.Output, NChat.GetOne.Input> {
  chatId: string | null | undefined;
}

export const useChat = ({ chatId, ...options }: Params) => {
  const { data, loading } = useAppQuery<NChat.GetOne.Output, NChat.GetOne.Input>(
    GET_CHAT,
    {
      skip: !chatId,
      variables: {
        input: {
          id: chatId ?? '',
        },
      },
      ...options,
    },
  );

  const chat = useMemo(() => data?.chat ?? null, [data?.chat]);

  return { chat, loading };
};
