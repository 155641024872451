import React, { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSearchQuery } from '../../../../hooks';
import { ESearchQuery } from '../../../../constants/query.constant';
import { SvgBack } from '../../../../assets/icons';
import classNames from 'classnames';

interface RightSidebarProps {
  name: string;
  onClose?: () => void;
  onCloseStart?: () => void;
  width?: number;
}

export const Sidebar = memo<PropsWithChildren<RightSidebarProps>>(
  ({ children, name, onClose, onCloseStart, width = 360 }) => {
    const { goBack } = useHistory();

    const sidebar = useSearchQuery().get(ESearchQuery.SIDEBAR);

    /* Determines whether to open the sidebar */
    const isOpen = sidebar === name;

    /* Determines whether there is a sidebar on the page */
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setIsVisible(true);
      }
    }, [isOpen]);

    /* When the animation finishes, the sidebar is removed from the page */
    const handleAnimationEnd = useCallback(() => {
      if (!isOpen) {
        setIsVisible(false);

        if (onClose) {
          onClose();
        }
      }
    }, [isOpen, onClose]);

    if (isVisible) {
      return (
        <div
          style={{
            zIndex: isOpen ? 10004 : 10003,
            ['--sidebar-width' as string]: `${width}px`,
          }}
          className={classNames('sidebar', {
            [`animation_slide-right-in`]: isOpen,
            [`animation_slide-right-out`]: !isOpen,
          })}
          onAnimationStart={() => !isOpen && onCloseStart && onCloseStart()}
          onAnimationEnd={handleAnimationEnd}
        >
          <div className="sidebar__header">
            <div className="btn_back" onClick={goBack}>
              <div className="btn_back__icon">
                <SvgBack />
              </div>
              <span className="btn_back__title">Back</span>
            </div>
          </div>
          {children}
        </div>
      );
    }

    return null;
  },
);
