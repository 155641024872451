import { gql } from '@apollo/client';

export const GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_EXPECTED_REVENUE = gql`
  query analyticsStaticForLostOrdersExpectedRevenue($input: GetStaticForTotalsInput!) {
    analyticsStaticForLostOrdersExpectedRevenue(input: $input) {
      currentPeriodExpectedRevenue
      percentExpectedRevenuePreviousToCurrent
    }
  }
`;
