import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateVehicleTypeSidebar } from './RenderCreateVehicleTypeSidebar';

export const CreateVehicleTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_VEHICLE_TYPE} onClose={handleClear} width={450}>
      <RenderCreateVehicleTypeSidebar />
    </Sidebar>
  );
});
