import React, { memo } from 'react';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import {
  UIBreadcrumbs,
  UIContainer,
  UIHSeparator,
  UIOrder,
  UIUser,
} from '../../../../../../components/ui';
import { SvgDislike, SvgLike, SvgStar } from '../../../../../../assets/icons';
import { useReview } from '../../../../../../queries';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderReviewSidebar = memo(() => {
  const searchQuery = useSearchQuery();
  const reviewId = useSearchParam(searchQuery, ESearchQuery.REVIEW_ID);

  const { review, loading, error } = useReview({
    id: reviewId,
  });

  if (loading) {
    return <DataIsFetching />;
  }

  if (!review) {
    return <NoData title={`No review data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="Review" />

      <UIContainer style={{ padding: '0 12px 24px 12px' }}>
        <UIHSeparator title="order" />

        <UIOrder {...review.order} />

        <UIHSeparator title="customer" />

        <UIUser {...review.customer} />

        {review.driver && (
          <>
            <UIHSeparator title="driver" />
            <UIUser {...review.driver} />
          </>
        )}

        <UIHSeparator />

        <div className="field d-flex align-items-center" style={{ marginLeft: '12px' }}>
          <span className="field__title">Safety:</span>
          <span className="field__value">{review.safety}</span>
          <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
            <SvgStar fill={review.safety > 2 ? '#FFC84E' : '#f46a6a'} />
          </div>
        </div>

        {review.rating && (
          <>
            <UIHSeparator />

            <div
              className="field d-flex align-items-center"
              style={{ marginLeft: '12px' }}
            >
              <span className="field__title">Driver Rating:</span>
              <span className="field__value">{review.rating.score}</span>
              <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
                <SvgStar fill={review.rating.score > 2 ? '#FFC84E' : '#f46a6a'} />
              </div>
            </div>
          </>
        )}

        <UIHSeparator />

        <div className="field d-flex align-items-center" style={{ marginLeft: '12px' }}>
          <span className="field__title">Service:</span>
          <span className="field__value">{review.service}</span>
          <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
            <SvgStar fill={review.service > 2 ? '#FFC84E' : '#f46a6a'} />
          </div>
        </div>

        <UIHSeparator />

        <div className="field d-flex" style={{ marginLeft: '12px' }}>
          <span className="field__title">Recommendation:</span>
          <div style={{ width: 20, height: 20 }}>
            {review.recommendation ? <SvgLike /> : <SvgDislike />}
          </div>
        </div>

        <UIHSeparator />

        <div className="field" style={{ marginLeft: '12px' }}>
          <span className="field__title">Praise Tags: </span>
          <span className="field__value">{review.reviewTags.join(', ')}</span>
        </div>

        <UIHSeparator />

        <div className="field" style={{ marginLeft: '12px' }}>
          <span className="field__title">Comment: </span>
          <span className="field__value">{review.comment}</span>
        </div>
      </UIContainer>
    </>
  );
});
