import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
  query messages($input: MessagesInput!, $skip: Float!, $take: Float!) {
    messages(input: $input, skip: $skip, take: $take) {
      id
      text
      createdAt
      user {
        id
        firstName
        lastName
        photoFileId
        userRole
      }
      readers {
        id
      }
    }
  }
`;
