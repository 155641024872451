import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, {
  ColumnDescription,
  SelectRowProps,
  SortOrder,
} from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { detect } from 'detect-browser';
import { useBarcodes } from '../../../queries';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { ESearchQuery } from '../../../constants/query.constant';
import { API_URL } from '../../../constants';
import { ELocalStorage } from '../../../constants/local-storage.constant';
import { NBarcode } from '../../../types';

const browser = detect();

function performPrint(iframeElement: HTMLIFrameElement) {
  try {
    iframeElement.focus();

    // If Edge or IE, try catch with execCommand
    if (browser?.name === 'edge' || browser?.name === 'ie') {
      try {
        iframeElement.contentWindow?.document.execCommand('print', false);
      } catch (e) {
        iframeElement.contentWindow?.print();
      }
    } else {
      setTimeout(() => {
        iframeElement.contentWindow?.print();
      }, 1000);
    }
  } catch (error) {
    console.error('An error occurred when starting the printing process:', error);
  } finally {
    if (browser?.name === 'firefox' || browser?.name === 'safari') {
      // Move the iframe element off-screen and make it invisible
      iframeElement.style.visibility = 'hidden';
      iframeElement.style.left = '-1px';
    }
  }
}

export const RenderBarcodesPage = memo(() => {
  const history = useHistory();
  const { barcodes } = useBarcodes();
  const tableData = barcodes;

  const [selectedBarcodeIds, setSelectedBarcodeIds] = useState<string[]>([]);
  const [isPrintingProcess, setIsPrintingProcess] = useState(false);

  /* Delete unnecessary selected barcodes, if they were used */
  useEffect(() => {
    const isSomeSelectedBarcodeIsUsed = selectedBarcodeIds.some(
      (id) => barcodes.find((barcode) => barcode.id === id)?.isUsed,
    );

    if (isSomeSelectedBarcodeIsUsed) {
      setSelectedBarcodeIds((prev) => {
        return prev.filter(
          (id) => !barcodes.find((barcode) => barcode.id === id)?.isUsed,
        );
      });
    }
  }, [barcodes, selectedBarcodeIds]);

  const onCreateNew = useCallback(() => {
    history.push({
      search: `${ESidebarPath.CREATE_BARCODE}`,
    });
  }, [history]);

  const onPrint = useCallback(async () => {
    if (!selectedBarcodeIds.length) return;

    try {
      setIsPrintingProcess(true);
      const accessToken = localStorage.getItem(ELocalStorage.ACCESS_TOKEN);
      const response = await fetch(`${API_URL}/barcodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ barcodeIds: selectedBarcodeIds }),
      });
      const blob = await response.blob();
      const blobURL = URL.createObjectURL(blob);

      const pdfFrame = document.body.appendChild(document.createElement('iframe'));
      pdfFrame.style.display = 'none';
      pdfFrame.onload = async () => {
        performPrint(pdfFrame);
        setIsPrintingProcess(false);
      };
      pdfFrame.src = blobURL;
    } catch (e) {
      setIsPrintingProcess(false);
    }
  }, [selectedBarcodeIds]);

  const onRowClick = useCallback(
    (_, row: NBarcode.Entity) => {
      history.push({
        search: `${ESearchQuery.BARCODE_ID}=${row.id}&${ESidebarPath.BARCODE}`,
      });
    },
    [history],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'isUsed',
        order: 'asc',
      },
    ],
    [],
  );

  const selectRow = useMemo<SelectRowProps<NBarcode.Entity>>(
    () => ({
      mode: 'checkbox',
      selected: selectedBarcodeIds,
      // clickToSelect: true,
      onSelect(row, isSelected) {
        if (row.isUsed) return !isSelected;

        if (isSelected) {
          setSelectedBarcodeIds((prev) => [...prev, row.id]);
        } else {
          setSelectedBarcodeIds((prev) => prev.filter((id) => id !== row.id));
        }

        return true;
      },
      onSelectAll(isSelect, rows) {
        if (isSelect) {
          const ids = rows.filter(({ isUsed }) => !isUsed).map(({ id }) => id);
          setSelectedBarcodeIds(ids);
          return ids as never as number[];
        } else {
          setSelectedBarcodeIds([]);
          return [];
        }
      },
    }),
    [selectedBarcodeIds],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2 me-2 d-inline-flex align-items-center justify-content-center"
                                onClick={onPrint}
                              >
                                {isPrintingProcess ? (
                                  <span className="spinner-border spinner-border-sm" />
                                ) : (
                                  <>
                                    <i className="mdi mdi-printer me-1" />
                                    Print
                                  </>
                                )}
                              </Button>
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={onCreateNew}
                              >
                                <i className="mdi mdi-plus me-1" />
                                New barcodes
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                selectRow={selectRow}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

const columns: ColumnDescription[] = [
  {
    dataField: 'isUsed',
    text: 'Usage status',
    sort: true,
    formatter(_, row: NBarcode.Entity) {
      if (row.isUsed) {
        return <span className="badge badge-soft-danger">Used</span>;
      }
      return <span className="badge badge-soft-success">Not used</span>;
    },
  },
  {
    dataField: 'uniqueCode',
    text: 'Code',
    sort: true,
  },
];
