import { useSubscription } from '@apollo/client';
import { UPDATE_BARCODE_SUBSCRIPTION } from '../../../subscriptions';
import { NBarcode } from '../../../types';
import { GET_ALL_BARCODES } from '../../../queries';

export const useBarcodeUpdatedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NBarcode.On.Update.Output>(UPDATE_BARCODE_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { update } = data.barcodes;

      if (!update) {
        return;
      }

      client.cache.updateQuery<NBarcode.GetAll.Output>(
        { query: GET_ALL_BARCODES },
        (record) => {
          if (!record) return;

          return {
            getAllBarcodes: record.getAllBarcodes.map((barcode) => {
              if (barcode.id === update.id) {
                return update;
              }
              return barcode;
            }),
          };
        },
      );
    },
  });
};
