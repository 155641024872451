import React, { memo, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useBarcode } from '../../../../../../queries';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderBarcodeSidebar = memo(() => {
  const searchQuery = useSearchQuery();
  const barcodeId = useSearchParam(searchQuery, ESearchQuery.BARCODE_ID);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { barcode, loading, error } = useBarcode({
    id: barcodeId,
  });

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    if (barcode) {
      JsBarcode(canvasRef.current, barcode.uniqueCode);
    }
  }, [barcode]);

  if (loading) {
    return <DataIsFetching />;
  }

  if (!barcode) {
    return <NoData title={`No barcode data: ${error?.message}`} />;
  }

  return (
    <div className="p-4">
      <canvas ref={canvasRef} style={{ width: '100%' }} />
    </div>
  );
});
