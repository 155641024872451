import React, { memo, PropsWithChildren } from 'react';
import { ProtectedImage } from '../ProtectedImage';
import { NUser } from '../../types';
import { SvgArrowRight } from '../../assets/icons';
import { ESearchQuery } from '../../constants/query.constant';
import { ESidebarPath } from '../../constants/sidebar.constant';
import { NavLink } from 'react-router-dom';

export type UIUserProps = Pick<
  NUser.Entity,
  'firstName' | 'lastName' | 'phoneNumber' | 'photoFileId'
> &
  Partial<Pick<NUser.Entity, 'id'>>;

export const UIUser = memo<PropsWithChildren<UIUserProps>>(
  ({ children, id, firstName, lastName, phoneNumber, photoFileId }) => {
    const search = `${ESearchQuery.USER_ID}=${id}&${ESidebarPath.USER}`;

    if (id) {
      return (
        <NavLink to={{ search }}>
          <div className="item">
            <div className="item__icon user__avatar">
              <ProtectedImage
                id={photoFileId}
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="item__details">
              <div className="item__title">
                {firstName} {lastName}
              </div>
              <div className="item__subtitle">{phoneNumber}</div>
            </div>

            {children}

            <div className="item__button_more">
              <SvgArrowRight />
            </div>
          </div>
        </NavLink>
      );
    }

    return (
      <div className="item">
        <div className="item__icon user__avatar">
          <ProtectedImage id={photoFileId} style={{ width: '100%', height: '100%' }} />
        </div>

        <div className="item__details">
          <div className="item__title">
            {firstName} {lastName}
          </div>
          <div className="item__subtitle">{phoneNumber}</div>
        </div>
      </div>
    );
  },
);
