import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { NReviewTag } from '../../../../../../types';
import { ReviewTagFields } from '../components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { CREATE_REVIEW_TAG } from '../../../../../../mutations';
import { GET_ALL_REVIEW_TAGS } from '../../../../../../queries';

export const RenderCreateReviewTagSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();

  const [createReviewTag, { loading }] = useMutation<
    NReviewTag.Create.Output,
    NReviewTag.Create.Input
  >(CREATE_REVIEW_TAG, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NReviewTag.GetAll.Output>(
        { query: GET_ALL_REVIEW_TAGS },
        (record) => {
          if (!record) return;

          return {
            reviewTags: [...record.reviewTags, data.createReviewTag],
          };
        },
      );
    },
    onCompleted() {
      toast('Review tag type was successfully created!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when creating a review tag): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NReviewTag.Create.Input['input']) => {
      void createReviewTag({
        variables: {
          input: values,
        },
      });
    },
    [createReviewTag],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new review tag" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <ReviewTagFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
