import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_STATIC_FOR_AVERAGE_REVENUE } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardCardAverageRevenue = memo(() => {
  const [unit, setUnit] = useState(NAnalytics.RevenuePerUnit.WEEK);

  const { data, loading } = useQuery<
    NAnalytics.Static.GetForAverageRevenue.Output,
    NAnalytics.Static.GetForAverageRevenue.Input
  >(GET_ANALYTICS_STATIC_FOR_AVERAGE_REVENUE, {
    variables: {
      input: {
        unit,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.target.value as NAnalytics.RevenuePerUnit);
  }, []);

  let value = data?.analyticsStaticForAverageRevenue.averageRevenue.toFixed(2);

  return (
    <Card className="dashboard-card bg_green">
      <CardBody>
        <CardTitle className="dashboard-card__title">Average Revenue</CardTitle>

        <div className="dashboard-card__content">
          {loading ? (
            <div className="spinner-border spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="dashboard-card__value">
              {value ? `${value} Euro` : 'No data'}
            </span>
          )}
        </div>

        <div className="d-flex">
          <select className="dashboard-select" onChange={handleChange}>
            <option value={NAnalytics.RevenuePerUnit.DAY}>per day</option>
            <option value={NAnalytics.RevenuePerUnit.WEEK}>per week</option>
            <option value={NAnalytics.RevenuePerUnit.MONTH}>per month</option>
            <option value={NAnalytics.RevenuePerUnit.YEAR}>per year</option>
            <option value={NAnalytics.RevenuePerUnit.CUSTOMER}>per user</option>
            <option value={NAnalytics.RevenuePerUnit.ORDER}>per order</option>
          </select>
          <span className="dashboard-select-icon">
            <SvgExpandMore />
          </span>
        </div>
      </CardBody>
    </Card>
  );
});
