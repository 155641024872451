import { useMemo } from 'react';
import { GET_ALL_USER_VEHICLES } from '../user-vehicles';
import { GetAllUserVehiclesOutput } from '../../types';
import { useAppQuery } from '../../apollo';

export const useUserVehicles = () => {
  const { data, loading } = useAppQuery<GetAllUserVehiclesOutput>(GET_ALL_USER_VEHICLES);

  const userVehicles = useMemo(
    () => data?.getAllUserVehicles ?? [],
    [data?.getAllUserVehicles],
  );

  return { userVehicles, loading };
};
