import { useSubscription } from '@apollo/client';
import { NUser } from '../../../types';
import { UPDATE_USER_SUBSCRIPTION } from '../../../subscriptions';
import { GET_ALL_USERS } from '../../../queries';

export const useUserUpdatedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NUser.On.Update.Output>(UPDATE_USER_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { update } = data.users;

      if (!update) {
        return;
      }

      client.cache.updateQuery<NUser.GetAll.Output>(
        { query: GET_ALL_USERS },
        (record) => {
          if (!record) return;

          return {
            getAllUsers: record.getAllUsers.map((user) => {
              if (user.id === update.id) {
                return update;
              }
              return user;
            }),
          };
        },
      );
    },
  });
};
