import { useMemo } from 'react';
import { GET_ORDER } from '../orders';
import { NOrder } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NOrder.GetOne.Output, NOrder.GetOne.Input> {
  id: string | null | undefined;
}

export const useOrder = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<NOrder.GetOne.Output, NOrder.GetOne.Input>(
    GET_ORDER,
    {
      skip: !id,
      variables: {
        input: {
          id: id ?? '',
        },
      },
      nextFetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const order = useMemo(() => data?.order ?? null, [data?.order]);

  return { order, loading, error };
};
