import { LazyQueryResult } from '@apollo/client';
import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { useEffect, useRef } from 'react';
import { AppLazyQueryOptions, useAppLazyQuery } from './useAppLazyQuery';

export interface AppQueryOptions<TData = any, TVariables = OperationVariables>
  extends AppLazyQueryOptions<TData, TVariables> {
  skip?: boolean;
}

export const useAppQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: AppQueryOptions<TData, TVariables>,
): LazyQueryResult<TData, TVariables> => {
  const initialFetchRef = useRef(true);

  const [fetchData, { __skip, ...result }] = useAppLazyQuery(query, options);

  useEffect(() => {
    if (options?.skip) return;

    if (!__skip && initialFetchRef.current) {
      initialFetchRef.current = false;
      void fetchData();
    }
  }, [fetchData, options?.skip, __skip]);

  return result;
};
