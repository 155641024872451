import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ERoutes } from '../../constants/route.constant';
import { useAuth } from '../../context/Auth';
import { Preloader } from '../../components/Preloader';
import { RouteProps } from 'react-router';
import { useNetwork } from '../../context/Network';

export const ProtectedRoute = memo<Omit<RouteProps, 'component'>>(
  ({ children, ...rest }) => {
    const { isLoggedIn } = useAuth();
    const { isInitializing } = useNetwork();

    /**
     * If the session is extended,
     * return the loader
     * */
    if (isInitializing) {
      return <Preloader />;
    }

    return (
      <Route
        {...rest}
        render={({ location }) => {
          /**
           * If the user is not logged in,
           * redirect him to the login page and save the current location
           * */
          if (!isLoggedIn) {
            return (
              <Redirect
                to={{
                  pathname: ERoutes.LOGIN,
                  state: {
                    from: location,
                  },
                }}
              />
            );
          }

          /* Otherwise, return the page */
          return children;
        }}
      />
    );
  },
);
