import React from 'react';

export const SvgBarcode = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 5H3.89798V19H2V5ZM13.8313 5H15.187V19H13.8313V5ZM11.205 5H12.2206V19H11.205V5ZM5.50873 5H6.08548V19H5.50873V5ZM20.3065 5H22V19H20.3065V5ZM16.7978 5H18.6958V19H16.7978V5ZM7.69623 5H9.59421V19H7.69623V5Z" />
  </svg>
);
