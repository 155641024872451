import { useMemo } from 'react';
import { NReviewTag } from '../../types';
import { GET_ALL_REVIEW_TAGS } from '../review-tags';
import { useAppQuery } from '../../apollo';

export const useReviewTags = () => {
  const { data, loading } = useAppQuery<NReviewTag.GetAll.Output>(GET_ALL_REVIEW_TAGS);

  const reviewTags = useMemo(() => data?.reviewTags ?? [], [data?.reviewTags]);

  return { reviewTags, loading };
};
