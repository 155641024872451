import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateQuestionAnswerSidebar } from './CreateQuestionAnswer';
import { UpdateQuestionAnswerSidebar } from './UpdateQuestionAnswer';

export const QuestionsAnswersSidebars = memo(() => (
  <>
    <FProvider>
      <CreateQuestionAnswerSidebar />
    </FProvider>
    <FProvider>
      <UpdateQuestionAnswerSidebar />
    </FProvider>
  </>
));
