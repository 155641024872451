import { useRef, useEffect } from 'react';
import { useSetValidator, useStatusSubmitted } from '../index';

const useValidator = (name: string, isValid: () => boolean) => {
  const setValidator = useSetValidator();
  const submitted = useStatusSubmitted();
  const ref = useRef(() => {});

  useEffect(() => {
    ref.current = isValid;
  }, [isValid]);

  useEffect(() => {
    if (name) {
      setValidator(name, () => ref.current());
    }
  }, [name, setValidator]);

  if (submitted) {
    return isValid();
  }

  return true;
};

export default useValidator;
