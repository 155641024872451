export namespace NSettings {
  export interface Entity {
    breakTime: number;
    urgentPrice: number;
    pauseTimeBeforeBreak: number;
    defaultParcelTypeId: string;
    orderAmountPercent: number;
    additionalPaymentFee: number;
    stripeMinPaymentAmount: number;
    vehiclesMinRadiusInM: number;
    vehiclesMidRadiusInM: number;
    vehiclesMaxRadiusInM: number;
    timeForOrderAcceptanceInMs: number;
    pickUpTypeLaterExpirationTimeAnotherDayInMinutes: number;
    pickUpTypeLaterExpirationTimeSameDayInMinutes: number;
    pickUpTypeNowExpirationTimeInMinutes: number;
    pickUpTypeUrgentExpirationTimeInMinutes: number;
    analyticsStartDate: number;
  }

  /* Query */
  export namespace Get {
    export type Output = {
      getSettings: Entity;
    };
  }

  /* Mutation */
  export namespace Set {
    export type Input = { input: Entity };
    export type Output = { setSettings: boolean };
  }

  /* Subscriptions */
  export namespace On {
    export namespace Update {
      export type Output = {
        settings: { update: NSettings.Entity | null };
      };
    }
  }
}
