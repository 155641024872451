import { gql } from '@apollo/client';

export const GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_BY_REASON_EXPECTED_REVENUE = gql`
  query analyticsStaticForLostOrdersByReasonExpectedRevenue(
    $input: GetAnalyticsStaticByLostOrdersReasonInput!
  ) {
    analyticsStaticForLostOrdersByReasonExpectedRevenue(input: $input) {
      expectedRevenue
    }
  }
`;
