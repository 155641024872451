import React, { memo } from 'react';
import { CreateUserSidebar } from './CreateUser';
import { UpdateUserSidebar } from './UpdateUser';
import { FProvider } from '../../../../../libs/FForm';
import { UserSidebar } from './User';

export const UserSidebars = memo(() => (
  <>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <UserSidebar />
    </FProvider>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <CreateUserSidebar />
    </FProvider>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <UpdateUserSidebar />
    </FProvider>
  </>
));
