import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query {
    getSettings {
      breakTime
      pauseTimeBeforeBreak
      urgentAmount
      defaultParcelTypeId
      orderAmountPercent
      additionalPaymentFee
      stripeMinPaymentAmount
      vehiclesMinRadiusInM
      vehiclesMidRadiusInM
      vehiclesMaxRadiusInM
      timeForOrderAcceptanceInMs
      pickUpTypeLaterExpirationTimeAnotherDayInMinutes
      pickUpTypeLaterExpirationTimeSameDayInMinutes
      pickUpTypeNowExpirationTimeInMinutes
      pickUpTypeUrgentExpirationTimeInMinutes
      analyticsStartDate
    }
  }
`;
