import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { App } from './App';
import './i18n';
import { AuthProvider } from './context/Auth';
import { DirectionsProvider } from './context/Directions';
import { NetworkProvider } from './context/Network';
import { client } from './apollo';

const app = (
  <NetworkProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <DirectionsProvider>
            <App />
          </DirectionsProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  </NetworkProvider>
);

ReactDOM.render(app, document.getElementById('root'));
