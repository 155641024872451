import React, {
  CSSProperties,
  forwardRef,
  memo,
  PropsWithChildren,
  UIEventHandler,
} from 'react';
import classNames from 'classnames';

interface UIScrollableContainerProps {
  style?: CSSProperties;
  className?: string;
  horizontal?: boolean;
  onScroll?: UIEventHandler<HTMLDivElement>;
}

export const UIScrollableContainer = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<UIScrollableContainerProps>>(
    ({ children, className, horizontal = false, ...props }, ref) => (
      <div
        ref={ref}
        className={classNames(
          {
            ui__container_scrollable_vertical: !horizontal,
            ui__container_scrollable_horizontal: horizontal,
          },
          className,
        )}
        {...props}
      >
        {children}
      </div>
    ),
  ),
);
