import { useSubscription } from '@apollo/client';
import { DELETE_PARCEL_TYPE_SUBSCRIPTION } from '../../../subscriptions';
import { NParcelType } from '../../../types';
import { GET_ALL_PARCEL_TYPES } from '../../../queries';

export const useParcelTypeRemovedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NParcelType.On.Delete.Output>(DELETE_PARCEL_TYPE_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { delete: removed } = data.parcelTypes;

      if (!removed) {
        return;
      }

      client.cache.updateQuery<NParcelType.GetAll.Output>(
        { query: GET_ALL_PARCEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            getAllParcelTypes: record.getAllParcelTypes.filter(
              (parcelType) => parcelType.id !== removed.id,
            ),
          };
        },
      );
    },
  });
};
