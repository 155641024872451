import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { useReviews } from '../../../queries';
import { ESearchQuery } from '../../../constants/query.constant';
import { NReview } from '../../../types';
import { SvgStar } from '../../../assets/icons';
import { parseDate } from '../../../utilities/parse-date';
import { ELocalStorage } from '../../../constants/local-storage.constant';
import { FILES_URL } from '../../../constants';

export const RenderReviewsPage = memo(() => {
  const history = useHistory();
  const { reviews } = useReviews();

  const [isDownloadingXLSFile, setIsDownloadingXLSFile] = useState(false);

  const tableData = reviews;

  const onRowClick = useCallback(
    (e, row: NReview.Entity) => {
      if (e.target) {
        let currentNode: HTMLDivElement = e.target;

        if (currentNode.nodeName !== 'TD') {
          while (currentNode?.parentNode && currentNode.parentNode.nodeName !== 'TD') {
            currentNode = currentNode.parentNode as HTMLDivElement;
          }
          if (currentNode.dataset.hasOwnProperty('unclickable')) {
            return;
          }
        }

        history.push({
          search: `${ESearchQuery.REVIEW_ID}=${row.id}&${ESidebarPath.REVIEW}`,
        });
      }
    },
    [history],
  );

  const onDownloadXLS = useCallback(async () => {
    setIsDownloadingXLSFile(true);

    try {
      const accessToken = localStorage.getItem(ELocalStorage.ACCESS_TOKEN);
      const response = await fetch(
        `${FILES_URL}/reviews-report?accessToken=${accessToken}`,
      );
      const blob = await response.blob();

      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobURL;
      a.download = 'Reviews.xls';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(blobURL);
      setIsDownloadingXLSFile(false);
    } catch (e) {
      setIsDownloadingXLSFile(false);
    }
  }, []);

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'order.uniqueCode',
        order: 'desc',
      },
    ],
    [],
  );

  const columns = useMemo<ColumnDescription[]>(
    () => [
      {
        dataField: 'order.uniqueCode',
        text: 'Order',
        sort: true,
        formatter: (_, row: NReview.Entity) => {
          const { order } = row;

          return <>{order.uniqueCode}</>;
        },
      },
      {
        dataField: 'driver',
        text: 'Driver',
        sort: true,
        sortFunc: (a: NReview.Entity['driver'], b: NReview.Entity['driver'], order) => {
          if (!a && b) return 1;
          if (a && !b) return -1;
          if (!(a && b)) return 0;

          if (order === 'asc') {
            return `${a.firstName} ${a.lastName}`.localeCompare(
              `${b.firstName} ${b.lastName}`,
            );
          }

          return `${b.firstName} ${b.lastName}`.localeCompare(
            `${a.firstName} ${a.lastName}`,
          );
        },
        formatter: (_, row: NReview.Entity) => {
          if (!row.driver) {
            return <span className="text-secondary">No driver</span>;
          }

          return (
            <>
              {row.driver.firstName} {row.driver.lastName}
            </>
          );
        },
      },
      {
        dataField: 'rating.score',
        text: 'Driver Rating',
        sort: true,
        formatter: (_, row: NReview.Entity) => {
          if (!row.rating) {
            return <></>;
          }

          return (
            <div className="d-flex align-items-center">
              {row.rating.score}
              <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
                <SvgStar fill={row.rating.score > 2 ? '#FFC84E' : '#f46a6a'} />
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'service',
        text: 'Service',
        sort: true,
        formatter: (_, row: NReview.Entity) => {
          return (
            <div className="d-flex align-items-center">
              {row.service}
              <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
                <SvgStar fill={row.service > 2 ? '#FFC84E' : '#f46a6a'} />
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'customer',
        text: 'Customer',
        sort: true,
        sortFunc: (
          a: NReview.Entity['customer'],
          b: NReview.Entity['customer'],
          order,
        ) => {
          if (order === 'asc') {
            return `${a.firstName} ${a.lastName}`.localeCompare(
              `${b.firstName} ${b.lastName}`,
            );
          }

          return `${b.firstName} ${b.lastName}`.localeCompare(
            `${a.firstName} ${a.lastName}`,
          );
        },
        formatter: (_, row: NReview.Entity) => {
          return (
            <>
              {row.customer.firstName} {row.customer.lastName}
            </>
          );
        },
      },
      {
        dataField: 'order.pickUpTime',
        text: 'Order pick-up time',
        sort: true,
        formatter: (_, row: NReview.Entity) => {
          return <>{parseDate(row.order.pickUpTime)}</>;
        },
      },
    ],
    [],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [columns, tableData],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2 me-2 d-inline-flex align-items-center justify-content-center"
                                onClick={onDownloadXLS}
                              >
                                {isDownloadingXLSFile ? (
                                  <span className="spinner-border spinner-border-sm" />
                                ) : (
                                  <>Download .xls</>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});
