import { gql } from '@apollo/client';

export const GET_ALL_ORDERS = gql`
  query {
    getAllOrders {
      id
      uniqueCode
      orderStatus
      pickUpTime
      pickUpTimeType
      attachType
      paymentStatus
    }
  }
`;
