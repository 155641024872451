import React, { memo, useCallback, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router';
import { useUserVehicles } from '../../../queries';
import { IUserVehicle } from '../../../interfaces/user-vehicle';
import { ESearchQuery } from '../../../constants/query.constant';
import { ESidebarPath } from '../../../constants/sidebar.constant';

export const RenderAssignedVehiclesPage = memo(() => {
  const history = useHistory();
  const { userVehicles } = useUserVehicles();
  const tableData = userVehicles;

  const columns: ColumnDescription[] = useMemo(
    () => [
      {
        dataField: 'vehicle',
        text: 'Vehicle',
        sort: true,
        formatter(_, row: IUserVehicle) {
          return <>{row.vehicle.title}</>;
        },
      },
      {
        dataField: 'user',
        text: 'Driver',
        sort: true,
        formatter(_, row: IUserVehicle) {
          if (row.user) {
            return (
              <>
                {row.user.firstName} {row.user.lastName}
              </>
            );
          }
          return <span className="badge badge-soft-info">No driver</span>;
        },
      },
    ],
    [],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [columns, tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'id',
        order: 'desc',
      },
    ],
    [],
  );

  const onRowClick = useCallback(
    (_, row: IUserVehicle) => {
      history.push({
        search: `${ESearchQuery.USER_VEHICLE_ID}=${row.id}&${ESidebarPath.ASSIGN_VEHICLE}`,
      });
    },
    [history],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});
