import { useSubscription } from '@apollo/client';
import { DELETE_FIRST_ORDER_PICK_UP_WAYPOINT_SUBSCRIPTION } from '../../../subscriptions';
import {
  DeleteFirstPickUpWaypointSubscriptionOutput,
  GetOrderFirstPickUpWaypointsOutput,
} from '../../../types';
import { GET_ORDER_FIRST_PICK_UP_WAYPOINTS } from '../../../queries';

export const useFirstOrderPickUpWaypointRemovedSubscription = (tokenIsValid: boolean) => {
  useSubscription<DeleteFirstPickUpWaypointSubscriptionOutput>(
    DELETE_FIRST_ORDER_PICK_UP_WAYPOINT_SUBSCRIPTION,
    {
      skip: !tokenIsValid,
      shouldResubscribe: true,
      onData({ client, data: { data } }) {
        if (!data) return;

        const { id } = data.deleteFirstOrderPickWaypointSubscription;

        client.cache.updateQuery<GetOrderFirstPickUpWaypointsOutput>(
          { query: GET_ORDER_FIRST_PICK_UP_WAYPOINTS },
          (record) => {
            if (!record) return;

            return {
              orderFirstPickUpWaypoints:
                record?.orderFirstPickUpWaypoints.filter(
                  (waypoint) => waypoint.id !== id,
                ) ?? [],
            };
          },
        );
      },
    },
  );
};
