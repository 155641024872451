import React, { memo, useCallback } from 'react';
import { SvgDownloadFile } from '../../assets/icons';
import { ELocalStorage } from '../../constants/local-storage.constant';
import { FILES_URL } from '../../constants';

interface UIDownloadFileProps {
  fileId: string;
  filename: string;
}

export const UIDownloadFile = memo<UIDownloadFileProps>(({ fileId, filename }) => {
  const onDownload = useCallback(async () => {
    const accessToken = localStorage.getItem(ELocalStorage.ACCESS_TOKEN);
    const response = await fetch(`${FILES_URL}/${fileId}?accessToken=${accessToken}`);
    const blob = await response.blob();

    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobURL;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(blobURL);
  }, [fileId, filename]);

  return (
    <div
      className="item download-file"
      onClick={onDownload}
      style={{ cursor: 'pointer' }}
    >
      <div className="item__icon">
        <SvgDownloadFile />
      </div>
      <div className="item__details">
        <div className="item__subtitle">{filename}</div>
      </div>
    </div>
  );
});
