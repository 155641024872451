import { gql } from '@apollo/client';

export const GET_ALL_USER_VEHICLES = gql`
  query {
    getAllUserVehicles {
      id
      user {
        firstName
        lastName
      }
      vehicle {
        title
      }
    }
  }
`;
