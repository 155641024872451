import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderOrderSidebar } from './RenderOrderSidebar';

export const OrderSidebar = memo(() => (
  <Sidebar name={ESidebar.ORDER} width={450}>
    <RenderOrderSidebar />
  </Sidebar>
));
