import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUpdateQuestionAnswerSidebar } from './RenderUpdateQuestionAnswerSidebar';

export const UpdateQuestionAnswerSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.UPDATE_QUESTION_ANSWER} onClose={handleClear}>
      <RenderUpdateQuestionAnswerSidebar />
    </Sidebar>
  );
});
