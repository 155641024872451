import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($input: UsersUpdateInput!) {
    users {
      update(input: $input) {
        id
        email
        firstName
        lastName
        isVerifyEmail
        isVerifyPhoneNumber
        phoneNumber
        photoFileId
        userRole
        licenceID
        rating
      }
    }
  }
`;
