import React, { memo, useCallback, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSearchQuery } from '../../../../../../../hooks';
import { ESearchQuery } from '../../../../../../../constants/query.constant';
import { NChat } from '../../../../../../../types';
import { CREATE_MESSAGE } from '../../../../../../../mutations';
import { MessageInput, MessageInputRef } from './components';
import { GET_CHAT } from '../../../../../../../queries';

export const Footer = memo(() => {
  const searchQuery = useSearchQuery();
  const chatId = searchQuery.get(ESearchQuery.CHAT_ID);
  const chatType = searchQuery.get(ESearchQuery.CHAT_TYPE);
  const client = useApolloClient();

  const inputRef = useRef<MessageInputRef>(null);

  const [createMessage, { loading }] = useMutation<
    NChat.Message.Create.Output,
    NChat.Message.Create.Input
  >(CREATE_MESSAGE, {
    onError(err) {
      console.error(err.message);
    },
  });

  const handleSendMessage = useCallback(
    async (text: string) => {
      if (text && chatId) {
        const response = await createMessage({
          variables: {
            input: {
              chatId,
              text,
            },
          },
        });

        const createdMessage = response.data?.createMessage;

        if (!createdMessage) {
          return;
        }

        // Add a sent message to the chat room
        client.cache.updateQuery<NChat.GetOne.Output, NChat.GetOne.Input>(
          {
            query: GET_CHAT,
            variables: {
              input: {
                id: chatId,
              },
            },
          },
          (record) => {
            if (!record) return;

            return {
              chat: {
                ...record.chat,
                messages: [...record.chat.messages, createdMessage],
              },
            };
          },
        );

        // // Set the new last chat message in the chat list
        // client.cache.updateQuery<GetChatsOutput>({ query: GET_CHATS }, (record) => {
        //   if (!record) return;
        //
        //   return {
        //     chats: record.chats.map((chat) => {
        //       if (chat.id === send.chat.id) {
        //         return {
        //           ...chat,
        //           messages: [send],
        //         };
        //       }
        //       return chat;
        //     }),
        //   };
        // });

        inputRef.current?.clear();
      }
    },
    [chatId, client, createMessage],
  );

  if (
    chatType &&
    [NChat.Type.ORDER_SUPPORT, NChat.Type.EMPLOYEE].includes(chatType as NChat.Type)
  ) {
    return (
      <div className="chat-room__footer d-flex">
        <MessageInput ref={inputRef} onSend={handleSendMessage} />
        {loading ? (
          <button className="chat-room__send-message-btn">
            <div className="spinner-border spinner-border-sm text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </button>
        ) : (
          <button
            className="chat-room__send-message-btn"
            onClick={() => handleSendMessage(inputRef.current?.value ?? '')}
          >
            <span>Send</span>
            <i className="mdi mdi-send" />
          </button>
        )}
      </div>
    );
  }

  return null;
});
