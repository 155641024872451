import { NOrder } from '../types';

export const Colors = {
  VEHICLE: 'rgb(0,0,0)',
  SELECTED_VEHICLE: 'rgb(0,45,255)',
  VEHICLE_ROUTE: 'rgb(29,113,212)',
  ORDER_IS_ACCEPTED: 'rgb(57,92,123)',
  ORDER_ROUTE: 'rgb(72,149,239)',
  SELECTED_ORDER_START_POINT: 'rgb(0,45,255)',
  SELECTED_ORDER_PICK_UP: 'rgb(0,45,255)',
  SELECTED_ORDER_DROP_OFF: 'rgb(63,55,201)',
  [NOrder.PickUpTimeType.NOW]: 'rgb(255,161,9)',
  [NOrder.PickUpTimeType.LATER]: 'rgb(39, 174, 96)',
  [NOrder.PickUpTimeType.URGENT]: 'rgb(255,82,82)',
};
