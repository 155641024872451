import { useSubscription } from '@apollo/client';
import { NOrder } from '../../../types';
import { GET_ORDER } from '../../../queries';
import { ORDER_UPDATED_VEHICLE_SUBSCRIPTION } from '../../../subscriptions';

export const useOrderUpdatedVehicleSubscription = (tokenIsValid: boolean) => {
  useSubscription<NOrder.On.UpdatedVehicle.Output>(ORDER_UPDATED_VEHICLE_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const {
        orderUpdatedVehicle: { id, driver, vehicle },
      } = data;

      client.cache.updateQuery<NOrder.GetOne.Output, NOrder.GetOne.Input>(
        {
          query: GET_ORDER,
          variables: { input: { id } },
        },
        (record) => {
          if (!record) return;

          return {
            order: {
              ...record.order,
              driver,
              vehicle,
            },
          };
        },
      );
    },
  });
};
