export enum ESidebarPath {
  CREATE_VEHICLE_TYPE = 's=create_vehicle_type',
  UPDATE_VEHICLE_TYPE = 's=update_vehicle_type',

  CREATE_PARCEL_TYPE = 's=create_parcel_type',
  UPDATE_PARCEL_TYPE = 's=update_parcel_type',

  USER = 's=user',
  CREATE_USER = 's=create_user',
  UPDATE_USER = 's=update_user',

  CREATE_VEHICLE = 's=create_vehicle',
  UPDATE_VEHICLE = 's=update_vehicle',
  ASSIGN_VEHICLE = 's=assign-vehicle',

  BARCODE = 's=barcode',
  CREATE_BARCODE = 's=create_barcode',

  ORDER = 's=order',
  ATTACH_ORDER = 's=attach-order',
  REATTACH_ORDER = 's=reattach-order',

  USER_VEHICLE = 's=user-vehicle',

  CREATE_REVIEW_TAG = 's=create_review_tag',
  UPDATE_REVIEW_TAG = 's=update_review_tag',

  REVIEW = 's=review',

  CREATE_QUESTION_ANSWER = 's=create_question_answer',
  UPDATE_QUESTION_ANSWER = 's=update_question_answer',

  CREATE_VEHICLE_FUEL_TYPE = 's=create_vehicle_fuel_type',
  UPDATE_VEHICLE_FUEL_TYPE = 's=update_vehicle_fuel_type',
}

export enum ESidebar {
  CREATE_VEHICLE_TYPE = 'create_vehicle_type',
  UPDATE_VEHICLE_TYPE = 'update_vehicle_type',

  CREATE_PARCEL_TYPE = 'create_parcel_type',
  UPDATE_PARCEL_TYPE = 'update_parcel_type',

  USER = 'user',
  CREATE_USER = 'create_user',
  UPDATE_USER = 'update_user',

  CREATE_VEHICLE = 'create_vehicle',
  UPDATE_VEHICLE = 'update_vehicle',
  ASSIGN_VEHICLE = 'assign-vehicle',

  ORDER = 'order',
  ATTACH_ORDER = 'attach-order',
  REATTACH_ORDER = 'reattach-order',

  BARCODE = 'barcode',
  CREATE_BARCODE = 'create_barcode',

  USER_VEHICLE = 'user-vehicle',

  CREATE_REVIEW_TAG = 'create_review_tag',
  UPDATE_REVIEW_TAG = 'update_review_tag',

  REVIEW = 'review',

  CREATE_QUESTION_ANSWER = 'create_question_answer',
  UPDATE_QUESTION_ANSWER = 'update_question_answer',

  CREATE_VEHICLE_FUEL_TYPE = 'create_vehicle_fuel_type',
  UPDATE_VEHICLE_FUEL_TYPE = 'update_vehicle_fuel_type',
}
