import React, { useCallback, useState, useEffect, memo } from 'react';
import { Row, Col, Card } from 'reactstrap';
import Dropzone from 'react-dropzone';
import FInput from './FInput';
import { useSetValues, useConnector } from '../hooks';
import { UPLOAD_AVATAR_URL } from '../../../constants';
import { ELocalStorage } from '../../../constants/local-storage.constant';
import { ProtectedImage } from '../../../components/ProtectedImage';

interface FileUploaderProps {
  name: string;
  label?: string;
  required?: boolean;
  showDeleteButton?: boolean;
  url?: string;
}

const FileUploader = memo<FileUploaderProps>(
  ({ label, name, required, showDeleteButton = true, url = UPLOAD_AVATAR_URL }) => {
    const setValues = useSetValues();

    const [uploadedFile, setUploadedFile] = useState<{ id: string | null }>({ id: null });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useConnector(name, uploadedFile.id, (id) => setUploadedFile({ id }));

    useEffect(() => {
      setValues({ [`upload/${name}`]: uploadedFile.id });
    }, [name, setValues, uploadedFile.id]);

    useEffect(() => {
      return () => {
        setUploadedFile({ id: null });
        setLoading(false);
        setError('');
        setValues({ [`upload/${name}`]: '' });
      };
    }, [name, setValues]);

    const sendFile = useCallback(
      (file: File) => {
        setLoading(true);
        setValues({ [`upload/${name}`]: '' });

        const accessToken = localStorage.getItem(ELocalStorage.ACCESS_TOKEN);

        if (!accessToken) {
          return;
        }

        const form = new FormData();

        form.append('file', file, file.name);

        fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: form,
        })
          .then(async (res) => {
            if (!res.ok) {
              const data = await res.json();
              setError(JSON.stringify(data));
            }
            return res.json();
          })
          .then((data) => {
            setLoading(false);
            if (data?.status !== 403) {
              setUploadedFile(data);
            }
          })
          .catch((err) => {
            setError(err.message);
            setValues({ [`upload/${name}`]: '' });
          });
      },
      [name, setValues, url],
    );

    const handleDelete = useCallback(() => {
      setValues({ [name]: '' });
    }, [name, setValues]);

    return (
      <Row>
        <label>
          {label}{' '}
          {required ? (
            <span className="text-danger">*</span>
          ) : (
            <span className="text-secondary">(optional)</span>
          )}
        </label>
        <Dropzone onDrop={(files) => sendFile(files[0])} maxFiles={1}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone p-3 d-flex align-items-center justify-content-center">
              <div className="needsclick" {...getRootProps()}>
                <input className="form-control" {...getInputProps()} />

                {uploadedFile.id ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="d-flex align-items-center justify-content-center font-size-16">
                        <Col>
                          <ProtectedImage
                            id={uploadedFile.id}
                            style={{
                              width: 200,
                              height: 'auto',
                              backgroundSize: 'contain',
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : (
                  <div className="dz-message">
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    Drop files here or click to upload.
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        <div className="mt-3 d-flex">
          <div className="w-100">
            {loading && (
              <div
                className="mb-3"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="spinner-border text-primary spinner-border-sm" />
              </div>
            )}
            <div className="d-flex w-100">
              <FInput
                name={`upload/${name}`}
                errorMessage={error || 'Upload file to get the link'}
                placeholder="ID"
                containerClassName="w-100"
                required={required}
                disabled
              />
              {showDeleteButton && (
                <div
                  style={{ fontSize: 16, width: 36, height: 36, cursor: 'pointer' }}
                  className="d-flex align-items-center justify-content-center"
                  onClick={handleDelete}
                >
                  <i className="fas fa-trash text-secondary" />
                </div>
              )}
            </div>
          </div>
        </div>
      </Row>
    );
  },
);

export default FileUploader;
