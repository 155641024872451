import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs, UIContainer } from '../../../components/ui';
import { Room, LeftSidebar } from './componets';
import { pageTitle } from '../../../utilities/pageTitle';

export const ChatsPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Chats')}</title>
    </Helmet>

    <UIBreadcrumbs title="Chats" />

    <UIContainer className="chats-page">
      <LeftSidebar />
      <Room />
    </UIContainer>
  </>
));
