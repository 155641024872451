import React, {
  ChangeEvent,
  KeyboardEvent,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REJECT_ORDER } from '../../../../../../../mutations';
import { NOrder } from '../../../../../../../types';

export interface ConfirmOrderRejectionModalProps {
  orderId: string;
}

export interface ConfirmOrderRejectionModalRef {
  open(): void;
  close(): void;
}

export const ConfirmOrderRejectionModal = memo(
  forwardRef<ConfirmOrderRejectionModalRef, ConfirmOrderRejectionModalProps>(
    ({ orderId }, ref) => {
      const [isShown, setIsShown] = useState(false);
      const [rejectionReason, setRejectionReason] = useState('');

      const [rejectOrder, { loading }] = useMutation<
        NOrder.Reject.Output,
        NOrder.Reject.Input
      >(REJECT_ORDER, {
        variables: {
          input: {
            id: orderId,
            rejectionReason,
          },
        },
        onCompleted() {
          toast('Order was successfully rejected!', {
            type: 'success',
          });
          setRejectionReason('');
          setIsShown(false);
        },
        onError(err) {
          toast(`Error (when rejecting an order): ${err.message}`, {
            type: 'error',
            autoClose: 2000,
          });
        },
      });

      useImperativeHandle(
        ref,
        () => ({
          open: () => setIsShown(true),
          close: () => setIsShown(false),
        }),
        [],
      );

      const handleCancel = useCallback(() => {
        setIsShown(false);
      }, []);

      const handleConfirm = useCallback(() => {
        if (rejectionReason.length) {
          void rejectOrder();
        }
      }, [rejectOrder, rejectionReason.length]);

      const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setRejectionReason(e.target.value);
      }, []);

      const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
          if (e.code === 'Enter') {
            handleConfirm();
          }
        },
        [handleConfirm],
      );

      return (
        <Modal
          show={isShown}
          onHide={() => setIsShown(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="ui__modal"
          backdropClassName="ui__modal_backdrop"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Write the reason for the order rejection
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Reason"
              value={rejectionReason}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCancel} color="light">
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              className="d-flex justify-content-center"
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <>Confirm</>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    },
  ),
);
