import React, { memo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import {
  GET_ALL_USER_VEHICLES,
  useAvailableVehicleUsers,
} from '../../../../../../queries';
import { IUserVehicle } from '../../../../../../interfaces/user-vehicle';
import { FSelect } from '../../../../../../libs/FForm/components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { ATTACH_USER } from '../../../../../../mutations';
import {
  AttachUserInput,
  AttachUserOutput,
  GetAllUserVehiclesOutput,
} from '../../../../../../types';
import { toast } from 'react-toastify';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderAssignVehicleSidebar = memo(() => {
  const history = useHistory();
  const client = useApolloClient();
  const handleSubmit = useHandleSubmit();

  const searchQuery = useSearchQuery();
  const userVehicleId = useSearchParam(searchQuery, ESearchQuery.USER_VEHICLE_ID);

  const nextUserId = useRef<string | null>(null);

  const { drivers, loading: isFetching, error } = useAvailableVehicleUsers();

  const [assignEmployeeToVehicle, { loading }] = useMutation<
    AttachUserOutput,
    AttachUserInput
  >(ATTACH_USER, {
    onCompleted() {
      toast(
        `The driver was successfully ${nextUserId.current ? 'assigned' : 'unassigned'}!`,
        {
          type: 'success',
        },
      );
      history.goBack();
    },
    onError(err) {
      toast(`Error (when trying to assign a driver to a vehicle): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    async (values: AttachUserInput['input']) => {
      if (!userVehicleId) return;

      const userId = values.userId || null;
      nextUserId.current = userId;

      const response = await assignEmployeeToVehicle({
        variables: {
          input: {
            ...values,
            userId,
            userVehicleLinkId: userVehicleId,
          },
        },
      });

      client.cache.updateQuery<GetAllUserVehiclesOutput>(
        { query: GET_ALL_USER_VEHICLES },
        (record) => {
          if (!record) return;

          return {
            getAllUserVehicles: record.getAllUserVehicles.map((vehicle) => {
              if (vehicle.id === userVehicleId) {
                if (userId) {
                  return {
                    ...vehicle,
                    user: response.data?.attachUser as IUserVehicle['user'],
                  };
                } else {
                  return { ...vehicle, user: null };
                }
              }
              return vehicle;
            }),
          };
        },
      );
    },
    [assignEmployeeToVehicle, client, userVehicleId],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!drivers) {
    return <NoData title={`No drivers data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="assign vehicle" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <FSelect
          label="Driver"
          name="userId"
          data={drivers}
          extractor={(item: { id: string; firstName: string; lastName: string }) => [
            item.id,
            item.id,
            `${item.firstName} ${item.lastName}`,
          ]}
        />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
