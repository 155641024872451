export enum ESearchQuery {
  USER_ID = 'u:id',
  USER_ROLE = 'u:role',
  ORDER_ID = 'o:id',
  ORDER_ROUTE = 'o:route',
  USER_VEHICLE_ID = 'u-v:id',
  USER_VEHICLE_ROUTE = 'u-v:route',
  PARCEL_TYPE_ID = 'p-t:id',
  VEHICLE_TYPE_ID = 'v-t:id',
  VEHICLE_ID = 'v:id',
  SIDEBAR = 's',
  BARCODE_ID = 'b:id',
  CHAT_TAB = 'c-t',
  CHAT_ID = 'c:id',
  CHAT_TYPE = 'c:t',
  REVIEW_TAG_ID = 'p:id',
  REVIEW_ID = 'r:id',
  QUESTION_ANSWER_ID = 'q-a:id',
  VEHICLE_FUEL_TYPE_ID = 'v-f-t:id',
}
