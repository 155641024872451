import React, { memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { GET_ALL_USERS } from '../../../../../../queries';
import { UserFields, UserRoleSelect } from '../components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { CREATE_USER } from '../../../../../../mutations';
import { NUser } from '../../../../../../types';

export const RenderCreateUserSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();
  const [userRole, setUserRole] = useState<NUser.Role>(NUser.Role.EMPLOYEE_DRIVER);

  const [createUser, { loading }] = useMutation<NUser.Create.Output, NUser.Create.Input>(
    CREATE_USER,
    {
      update: (cache, { data: response }) => {
        if (response) {
          cache.updateQuery<NUser.GetAll.Output>({ query: GET_ALL_USERS }, (record) => {
            if (!record) return;

            return {
              getAllUsers: [...record.getAllUsers, response.createUser],
            };
          });
        }
      },
      onCompleted() {
        toast('User was successfully created!', {
          type: 'success',
        });
        history.goBack();
      },
      onError(err) {
        toast(`Error (when creating a user): ${err.message}`, {
          type: 'error',
          autoClose: 2000,
        });
      },
    },
  );

  const handleValidSubmit = useCallback(
    (values: NUser.Create.Input['input']) => {
      void createUser({
        variables: {
          input: values,
        },
      });
    },
    [createUser],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new user" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <UserRoleSelect selected={userRole} onChange={setUserRole} />

        <UserFields loading={loading} userRole={userRole} isCreating={true} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
