import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation signInWithEmailAndPassword($input: SignInWithEmailAndPasswordInput!) {
    signInWithEmailAndPassword(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
