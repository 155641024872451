import { useMemo } from 'react';
import { NQuestionAnswer } from '../../types';
import { GET_QUESTION_ANSWER } from '../questions-answers';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<NQuestionAnswer.GetOne.Output, NQuestionAnswer.GetOne.Input> {
  id: string | null | undefined;
}

export const useQuestionAnswer = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NQuestionAnswer.GetOne.Output,
    NQuestionAnswer.GetOne.Input
  >(GET_QUESTION_ANSWER, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const questionAnswer = useMemo(
    () => data?.questionAnswer ?? null,
    [data?.questionAnswer],
  );

  return { questionAnswer, loading, error };
};
