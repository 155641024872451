import React, { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHandleSubmit, useSetValues } from '../../../libs/FForm';
import { useSettings, useParcelTypes } from '../../../queries';
import { NSettings, NUser } from '../../../types';
import { SET_SETTINGS } from '../../../mutations';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { FInput, FSelect } from '../../../libs/FForm/components';
import { VehicleFuelTypes } from './components';
import { useAuth } from '../../../context/Auth';
import FDatePicker from '../../../libs/FForm/components/FDatePicker';

export const RenderSettingsPage = memo(() => {
  const { profile } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (profile?.userRole !== NUser.Role.EMPLOYEE_ADMIN) {
      history.push('/');
    }
  }, [history, profile?.userRole]);

  const setValues = useSetValues();
  const handleSubmit = useHandleSubmit();

  const { settings } = useSettings();
  const { parcelTypes } = useParcelTypes();

  useEffect(() => {
    if (settings) {
      setValues(settings);
    }
  }, [setValues, settings]);

  const [setSettings, { loading }] = useMutation<
    NSettings.Set.Output,
    NSettings.Set.Input
  >(SET_SETTINGS, {
    onCompleted() {
      toast('Settings successfully updated!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when updating settings): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NSettings.Set.Input['input']) => {
      void setSettings({
        variables: {
          input: values,
        },
      });
    },
    [setSettings],
  );

  const handleAddNew = useCallback(() => {
    history.push({ search: ESidebarPath.CREATE_PARCEL_TYPE });
  }, [history]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>Analytics</CardTitle>

                  <FDatePicker name="analyticsStartDate" label="Start date" required />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <FInput
                    name="breakTime"
                    label="Break time (min 60s)"
                    placeholder="Break time"
                    type="number"
                    required
                    min={60}
                    disabled={loading}
                  />
                  <FInput
                    name="pauseTimeBeforeBreak"
                    label="Pause time before break"
                    placeholder="Pause time before break"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <div>
                    <label className="form-label">
                      Default parcel type <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <FSelect
                        name="defaultParcelTypeId"
                        data={parcelTypes}
                        placeholder="Default parcel type"
                        required
                        disabled={loading}
                      />
                      <div className="ms-2">
                        <Button variant="outline-light" onClick={handleAddNew}>
                          <i className="mdi mdi-plus" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <CardTitle>Payments</CardTitle>
                  <FInput
                    name="urgentAmount"
                    label="Urgent amount"
                    placeholder="Amount"
                    type="number"
                    disabled={loading}
                    min={0}
                    required
                  />
                  <FInput
                    name="orderAmountPercent"
                    label="Additional % to the order amount"
                    placeholder="Percentage"
                    type="number"
                    disabled={loading}
                    min={0}
                    required
                  />
                  <FInput
                    name="additionalPaymentFee"
                    label="Additional transaction fee"
                    placeholder="Amount"
                    type="number"
                    disabled={loading}
                    min={0}
                    required
                  />
                  <FInput
                    name="stripeMinPaymentAmount"
                    label="(Stripe) Minimum charge amount"
                    placeholder="Amount"
                    type="number"
                    disabled={loading}
                    min={0}
                    required
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <CardTitle>Order acceptance</CardTitle>
                  <FInput
                    name="vehiclesMinRadiusInM"
                    label="Minimum radius (m)"
                    placeholder="Meters"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="vehiclesMidRadiusInM"
                    label="Middle radius (m)"
                    placeholder="Meters"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="vehiclesMaxRadiusInM"
                    label="Maximum radius (m)"
                    placeholder="Meters"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="timeForOrderAcceptanceInMs"
                    label="Order acceptance time (ms)"
                    placeholder="Meters"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <CardTitle>Expiration Time</CardTitle>
                  <FInput
                    name="pickUpTypeUrgentExpirationTimeInMinutes"
                    label="Pick up type URGENT (min)"
                    placeholder="Minutes"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="pickUpTypeNowExpirationTimeInMinutes"
                    label="Pick up type NOW (min)"
                    placeholder="Minutes"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="pickUpTypeLaterExpirationTimeSameDayInMinutes"
                    label=" Pick up type LATER for the same day (min)"
                    placeholder="Minutes"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                  <FInput
                    name="pickUpTypeLaterExpirationTimeAnotherDayInMinutes"
                    label=" Pick up type LATER for another day (min)"
                    placeholder="Minutes"
                    type="number"
                    required
                    min={0}
                    disabled={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        type="button"
                        color="success"
                        className="btn w-100"
                        onClick={handleSubmit(handleValidSubmit)}
                        disabled={loading}
                      >
                        {loading ? 'Loading...' : 'Save'}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <VehicleFuelTypes />
        </Col>
      </Row>
    </Container>
  );
});
