import React, { memo } from 'react';
// import { NotificationsProvider } from '../../context/Notifications';
import { UILayout, UINavBar } from '../../components/ui';
import { LayoutProvider } from '../../context/Layout';

export const VerticalLayout = memo(({ children }) => (
  // <NotificationsProvider>
  <LayoutProvider>
    <UILayout>
      <UINavBar />

      {children}
    </UILayout>
  </LayoutProvider>
  // </NotificationsProvider>
));
