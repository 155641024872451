export namespace NParcelType {
  export interface Entity {
    id: string;
    title: string;
    description: string;
    designation: string;
    width: number;
    height: number;
    length: number;
    maxWeightInGrams: number;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { getParcelType: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { getAllParcelTypes: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createParcelType: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Entity };
    export type Output = { updateParcelType: boolean };
  }

  /* Mutation */
  export namespace Delete {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { deleteParcelType: boolean };
  }

  /* Subscriptions */
  export namespace On {
    export namespace Create {
      export type Output = { parcelTypes: { create: Entity | null } };
    }

    export namespace Update {
      export type Output = { parcelTypes: { update: Entity | null } };
    }

    export namespace Delete {
      export type Output = { parcelTypes: { delete: Pick<Entity, 'id'> | null } };
    }
  }
}
