import React, { memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody, Col, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { ESidebarPath } from '../../../../../constants/sidebar.constant';
import { NReviewTag } from '../../../../../types';
import { ESearchQuery } from '../../../../../constants/query.constant';
import { useVehicleFlueTypes } from '../../../../../queries';
import { DeleteButton } from './components';

export const VehicleFuelTypes = memo(() => {
  const history = useHistory();

  const { vehicleFuelTypes } = useVehicleFlueTypes();

  const tableData = vehicleFuelTypes;

  const onCreateNew = useCallback(() => {
    history.push({
      search: `${ESidebarPath.CREATE_VEHICLE_FUEL_TYPE}`,
    });
  }, [history]);

  const onRowClick = useCallback(
    (e, row: NReviewTag.Entity) => {
      if (e.target) {
        let currentNode: HTMLDivElement = e.target;

        if (currentNode.nodeName !== 'TD') {
          while (currentNode?.parentNode && currentNode.parentNode.nodeName !== 'TD') {
            currentNode = currentNode.parentNode as HTMLDivElement;
          }
          if (currentNode.dataset.hasOwnProperty('unclickable')) {
            return;
          }
        }

        history.push({
          search: `${ESearchQuery.VEHICLE_FUEL_TYPE_ID}=${row.id}&${ESidebarPath.UPDATE_VEHICLE_FUEL_TYPE}`,
        });
      }
    },
    [history],
  );

  const columns = useMemo<ColumnDescription[]>(
    () => [
      {
        dataField: 'titleEn',
        text: 'Title (en)',
        sort: true,
      },
      {
        dataField: 'titleDe',
        text: 'Title (de)',
        sort: true,
      },
      {
        dataField: 'menu',
        isDummyField: true,
        text: 'Action',
        headerStyle: {
          width: '50px',
        },
        formatter: (cellContent: any, row: NReviewTag.Entity) => {
          return (
            <div className="d-flex" style={{ height: '36px' }} data-unclickable="">
              <DeleteButton id={row.id} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [columns, tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'id',
        order: 'desc',
      },
    ],
    [],
  );

  return (
    <Card>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={tableData}
              columns={columns}
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Search.SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={onCreateNew}
                        >
                          <i className="mdi mdi-plus me-1" />
                          New vehicle fuel type
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={'table align-middle table-nowrap'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          rowEvents={{ onClick: onRowClick }}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
});
