import { gql } from '@apollo/client';

export const GET_ANALYTICS_STATIC_FOR_TOTAL_ORDERS = gql`
  query analyticsStaticForTotalOrders($input: GetStaticForTotalsInput!) {
    analyticsStaticForTotalOrders(input: $input) {
      currentPeriodTotalOrders
      percentOrdersPreviousToCurrent
    }
  }
`;
