import { useMemo } from 'react';
import { GET_ALL_VEHICLE_TYPES } from '../vehicle-types';
import { NVehicleType } from '../../types';
import { useAppQuery } from '../../apollo';

export const useVehicleTypes = () => {
  const { data, loading } =
    useAppQuery<NVehicleType.GetAll.Output>(GET_ALL_VEHICLE_TYPES);

  const vehicleTypes = useMemo(() => data?.vehicleTypes ?? [], [data?.vehicleTypes]);

  return { vehicleTypes, loading };
};
