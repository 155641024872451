import { gql } from '@apollo/client';

export const CREATE_VEHICLE_FUEL_TYPE = gql`
  mutation createVehicleType($input: CreateVehicleFuelTypeInput!) {
    createVehicleFuelType(input: $input) {
      id
      titleEn
      titleDe
    }
  }
`;
