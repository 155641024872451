import React, { memo, useMemo } from 'react';
import { SvgClose, SvgMenu } from '../../assets/icons';
import { useLayout, useLayoutMethods } from '../../context/Layout';
import { UIMenu } from './UIMenu';
import { SidebarBackground } from '../../layouts/VerticalLayout/components/Sidebar';
import {
  OrderSidebars,
  UserSidebars,
  VehicleTypeSidebars,
  ParcelTypeSidebars,
  VehicleSidebars,
  BarcodeSidebars,
  UserVehicleSidebars,
  ReviewTagSidebars,
  ReviewSidebars,
  QuestionsAnswersSidebars,
  VehicleFuelTypeSidebars,
} from '../../layouts/VerticalLayout/components/sidebars';
import { UIBadge } from './UIBadge';
import { useChats } from '../../queries';
import { NChat } from '../../types';
import { findUnreadMessages } from '../../utilities/findUnreadMessages';
import { useAuth } from '../../context/Auth';

export const UINavBar = memo(() => {
  const {
    menu: { isOpen },
  } = useLayout();
  const { menu } = useLayoutMethods();
  const { profile } = useAuth();

  const { chats: orderSupportChats } = useChats({ type: NChat.Type.ORDER_SUPPORT });
  const { chats: employeeChats } = useChats({ type: NChat.Type.EMPLOYEE });

  const numberOfUnreadMessagesInSupportChats = useMemo(() => {
    return orderSupportChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.ORDER_SUPPORT) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [profile?.id, orderSupportChats]);

  const numberOfUnreadMessagesInEmployeeChats = useMemo(() => {
    return employeeChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.EMPLOYEE) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [employeeChats, profile?.id]);

  return (
    <div className="ui__nav-bar">
      <div className="ui__button" onClick={menu.toggle} style={{ zIndex: 10002 }}>
        <UIBadge
          value={
            numberOfUnreadMessagesInSupportChats + numberOfUnreadMessagesInEmployeeChats
          }
          position="right-top"
          size="small"
        />
        {isOpen ? <SvgClose /> : <SvgMenu />}
      </div>

      <UIMenu />

      {isOpen && <div className="ui__menu-background" onClick={menu.close} />}

      <SidebarBackground />
      <OrderSidebars />
      <UserSidebars />
      <VehicleTypeSidebars />
      <ParcelTypeSidebars />
      <VehicleSidebars />
      <BarcodeSidebars />
      <UserVehicleSidebars />
      <ReviewTagSidebars />
      <ReviewSidebars />
      <QuestionsAnswersSidebars />
      <VehicleFuelTypeSidebars />
    </div>
  );
});
