import { gql } from '@apollo/client';

export const GET_VEHICLE = gql`
  query getVehicle($input: GetVehicleInput!) {
    getVehicle(input: $input) {
      id
      title
      vehicleType {
        id
        fuelType {
          id
          titleEn
          titleDe
        }
        photoFileId
        titleEn
        titleDe
        shortDescriptionDe
        shortDescriptionEn
        longDescriptionDe
        longDescriptionEn
        journeyInc5MinPrice
        over2kmPerKmPrice
        pickUpOver5MinPrice
        pickUpOverTimeInSeconds
        stopoverPrice
        cancelPrice
        outsideServiceHoursPercent
        minCoastInc2kmPrice
        bootCapacityLiters
        orderLimit
        volumePerOrder
      }
    }
  }
`;
