import React, { memo } from 'react';
import { NOrder } from '../types';

interface PickUpTimeTypeBadgeProps {
  type: NOrder.PickUpTimeType;
}

export const PickUpTimeType = memo<PickUpTimeTypeBadgeProps>(({ type }) => {
  if (type === NOrder.PickUpTimeType.LATER) {
    return <span className="badge badge-soft-warning">Later</span>;
  }
  if (type === NOrder.PickUpTimeType.URGENT) {
    return <span className="badge badge-soft-danger">Urgent</span>;
  }

  return <span className="badge badge-soft-primary">Now</span>;
});
