import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { AssignVehicleSidebar } from './AssignVehicle';
import { CreateVehicleSidebar } from './CreateVehicle';
import { UpdateVehicleSidebar } from './UpdateVehicle';

export const VehicleSidebars = memo(() => (
  <>
    <FProvider>
      <CreateVehicleSidebar />
    </FProvider>
    <FProvider>
      <UpdateVehicleSidebar />
    </FProvider>
    <FProvider>
      <AssignVehicleSidebar />
    </FProvider>
  </>
));
