import compareArrays from './compareArrays';

export default (oldData, newData, ignore = []) => {
  let values = {};

  Object.keys(newData).forEach((key) => {
    const nData = newData[key];
    const oData = oldData[key];

    if (ignore.includes(key)) {
      values[key] = nData;
      return;
    }

    if (!oldData.hasOwnProperty(key)) {
      values[key] = nData;
      return;
    }

    if (Array.isArray(nData) && Array.isArray(oData)) {
      if (!compareArrays(nData, oData)) {
        values[key] = nData;
      }
    } else {
      if (nData !== oData) {
        values[key] = nData;
      }
    }
  });

  return values;
};
