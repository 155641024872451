import React, { memo } from 'react';
import { FInput } from '../../../../../../libs/FForm';
import FileUploader from '../../../../../../libs/FForm/components/FileUploader';
import { UIHSeparator } from '../../../../../../components/ui';
import { NUser } from '../../../../../../types';

interface FieldsProps {
  userRole: NUser.Role;
  loading: boolean;
  isCreating?: boolean;
  showDeleteButtonInFileUploader?: boolean;
}

export const UserFields = memo<FieldsProps>(
  ({ loading, userRole, isCreating = false, showDeleteButtonInFileUploader }) => {
    // const isCustomer = userRole === EUserRole.CUSTOMER;
    const isBusinessCustomer = userRole === NUser.Role.BUSINESS_CUSTOMER;
    const isDriver = userRole === NUser.Role.EMPLOYEE_DRIVER;
    const isFleetManager = userRole === NUser.Role.EMPLOYEE_FLEET_MANAGER;

    return (
      <>
        <FInput
          label="First name"
          name="firstName"
          placeholder="First name"
          required
          disabled={loading}
        />
        <FInput
          label="Last name"
          name="lastName"
          placeholder="Last name"
          required
          disabled={loading}
        />
        <FInput
          label="Phone number"
          name="phoneNumber"
          placeholder="+380661234567"
          required
          disabled={loading}
        />
        {isDriver && (
          <FInput
            label="Licence ID"
            name="licenceID"
            placeholder="1234567890"
            required
            disabled={loading}
          />
        )}
        <FInput
          label="Email"
          name="email"
          placeholder="example@gmail.com"
          required={true}
          disabled={loading}
        />

        {isCreating && isFleetManager && (
          <FInput
            label="Password"
            name="password"
            placeholder="password"
            required
            disabled={loading}
          />
        )}

        {isBusinessCustomer && (
          <>
            <UIHSeparator title="about company" />
            <FInput
              label="Company name"
              name="companyInfo.companyName"
              placeholder="Company name"
              required
              disabled={loading}
            />
            <FInput
              label="Company address"
              name="companyInfo.companyAddress"
              placeholder="Company address"
              required
              disabled={loading}
            />
            <UIHSeparator />
          </>
        )}

        <FileUploader
          label="Photo"
          name="photoFileId"
          showDeleteButton={!loading || showDeleteButtonInFileUploader}
          required={isDriver}
        />
      </>
    );
  },
);
