import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { FirstOrderPickUpWaypoint } from './FirstOrderPickUpWaypoint';
import { useSearchQuery } from '../../../../hooks';
import { ESearchQuery } from '../../../../constants/query.constant';
import { ESidebar, ESidebarPath } from '../../../../constants/sidebar.constant';
import { useFirstOrderPickUpWaypoints } from '../../../../queries';
import { NOrder } from '../../../../types';

export const FirstOrderPickUpWaypoints = memo(() => {
  const { firstOrderPickUpWaypoints } = useFirstOrderPickUpWaypoints();
  const { push } = useHistory();
  const query = useSearchQuery();
  const selectedOrderId = query.get(ESearchQuery.ORDER_ID);
  const sidebar = query.get(ESearchQuery.SIDEBAR);

  const handleClick = useCallback(
    (orderId: string) => () => {
      push({
        search: `${ESearchQuery.ORDER_ID}=${orderId}&${ESearchQuery.ORDER_ROUTE}=${orderId}&${ESidebarPath.ORDER}`, //${shiftId ? `&${ESearchQuery.SHIFT_ID}=${shiftId}` : ''}&
      });
    },
    [push],
  );

  return (
    <>
      {firstOrderPickUpWaypoints.map(({ id, orderId, ...props }) => (
        <FirstOrderPickUpWaypoint
          key={id}
          isSelectedExists={!!selectedOrderId}
          isSelected={orderId === selectedOrderId}
          pickUpTimeType={NOrder.PickUpTimeType.NOW} // order.pickUpTimeType
          shiftId={'none'} //order.shiftId
          onClick={handleClick(orderId)} //order.shiftId
          isShown={sidebar !== ESidebar.ATTACH_ORDER || orderId === selectedOrderId}
          id={id}
          orderId={orderId}
          {...props}
        />
      ))}
    </>
  );
});
