export default (fieldValues, values) => {
  const obj = { ...values };

  Object.entries(obj).forEach(([key, value]) => {
    if (fieldValues.includes(value)) {
      obj[key] = null;
    }
  });

  return obj;
};
