import { GET_ALL_ORDERS } from '../orders';
import { useMemo } from 'react';
import { GetAllOrdersOutput } from '../../types';
import { useAppQuery } from '../../apollo';

export const useOrders = () => {
  const { data, loading } = useAppQuery<GetAllOrdersOutput>(GET_ALL_ORDERS);

  const orders = useMemo(() => data?.getAllOrders ?? [], [data?.getAllOrders]);

  return { orders, loading };
};
