import React from 'react';
import Helmet from 'react-helmet';
import { memo } from 'react';
import { FProvider } from '../../../libs/FForm';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderSettingsPage } from './RenderSettingsPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const SettingsPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Settings')}</title>
    </Helmet>

    <UIBreadcrumbs title="Settings" />

    <FProvider>
      <RenderSettingsPage />
    </FProvider>
  </>
));
