import { gql } from '@apollo/client';

export const CREATE_MESSAGE = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      text
      createdAt
      user {
        id
        firstName
        lastName
        photoFileId
        userRole
      }
      readersSeen {
        userId
        hasSeen
      }
    }
  }
`;
