import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderBarcodesPage } from './RenderBarcodesPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const BarcodesPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Barcodes')}</title>
    </Helmet>

    <UIBreadcrumbs title="Barcodes" />

    <RenderBarcodesPage />
  </>
));
