export namespace NAnalytics {
  export enum TimePeriod {
    CURRENT_MONTH = 'CURRENT_MONTH',
    CURRENT_WEEK = 'CURRENT_WEEK',
    CURRENT_YEAR = 'CURRENT_YEAR',
  }

  export enum RevenuePerUnit {
    DAY = 'DAY',
    MONTH = 'MONTH',
    ORDER = 'ORDER',
    CUSTOMER = 'USER',
    WEEK = 'WEEK',
    YEAR = 'YEAR',
  }

  export enum OrdersPerUnit {
    CUSTOMER = 'CUSTOMER',
    DAY = 'DAY',
    DRIVER = 'DRIVER',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    YEAR = 'YEAR',
  }

  export enum LostOrdersReason {
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
    REJECTED = 'REJECTED',
  }

  export namespace Static {
    /* Query */
    export namespace GetForTotalOrders {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsStaticForTotalOrders: {
          currentPeriodTotalOrders: number;
          percentOrdersPreviousToCurrent: number | null;
        };
      }
    }

    /* Query */
    export namespace GetForTotalRevenue {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsStaticForTotalRevenue: {
          currentPeriodTotalRevenue: number;
          percentRevenuePreviousToCurrent: number | null;
        };
      }
    }

    /* Query */
    export namespace GetForLostOrdersExpectedRevenue {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsStaticForLostOrdersExpectedRevenue: {
          currentPeriodExpectedRevenue: number;
          percentExpectedRevenuePreviousToCurrent: number | null;
        };
      }
    }

    /* Query */
    export namespace GetForAverageRevenue {
      export interface Input {
        input: {
          unit: RevenuePerUnit;
        };
      }
      export interface Output {
        analyticsStaticForAverageRevenue: {
          averageRevenue: number;
        };
      }
    }

    /* Query */
    export namespace GetForAverageOrders {
      export interface Input {
        input: {
          unit: OrdersPerUnit;
        };
      }
      export interface Output {
        analyticsStaticForAverageOrders: {
          averageOrders: number;
        };
      }
    }

    /* Query */
    export namespace GetForLostOrdersByReasonExpectedRevenue {
      export interface Input {
        input: {
          reason: LostOrdersReason;
        };
      }
      export interface Output {
        analyticsStaticForLostOrdersByReasonExpectedRevenue: {
          expectedRevenue: number;
        };
      }
    }
  }

  export namespace Dynamic {
    /* Query */
    export namespace GetForCompletedVsLostOrders {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsDynamicForCompletedVsLostOrders: {
          dynamicCompletedVsLostOrdersStats: {
            period: string;
            completedOrders: number;
            lostOrders: number;
          }[];
        };
      }
    }

    /* Query */
    export namespace GetForRevenue {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsDynamicForRevenue: {
          dynamicRevenueStats: {
            period: string;
            revenue: number;
          }[];
        };
      }
    }

    /* Query */
    export namespace GetForLossesByReasonPeriod {
      export interface Input {
        input: {
          reason: LostOrdersReason;
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsDynamicForOrderLossesByReasonPeriod: {
          dynamicLossesByPeriods: {
            period: string;
            lossesByReason: number;
          }[];
        };
      }
    }

    /* Query */
    export namespace GetForLostOrdersByReasonPercent {
      export interface Input {
        input: {
          timePeriod: TimePeriod;
        };
      }
      export interface Output {
        analyticsLostOrdersByReasonPercent: {
          canceledOrdersPercent: number;
          rejectedOrdersPercent: number;
          expiredOrdersPercent: number;
        };
      }
    }
  }
}
