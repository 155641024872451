import { useSubscription } from '@apollo/client';
import { VEHICLE_TYPE_REMOVED_SUBSCRIPTION } from '../../../subscriptions';
import { NVehicleType } from '../../../types';
import { GET_ALL_VEHICLE_TYPES } from '../../../queries';

export const useVehicleTypeRemovedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NVehicleType.On.Removed.Output>(VEHICLE_TYPE_REMOVED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { vehicleTypeRemoved } = data;

      client.cache.updateQuery<NVehicleType.GetAll.Output>(
        { query: GET_ALL_VEHICLE_TYPES },
        (record) => {
          if (!record) return;

          return {
            vehicleTypes: record.vehicleTypes.filter(
              (vehicleType) => vehicleType.id !== vehicleTypeRemoved.id,
            ),
          };
        },
      );
    },
  });
};
