import { NWaypoint } from './waypoints';
import { NUser } from './users';
import { NVehicle } from './vehicles';
import { IOrderStartingWaypoint } from '../interfaces/waypoint.interface';

export namespace NOrder {
  export interface Entity {
    id: string;
    downTimeInSeconds: number;
    customer: Pick<
      NUser.Entity,
      'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'photoFileId'
    >;
    driver: Pick<
      NUser.Entity,
      'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'photoFileId' | 'rating'
    > | null;
    // orderDriverChat: NChat.Entity;
    // orderSupportChat: NChat.Entity;
    orderStatus: Status;
    attachType: AttachType;
    orderWaypointsLengthInKm: number | null;
    // payments: NPayment.Entity[];
    paymentStatus: PaymentStatus;
    pickUpTime: number;
    pickUpTimeType: PickUpTimeType;
    rejectionReason: string;
    uniqueCode: number;
    vehicle: NVehicle.Entity | null;
    waypoints: NWaypoint.Entity[];
    // review: NReview.Entity | null;
    orderSummaryFileId: string | null;
  }

  export enum Status {
    AVAILABLE = 'AVAILABLE',
    ACCEPTED = 'ACCEPTED',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
  }

  export enum AttachType {
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
    NONE = 'NONE',
  }

  export enum PickUpTimeType {
    LATER = 'LATER',
    NOW = 'NOW',
    URGENT = 'URGENT',
  }

  export enum PaymentStatus {
    UNCONFIRMED = 'UNCONFIRMED',
    UNCAPTURED = 'UNCAPTURED',
    REFUND = 'REFUND',
    PARTIAL_REFUND = 'PARTIAL_REFUND',
    PAID = 'PAID',
    UNCHARGED = 'UNCHARGED',
  }

  /* Query */
  export namespace GetOne {
    export interface Input {
      input: {
        id: string;
      };
    }
    export interface Output {
      order: Entity;
    }
  }

  /* Query */
  export namespace GetAll {
    export type Output = {
      getAllOrders: Entity[];
    };
  }

  /* Mutation */
  export namespace Attach {
    export interface Input {
      input: {
        orderId: string;
        userVehicleLinkId: string;
      };
    }
    export interface Output {
      attachOrder: boolean;
    }
  }

  /* Mutation */
  export namespace Reattach {
    export interface Input {
      input: {
        orderId: string;
        userVehicleLinkId: string;
      };
    }
    export interface Output {
      reattachOrder: boolean;
    }
  }

  /* Mutation */
  export namespace Reject {
    export interface Input {
      input: {
        id: string;
        rejectionReason: string;
      };
    }
    export interface Output {
      rejectOrder: boolean;
    }
  }

  /* Subscriptions */
  export namespace On {
    export namespace Added {
      export type Output = {
        orderAdded: Entity;
      };
    }

    export namespace Updated {
      export type Output = {
        orderUpdated: Pick<
          Entity,
          | 'id'
          | 'downTimeInSeconds'
          | 'orderStatus'
          | 'attachType'
          | 'orderWaypointsLengthInKm'
          | 'paymentStatus'
          | 'pickUpTime'
          | 'pickUpTimeType'
          | 'rejectionReason'
          | 'uniqueCode'
        >;
      };
    }

    // export namespace UpdatedPayments {
    //   export type Output = {
    //     orderUpdatedPayments: Pick<Entity, 'id' | 'payments'>;
    //   };
    // }

    export namespace UpdatedVehicle {
      export type Output = {
        orderUpdatedVehicle: Pick<Entity, 'id' | 'driver' | 'vehicle'>;
      };
    }
  }
}

/* Query: get first order pick up waypoints */
export interface GetOrderFirstPickUpWaypointsOutput {
  orderFirstPickUpWaypoints: IOrderStartingWaypoint[];
}

/* Query: get all orders */
export interface GetAllOrdersOutput {
  getAllOrders: {
    id: string;
    uniqueCode: number;
    orderStatus: NOrder.Status;
    pickUpTime: number;
    pickUpTimeType: NOrder.PickUpTimeType;
    attachType: NOrder.AttachType;
    paymentStatus: NOrder.PaymentStatus;
  }[];
}

/* Query: get order waypoints */
export interface GetOrderWaypointsInput {
  input: {
    orderId: string;
  };
}
export interface GetOrderWaypointsOutput {
  getOrderWaypoints: {
    id: string;
    lat: number;
    lng: number;
    waypointType: NWaypoint.Type;
  }[];
}
