import { NVehicleType } from './vehicle-types';
import { IEmployeeWithVehicle } from '../interfaces/employee-with-vehicle-output.interface';

export namespace NVehicle {
  export interface Entity {
    id: string;
    title: string;
    vehicleType: NVehicleType.Entity;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { getVehicle: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { getAllVehicles: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createVehicle: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Entity };
    export type Output = { updateVehicle: NVehicle.Entity };
  }

  /* Mutation */
  export namespace Delete {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { deleteVehicle: boolean };
  }

  /* Locations */
  export namespace Location {
    export interface Entity {
      userVehicleLinkId: string;
      lat: number | null;
      lng: number | null;
    }

    export namespace GetAll {
      export type Output = { getVehicleLocations: Entity[] };
    }

    /* WARNING: Incorrect subscription data returned */
    export namespace On {
      export namespace Add {
        export type Output = { addUserVehicleLocationSubscription: Entity };
      }

      export namespace Update {
        export type Output = {
          updateLocationSubscription: { id: string; lat: number; lng: number };
        };
      }

      export namespace Delete {
        export type Output = {
          deleteUserVehicleLocationSubscription: Pick<Entity, 'userVehicleLinkId'>;
        };
      }
    }
  }
}

/* Query: get vehicle available users */
export interface GetVehicleAvailableUsersOutput {
  getVehicleAvailableUsers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
}

/* Mutation: attach user */
export interface AttachUserInput {
  input: {
    userVehicleLinkId: string;
    userId: string | null;
  };
}
export interface AttachUserOutput {
  attachUser: IEmployeeWithVehicle['user'];
}
