import React, { memo, useMemo } from 'react';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useUserVehicleDetails } from '../../../../../../queries';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';
import {
  UIBreadcrumbs,
  UIContainer,
  UIContainerOfItems,
  UIHSeparator,
  UIOrder,
  UIUser,
} from '../../../../../../components/ui';
import { UIVehicle } from '../../../../../../components/ui/UIVehicle';

export const RenderUserVehicleSidebar = memo(() => {
  const searchQuery = useSearchQuery();
  const userVehicleId = useSearchParam(searchQuery, ESearchQuery.USER_VEHICLE_ID);

  const { userVehicle, loading, error } = useUserVehicleDetails({ id: userVehicleId });

  const favoriteOrderIds = useMemo(
    () => userVehicle?.favoriteOrders.map(({ id }) => id) ?? [],
    [userVehicle?.favoriteOrders],
  );

  if (loading) {
    return <DataIsFetching />;
  }

  if (!userVehicle) {
    return <NoData title={`No car data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="About the car" />

      <UIContainer style={{ padding: '0 12px' }}>
        <UIContainerOfItems>
          <UIUser {...userVehicle.user} />
          <UIVehicle {...userVehicle.vehicle} />
        </UIContainerOfItems>

        <UIHSeparator title="orders" />

        {userVehicle.orders.map((order) => (
          <UIOrder
            key={order.id}
            {...order}
            isInFavorites={favoriteOrderIds.includes(order.id)}
          />
        ))}
      </UIContainer>
    </>
  );
});
