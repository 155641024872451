import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateParcelTypeSidebar } from './CreateParcelType';
import { UpdateParcelTypeSidebar } from './UpdateParcelType';

export const ParcelTypeSidebars = memo(() => (
  <>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <CreateParcelTypeSidebar />
    </FProvider>
    <FProvider ignoreFields={['upload/photoFileId']}>
      <UpdateParcelTypeSidebar />
    </FProvider>
  </>
));
