export namespace NPayment {
  export interface Entity {
    expectedAmount: number;
    actualAmount: number;
    type: Type;
  }

  export enum Type {
    ORDER = 'ORDER',
    PLAIN = 'PLAIN',
  }
}
