import React, { memo, useMemo } from 'react';
import { useLayout, useLayoutMethods } from '../../context/Layout';
import classNames from 'classnames';
import { ERoutes } from '../../constants/route.constant';
import { NavLink } from 'react-router-dom';
import {
  SvgBarcode,
  SvgChats,
  SvgDashboard,
  SvgManWithCar,
  SvgOrders,
  SvgParcelTypes,
  SvgReviewTags,
  SvgReviews,
  SvgRoute,
  SvgSettings,
  SvgSignOut,
  SvgUsers,
  SvgVehicles,
  SvgVehicleTypes,
  SvgFAQ,
} from '../../assets/icons';
import { UIHSeparator } from './UIHSeparator';
import { useAuth } from '../../context/Auth';
import { useChats } from '../../queries';
import { NChat, NUser } from '../../types';
import { findUnreadMessages } from '../../utilities/findUnreadMessages';
import { UIBadge } from './UIBadge';

export const UIMenu = memo(() => {
  const { signOut, profile } = useAuth();
  const {
    menu: { isOpen },
  } = useLayout();
  const { menu } = useLayoutMethods();

  const { chats: orderSupportChats } = useChats({ type: NChat.Type.ORDER_SUPPORT });
  const { chats: employeeChats } = useChats({ type: NChat.Type.EMPLOYEE });

  const numberOfUnreadMessagesInSupportChats = useMemo(() => {
    return orderSupportChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.ORDER_SUPPORT) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [profile?.id, orderSupportChats]);

  const numberOfUnreadMessagesInEmployeeChats = useMemo(() => {
    return employeeChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.EMPLOYEE) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [employeeChats, profile?.id]);

  return (
    <div
      className={classNames('ui__menu', {
        ui__menu_opened: isOpen,
      })}
    >
      <NavLink
        to={ERoutes.DASHBOARD}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgDashboard />
        </div>
        <div className="ui__title">Dashboard</div>
      </NavLink>

      <UIHSeparator />

      <NavLink
        to={ERoutes.MAP}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgRoute />
        </div>
        <div className="ui__title">Map</div>
      </NavLink>

      <NavLink
        to={ERoutes.ORDERS}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgOrders />
        </div>
        <div className="ui__title">Orders</div>
      </NavLink>

      <NavLink
        to={ERoutes.CHATS}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgChats />
        </div>
        <div className="ui__title">Chats</div>
        <UIBadge
          value={
            numberOfUnreadMessagesInEmployeeChats + numberOfUnreadMessagesInSupportChats
          }
          position="right"
        />
      </NavLink>

      <NavLink
        to={ERoutes.REVIEWS}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgReviews />
        </div>
        <div className="ui__title">Reviews</div>
      </NavLink>

      <UIHSeparator />

      {profile?.userRole === NUser.Role.EMPLOYEE_ADMIN && (
        <NavLink
          to={ERoutes.FAQ}
          className="ui__menu-item"
          activeClassName="ui__menu-item_selected"
          onClick={menu.close}
        >
          <div className="ui__icon">
            <SvgFAQ />
          </div>
          <div className="ui__title">FAQ</div>
        </NavLink>
      )}

      <NavLink
        to={ERoutes.USERS}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgUsers />
        </div>
        <div className="ui__title">Users</div>
      </NavLink>

      <NavLink
        to={ERoutes.BARCODES}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgBarcode />
        </div>
        <div className="ui__title">Barcodes</div>
      </NavLink>

      <NavLink
        to={ERoutes.ASSIGNED_VEHICLES}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgManWithCar />
        </div>
        <div className="ui__title">Assigned Vehicles</div>
      </NavLink>

      <NavLink
        to={ERoutes.VEHICLES}
        className="ui__menu-item"
        activeClassName="ui__menu-item_selected"
        onClick={menu.close}
      >
        <div className="ui__icon">
          <SvgVehicles />
        </div>
        <div className="ui__title">Vehicles</div>
      </NavLink>

      {profile?.userRole === NUser.Role.EMPLOYEE_ADMIN && (
        <>
          <NavLink
            to={ERoutes.VEHICLE_TYPES}
            className="ui__menu-item"
            activeClassName="ui__menu-item_selected"
            onClick={menu.close}
          >
            <div className="ui__icon">
              <SvgVehicleTypes />
            </div>
            <div className="ui__title">Vehicle Types</div>
          </NavLink>

          <NavLink
            to={ERoutes.PARCEL_TYPES}
            className="ui__menu-item"
            activeClassName="ui__menu-item_selected"
            onClick={menu.close}
          >
            <div className="ui__icon">
              <SvgParcelTypes />
            </div>
            <div className="ui__title">Parcel Types</div>
          </NavLink>

          <NavLink
            to={ERoutes.REVIEW_TAGS}
            className="ui__menu-item"
            activeClassName="ui__menu-item_selected"
            onClick={menu.close}
          >
            <div className="ui__icon">
              <SvgReviewTags />
            </div>
            <div className="ui__title">Review Tags</div>
          </NavLink>

          <NavLink
            to={ERoutes.SETTINGS}
            className="ui__menu-item"
            activeClassName="ui__menu-item_selected"
            onClick={menu.close}
          >
            <div className="ui__icon">
              <SvgSettings />
            </div>
            <div className="ui__title">Settings</div>
          </NavLink>
        </>
      )}

      <UIHSeparator />

      <div
        className="ui__menu-item"
        onClick={() => {
          menu.close();
          signOut();
        }}
      >
        <div className="ui__icon">
          <SvgSignOut />
        </div>
        <div className="ui__title">Logout</div>
      </div>
    </div>
  );
});
