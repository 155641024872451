import { useSubscription } from '@apollo/client';
import { ADD_FIRST_ORDER_PICK_UP_WAYPOINT_SUBSCRIPTION } from '../../../subscriptions';
import {
  AddFirstOrderPickUpWaypointSubscriptionOutput,
  GetOrderFirstPickUpWaypointsOutput,
} from '../../../types';
import { GET_ORDER_FIRST_PICK_UP_WAYPOINTS } from '../../../queries';

export const useFirstOrderPickUpWaypointAddedSubscription = (tokenIsValid: boolean) => {
  useSubscription<AddFirstOrderPickUpWaypointSubscriptionOutput>(
    ADD_FIRST_ORDER_PICK_UP_WAYPOINT_SUBSCRIPTION,
    {
      skip: !tokenIsValid,
      shouldResubscribe: true,
      onData({ client, data: { data } }) {
        if (!data) return;

        const { addFirstOrderPickWaypointSubscription } = data;

        client.cache.updateQuery<GetOrderFirstPickUpWaypointsOutput>(
          { query: GET_ORDER_FIRST_PICK_UP_WAYPOINTS },
          (record) => {
            if (!record) return;

            return {
              orderFirstPickUpWaypoints: [
                ...(record?.orderFirstPickUpWaypoints ?? []),
                addFirstOrderPickWaypointSubscription,
              ],
            };
          },
        );
      },
    },
  );
};
