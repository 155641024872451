// Dev
// export const GOOGLE_API_KEY = 'AIzaSyC6X1ix7R-ZTTNM6xqCCrbF5Tbbf5GyzdQ';
//
// const DEV_API_HREF = 'http://35.157.169.173/api/v1/';
// const DEV_WS_HREF = 'ws://35.157.169.173/api/v1/';
//
// const PROD_API_HREF = 'http://35.157.169.173/api/v1/';
// const PROD_WS_HREF = 'ws://35.157.169.173/api/v1/';

// Prod
export const GOOGLE_API_KEY = 'AIzaSyA2bCoTwXVbqNHa4fk0wSF1pr1yNbj1pNs';

const DEV_API_HREF = 'https://api.capcorp-gmbh.com/v1';
const DEV_WS_HREF = 'wss://api.capcorp-gmbh.com/v1';

const PROD_API_HREF = 'https://api.capcorp-gmbh.com/v1';
const PROD_WS_HREF = 'wss://api.capcorp-gmbh.com/v1';

const isDev = process.env.NODE_ENV === 'development';

export const API_URL = isDev ? DEV_API_HREF : PROD_API_HREF;
export const SUBSCRIPTION_URL = isDev ? DEV_WS_HREF : PROD_WS_HREF;
export const FILES_URL = `${API_URL}/files`;
export const UPLOAD_AVATAR_URL = `${FILES_URL}/upload/avatar`;
export const UPLOAD_VEHICLE_TYPE_PHOTO_URL = `${FILES_URL}/upload/image`;
