import { gql } from '@apollo/client';

export const GET_ANALYTICS_DYNAMIC_FOR_COMPLETED_VS_LOST_ORDERS = gql`
  query analyticsDynamicForCompletedVsLostOrders($input: GetDynamicInput!) {
    analyticsDynamicForCompletedVsLostOrders(input: $input) {
      dynamicCompletedVsLostOrdersStats {
        period
        completedOrders
        lostOrders
      }
    }
  }
`;
