import React, { memo } from 'react';
import { UIParcel } from './UIParcel';
import { UIHSeparator } from './UIHSeparator';
import { UIDownloadFile } from './UIDownloadFile';
import { NWaypoint } from '../../types';

interface UIWaypointProps
  extends Pick<
    NWaypoint.Entity,
    | 'waypointType'
    | 'waypointStatus'
    | 'address'
    | 'contactName'
    | 'contactPhoneNumber'
    | 'comment'
    | 'parcels'
    | 'deliveryConfirmationFileId'
  > {
  orderUniqueCode: number;
}

export const UIWaypoint = memo<UIWaypointProps>(
  ({
    waypointType,
    address,
    contactName,
    contactPhoneNumber,
    comment,
    parcels,
    deliveryConfirmationFileId,
    orderUniqueCode,
  }) => {
    return (
      <div className="item waypoint field-group">
        {waypointType === NWaypoint.Type.PICK_UP && (
          <span className="badge badge-soft-secondary font-size-12 field-group__badge">
            Pick-up
          </span>
        )}
        {waypointType === NWaypoint.Type.DROP_OFF && (
          <span className="badge badge-soft-secondary font-size-12 field-group__badge">
            Drop-off
          </span>
        )}
        <div className="field">
          <span className="field__title">Address:</span>
          <span className="field__value">{address}</span>
        </div>
        <div className="field">
          <span className="field__title">Contact name:</span>
          <span className="field__value">{contactName}</span>
        </div>
        <div className="field">
          <span className="field__title">Contact phone number:</span>
          <span className="field__value">{contactPhoneNumber}</span>
        </div>
        <div className="field">
          <span className="field__title">Comment:</span>
          <span className="field__value">{comment}</span>
        </div>

        <UIHSeparator title="parcels" />

        {parcels.map((parcel) => (
          <UIParcel key={parcel.id} {...parcel} />
        ))}

        {deliveryConfirmationFileId && (
          <>
            <UIHSeparator title="files" />

            <UIDownloadFile
              fileId={deliveryConfirmationFileId}
              filename={`Delivery confirmation (Order #${orderUniqueCode}).pdf`}
            />
          </>
        )}
      </div>
    );
  },
);
