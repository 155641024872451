import { gql } from '@apollo/client';

export const GET_ATTACH_USER_VEHICLES = gql`
  query getAttachUserVehicles($input: GetAttachUserVehicleInput!) {
    getAttachUserVehicles(input: $input) {
      userVehicleLinkId
      user {
        firstName
        lastName
      }
      vehicle {
        title
      }
      vehicleType {
        titleEn
        titleDe
      }
    }
  }
`;
