import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSearchQuery } from '../../../../hooks';
import { ESearchQuery } from '../../../../constants/query.constant';
import { ERoutes } from '../../../../constants/route.constant';
import classNames from 'classnames';
import { useLayoutMethods } from '../../../../context/Layout';

export const SidebarBackground = memo(() => {
  const location = useLocation();
  const searchQuery = useSearchQuery();
  const { enableBodyScrolling, disableBodyScrolling } = useLayoutMethods();
  const sidebar = searchQuery.get(ESearchQuery.SIDEBAR);

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    sidebar ? disableBodyScrolling() : enableBodyScrolling();
  }, [disableBodyScrolling, enableBodyScrolling, sidebar]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (sidebar) {
      ref.current.style.display = 'block';
    }
  }, [sidebar, ref]);

  const handleAnimationEnd = useCallback(() => {
    if (!sidebar) {
      if (ref.current) {
        ref.current.style.display = 'none';
      }
    }
  }, [sidebar]);

  if (location.pathname !== ERoutes.MAP) {
    return (
      <Link
        ref={ref}
        to={`${location.pathname}`}
        className={classNames('sidebar__background', {
          'animation_fade-in': sidebar,
          'animation_fade-out': !sidebar,
        })}
        onAnimationEnd={handleAnimationEnd}
      />
    );
  }

  return null;
});
