import { useMemo } from 'react';
import { NReview } from '../../types';
import { GET_REVIEW } from '../reviews';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NReview.GetOne.Output, NReview.GetOne.Input> {
  id: string | null | undefined;
}

export const useReview = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NReview.GetOne.Output,
    NReview.GetOne.Input
  >(GET_REVIEW, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const review = useMemo(() => data?.review ?? null, [data?.review]);

  return { review, loading, error };
};
