export namespace NBarcode {
  export interface Entity {
    id: string;
    isUsed: boolean;
    uniqueCode: string;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { getBarcode: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { getAllBarcodes: Entity[] };
  }

  /* Mutation */
  export namespace CreateMany {
    export type Input = { input: { count: number } };
    export type Output = { createBarcodes: Entity[] };
  }

  /* Subscriptions */
  export namespace On {
    export namespace CreateMany {
      export type Output = { barcodes: { create: Entity[] | null } };
    }

    export namespace Update {
      export type Output = { barcodes: { update: Entity | null } };
    }
  }
}
