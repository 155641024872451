import React, { memo, useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import profile from '../../../assets/images/profile-img.png';
import logo from '../../../assets/images/logo.svg';
import { useAuth } from '../../../context/Auth';
import { EIss } from '../../../constants/iss.constant';
import { UserRoles } from './components';
import { SIGN_IN } from '../../../mutations';
import { NAuth, NUser } from '../../../types';
import { pageTitle } from '../../../utilities/pageTitle';

export const SignInPage = memo(() => {
  const { signIn } = useAuth();

  const [userRole, setUserRole] = useState<NUser.RoleAvailableForLogin>(
    NUser.RoleAvailableForLogin.EMPLOYEE_ADMIN,
  );

  const [singIn, { loading, error }] = useMutation<
    NAuth.SignIn.Output,
    NAuth.SignIn.Input
  >(SIGN_IN, {
    onCompleted: ({ signInWithEmailAndPassword }) => {
      void signIn(signInWithEmailAndPassword);
    },
    onError(err) {
      console.error(err.message);
    },
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = useCallback(() => {
    void singIn({
      variables: {
        input: {
          email,
          password,
          userRole,
          iss: EIss.API_WEB,
          deviceType: 'WEB',
          fcmToken: 'fcmToken',
        },
      },
    });
  }, [email, password, singIn, userRole]);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Login')}</title>
      </Helmet>

      <div className="flex-grow-1 d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to CapCorp.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle"
                          style={{ background: '#556ee6' }}
                        >
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <UserRoles selected={userRole} onClick={setUserRole} />

                  <div className="p-2">
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label>Password</label>
                      <input
                        name="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        required
                      />
                    </div>

                    {error ? (
                      <Alert color="danger">{JSON.stringify(error, null, 2)}</Alert>
                    ) : null}

                    <div className="mb-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={handleSignIn}
                      >
                        {loading ? (
                          <div className="spinner-border spinner-border-sm text-light" />
                        ) : (
                          'Login'
                        )}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
});
