import { useSubscription } from '@apollo/client';
import { CREATE_PARCEL_TYPE_SUBSCRIPTION } from '../../../subscriptions';
import { NParcelType } from '../../../types';
import { GET_ALL_PARCEL_TYPES } from '../../../queries';

export const useParcelTypeAddedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NParcelType.On.Create.Output>(CREATE_PARCEL_TYPE_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { create } = data.parcelTypes;

      if (!create) {
        return;
      }

      client.cache.updateQuery<NParcelType.GetAll.Output>(
        { query: GET_ALL_PARCEL_TYPES },
        (record) => {
          if (!record) return;

          return {
            getAllParcelTypes: [...record.getAllParcelTypes, create],
          };
        },
      );
    },
  });
};
