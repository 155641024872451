import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { UIBreadcrumbs } from '../../../components/ui';
import { Chart, registerables } from 'chart.js';
import {
  DashboardCardLostOrders,
  DashboardCardTotalOrders,
  DashboardCardTotalRevenue,
  DashboardCardAverageRevenue,
  DashboardCardAverageNumberOfOrders,
  DashboardCardLostOrdersByReason,
  DashboardChartTotalRevenue,
  DashboardChartCompletedOrdersVsLostOrders,
  DashboardChartLostOrdersByReason,
  DashboardChartAverageNumberOfLostOrdersByReason,
} from './components';

Chart.register(...registerables);

export const RenderDashboardPage = memo(() => (
  <Container fluid>
    <UIBreadcrumbs title="Totals" />

    <Row>
      <Col sm="6" lg="4">
        <DashboardCardTotalRevenue />
      </Col>
      <Col sm="6" lg="4">
        <DashboardCardTotalOrders />
      </Col>
      <Col sm="6" lg="4">
        <DashboardCardLostOrders />
      </Col>
    </Row>

    <UIBreadcrumbs title="Averages" />

    <Row>
      <Col sm="6" lg="4">
        <DashboardCardAverageRevenue />
      </Col>
      <Col sm="6" lg="4">
        <DashboardCardAverageNumberOfOrders />
      </Col>
      <Col sm="6" lg="4">
        <DashboardCardLostOrdersByReason />
      </Col>
    </Row>

    <Row>
      <Col md="6">
        <DashboardChartTotalRevenue />
      </Col>
      <Col md="6">
        <DashboardChartCompletedOrdersVsLostOrders />
      </Col>
    </Row>

    <Row>
      <Col md="6">
        <DashboardChartLostOrdersByReason />
      </Col>

      <Col md="6">
        <DashboardChartAverageNumberOfLostOrdersByReason />
      </Col>
    </Row>
  </Container>
));
