import { useMemo } from 'react';
import { NReviewTag } from '../../types';
import { GET_REVIEW_TAG } from '../review-tags';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<NReviewTag.GetOne.Output, NReviewTag.GetOne.Input> {
  id: string | null | undefined;
}

export const useReviewTag = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NReviewTag.GetOne.Output,
    NReviewTag.GetOne.Input
  >(GET_REVIEW_TAG, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const reviewTag = useMemo(() => data?.reviewTag ?? null, [data?.reviewTag]);

  return { reviewTag, loading, error };
};
