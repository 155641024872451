import { useMemo } from 'react';
import { NReview } from '../../types';
import { GET_ALL_REVIEWS } from '../reviews';
import { useAppQuery } from '../../apollo';

export const useReviews = () => {
  const { data, loading } = useAppQuery<NReview.GetAll.Output>(GET_ALL_REVIEWS);

  const reviews = useMemo(() => data?.reviews ?? [], [data?.reviews]);

  return { reviews, loading };
};
