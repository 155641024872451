import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUpdateVehicleTypeSidebar } from './RenderUpdateVehicleTypeSidebar';

export const UpdateVehicleTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.UPDATE_VEHICLE_TYPE} onClose={handleClear} width={450}>
      <RenderUpdateVehicleTypeSidebar />
    </Sidebar>
  );
});
