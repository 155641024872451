import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderAssignedVehiclesPage } from './RenderAssignedVehiclesPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const AssignedVehiclesPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Assigned Vehicles')}</title>
    </Helmet>

    <UIBreadcrumbs title="Assigned Vehicles" />

    <RenderAssignedVehiclesPage />
  </>
));
