import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      email
      firstName
      lastName
      isVerifyEmail
      isVerifyPhoneNumber
      phoneNumber
      photoFileId
      licenceID
      userRole
      rating
      isBlocked
    }
  }
`;
