import { useMemo } from 'react';
import { GET_USER } from '../users';
import { NUser } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NUser.GetOne.Output, NUser.GetOne.Input> {
  id: string | null | undefined;
}

export const useUser = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<NUser.GetOne.Output, NUser.GetOne.Input>(
    GET_USER,
    {
      skip: !id,
      variables: {
        input: {
          id: id ?? '',
        },
      },
      nextFetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const user = useMemo(() => data?.getUser ?? null, [data?.getUser]);

  return { user, loading, error };
};
