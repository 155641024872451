import { gql } from '@apollo/client';

export const GET_VEHICLE_AVAILABLE_USERS = gql`
  query {
    getVehicleAvailableUsers {
      id
      firstName
      lastName
    }
  }
`;
