import React, { CSSProperties, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface UIContainerProps {
  style?: CSSProperties;
  className?: string;
  row?: boolean;
  centered?: boolean;
}

export const UIContainer = memo<PropsWithChildren<UIContainerProps>>(
  ({ children, style, className, row, centered }) => (
    <div
      className={classNames('ui__container', className, {
        ui_direction_row: row,
        ui_centered_row:
          centered !== undefined
            ? row !== undefined
              ? row && centered
              : centered
            : false,
      })}
      style={style}
    >
      {children}
    </div>
  ),
);
