import { useMemo } from 'react';
import { GET_ORDER } from '../orders';
import { NOrder } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params extends AppQueryOptions<NOrder.GetOne.Output, NOrder.GetOne.Input> {
  orderId: string;
}

export const useOrderWaypoints = ({ orderId, ...options }: Params) => {
  const { data, loading } = useAppQuery<NOrder.GetOne.Output, NOrder.GetOne.Input>(
    GET_ORDER,
    {
      skip: !orderId,
      fetchPolicy: 'cache-only',
      variables: {
        input: {
          id: orderId,
        },
      },
      ...options,
    },
  );

  const waypoints = useMemo(() => data?.order.waypoints ?? [], [data?.order.waypoints]);

  return { waypoints, loading };
};
