import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { FInput, useHandleSubmit, useSetValues } from '../../../../../../libs/FForm';
import { GET_ALL_VEHICLES, useVehicle } from '../../../../../../queries';
import { NVehicle } from '../../../../../../types';
import { UPDATE_VEHICLE } from '../../../../../../mutations';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';

export const RenderUpdateVehicleSidebar = memo(() => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const vehicleId = useSearchParam(searchQuery, ESearchQuery.VEHICLE_ID);
  const handleSubmit = useHandleSubmit();
  const setValues = useSetValues();

  const {
    vehicle,
    loading: isFetching,
    error,
  } = useVehicle({
    id: vehicleId,
    onCompleted({ getVehicle }) {
      setValues(getVehicle);
    },
  });

  const [updateVehicle, { loading }] = useMutation<
    NVehicle.Update.Output,
    NVehicle.Update.Input
  >(UPDATE_VEHICLE, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NVehicle.GetAll.Output>({ query: GET_ALL_VEHICLES }, (record) => {
        if (!record) return;

        return {
          getAllVehicles: record.getAllVehicles.map((vehicle) => {
            if (vehicle.id === vehicleId) {
              return data.updateVehicle;
            }
            return vehicle;
          }),
        };
      });
    },
    onCompleted() {
      toast('Vehicle was successfully updated!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when updating the vehicle): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: Omit<NVehicle.Update.Input['input'], 'id'>) => {
      if (vehicleId) {
        void updateVehicle({
          variables: {
            input: {
              id: vehicleId,
              ...values,
            },
          },
        });
      }
    },
    [updateVehicle, vehicleId],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isFetching) {
    return <DataIsFetching />;
  }

  if (!vehicle) {
    return <NoData title={`No vehicle data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title="update vehicle" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <FInput
          label="Title"
          name="title"
          placeholder="Title"
          required
          disabled={loading}
        />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
