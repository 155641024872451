import { gql } from '@apollo/client';

export const ORDER_UPDATED_PAYMENTS_SUBSCRIPTION = gql`
  subscription {
    orderUpdatedPayments {
      id
      payments {
        actualAmount
        type
      }
    }
  }
`;
