import { useSubscription } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { NOrder } from '../../../types';
import { ERoutes } from '../../../constants/route.constant';
import { ESearchQuery } from '../../../constants/query.constant';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { GET_ALL_ORDERS } from '../../../queries';
import { ORDER_UPDATED_SUBSCRIPTION } from '../../../subscriptions';

export const useOrderUpdatedSubscription = (tokenIsValid: boolean) => {
  const { push } = useHistory();

  useSubscription<NOrder.On.Updated.Output>(ORDER_UPDATED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const {
        orderUpdated: { id, orderStatus, uniqueCode, attachType },
      } = data;

      if (orderStatus === NOrder.Status.CANCELED) {
        toast(<b>Order #{uniqueCode} was canceled</b>, {
          type: 'error',
          autoClose: 10000,
          onClick() {
            push(
              `${ERoutes.MAP}?${ESearchQuery.ORDER_ID}=${id}&${ESearchQuery.ORDER_ROUTE}=${id}&${ESidebarPath.ORDER}`,
            );
          },
        });
      } else if (attachType === NOrder.AttachType.MANUAL) {
        const allOrders =
          client.cache.readQuery<NOrder.GetAll.Output>({ query: GET_ALL_ORDERS })
            ?.getAllOrders ?? [];

        const order = allOrders.find((order) => order.id === id);

        if (order?.attachType !== NOrder.AttachType.MANUAL) {
          toast(<b>Order #{uniqueCode} must be assigned</b>, {
            type: 'error',
            autoClose: 10000,
            onClick() {
              push(
                `${ERoutes.MAP}?${ESearchQuery.ORDER_ID}=${id}&${ESearchQuery.ORDER_ROUTE}=${id}&${ESidebarPath.ORDER}`,
              );
            },
          });
        }
      }

      client.cache.updateQuery<NOrder.GetAll.Output>(
        { query: GET_ALL_ORDERS },
        (record) => {
          if (!record) return;

          return {
            getAllOrders: record.getAllOrders.map((order) => {
              if (order.id === id) {
                return {
                  ...order,
                  ...data.orderUpdated,
                };
              }
              return order;
            }),
          };
        },
      );
    },
  });
};
