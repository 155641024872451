import React, { createContext, useCallback, useRef, useState } from 'react';
import { pickUpIdentity, flattenObject, bloatObject } from './utils/index';
import replaceFieldsToNull from './utils/replaceFieldsToNull';

type ValuesOfRefProps = any;

interface FProviderProps {
  getOnlyModifiedData?: boolean;
  getInAnyCaseTheFields?: string[];
  ignoreFields?: string[];
}

interface RefGetters {
  [key: string]: () => any;
}

interface RefSetters {
  [key: string]: (value: ValuesOfRefProps) => void;
}

interface RefValidators {
  [key: string]: () => boolean;
}

interface RefSpecifiedValues {
  [key: string]: ValuesOfRefProps;
}

interface RefDefaultValues {
  [key: string]: ValuesOfRefProps;
}

export interface FContextProps {
  setValues(values: { [key: string]: any }, saveAsSpecifiedValue?: boolean): void;
  setSetter(
    name: string,
    callback: (value: ValuesOfRefProps) => void,
    defaultValue: any,
  ): void;
  setGetter(name: string, callback: () => any): void;
  setValidator(name: string, callback: () => any): void;
  handleSubmit(
    callback: (
      values: any,
      options: {
        isChanged: boolean;
      },
    ) => void,
  ): () => void;
  // handleClearValues(): void;
  handleClear(): void;
  handleReset(): void;
  unmountCallback(name: string): void;
  submitted: boolean;
}

const FContext = createContext<FContextProps>({
  setValues: () => {},
  setSetter: () => {},
  setGetter: () => {},
  setValidator: () => {},
  handleSubmit: () => () => {},
  // handleClearValues: () => {},
  handleClear: () => {},
  handleReset: () => {},
  unmountCallback: () => {},
  submitted: false,
});

const FProvider: React.FC<FProviderProps> = ({
  children,
  getOnlyModifiedData,
  getInAnyCaseTheFields,
  ignoreFields,
}) => {
  const setters = useRef<RefSetters>({});
  const getters = useRef<RefGetters>({});
  const validators = useRef<RefValidators>({});
  const specifiedValues = useRef<RefSpecifiedValues>({});
  const defaultValues = useRef<RefDefaultValues>({});
  const [submitted, setSubmitted] = useState(false);
  const cache = useRef<RefSpecifiedValues>({});
  // const [validated, setValidated] = useState(false);

  const setValues = useCallback((values, saveAsSpecifiedValue = true) => {
    Object.entries(flattenObject(values)).forEach(([key, value]) => {
      if (setters.current.hasOwnProperty(key)) {
        if (value !== null) {
          setters.current[key](value);
        } else {
          // console.log(`The value with the '${key}' key is NULL`);
        }
        if (saveAsSpecifiedValue) {
          specifiedValues.current[key] = value;
        }
      }
      cache.current[key] = value;
    });
  }, []);

  const getValues = useCallback(() => {
    const values: { [key: string]: any } = {};

    Object.entries(getters.current).forEach(([key, getter]) => {
      if (!ignoreFields || !ignoreFields.includes(key)) {
        values[key] = getter();
      }
    });

    return replaceFieldsToNull([undefined, ''], values);
  }, [ignoreFields]);

  const isChanged = useCallback(() => {
    return !!Object.keys(pickUpIdentity(specifiedValues.current, getValues())).length;
  }, [getValues]);

  const setSetter = useCallback((name, callback, defaultValue) => {
    setters.current[name] = callback;
    defaultValues.current[name] = defaultValue;

    if (cache.current[name] !== undefined && cache.current[name] !== null) {
      callback(cache.current[name]);
      delete cache.current[name];
    }
  }, []);

  const setGetter = useCallback((name, callback) => {
    getters.current[name] = callback;
  }, []);

  const setValidator = useCallback((name, callback) => {
    validators.current[name] = callback;
  }, []);

  const handleSubmit = useCallback(
    (callback) => () => {
      const isValid = Object.values(validators.current).every((validator) => validator());

      if (isValid) {
        // setValidated(true);

        let values = getValues();

        const options = {
          isChanged: isChanged(),
        };

        if (getOnlyModifiedData) {
          values = pickUpIdentity(specifiedValues.current, values, getInAnyCaseTheFields);
        }

        callback(bloatObject(values), options);
        return options.isChanged;
      }

      setSubmitted(true);
      return false;
    },
    [getInAnyCaseTheFields, getOnlyModifiedData, getValues, isChanged],
  );

  const handleReset = useCallback(() => {
    Object.entries(getters.current).forEach(([key, getter]) => {
      let defaultValue = defaultValues.current[key];

      if (defaultValue === undefined) {
        const value = getter();

        if (typeof value === 'string') {
          defaultValue = '';
        } else if (typeof value === 'number') {
          defaultValue = '0';
        } else if (typeof value === 'boolean') {
          defaultValue = false;
        } else if (Array.isArray(value)) {
          defaultValue = [];
        }
      }

      setters.current[key](defaultValue);
    });

    specifiedValues.current = {};

    setSubmitted(false);
    // setValidated(false);
  }, []);

  const handleClear = useCallback(() => {
    handleReset();

    getters.current = {};
    setters.current = {};
    validators.current = {};
    specifiedValues.current = {};
    defaultValues.current = {};
    cache.current = {};
  }, [handleReset]);

  const unmountCallback = useCallback((name) => {
    delete getters.current[name];
    delete setters.current[name];
    delete validators.current[name];
    delete specifiedValues.current[name];
  }, []);

  const value = {
    setValues,
    setSetter,
    setGetter,
    setValidator,
    handleSubmit,
    handleClear,
    handleReset,
    unmountCallback,
    submitted,
  };

  return <FContext.Provider value={value}>{children}</FContext.Provider>;
};

FProvider.defaultProps = {
  getOnlyModifiedData: false,
  getInAnyCaseTheFields: [],
};

export { FContext, FProvider };
