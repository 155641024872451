import React, { createContext, memo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../constants';

export const GlobalMapContext = createContext<null>(null);

export const GlobalMapProvider = memo(({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    language: 'en',
  });

  return (
    <GlobalMapContext.Provider value={null}>
      {isLoaded && children}
    </GlobalMapContext.Provider>
  );
});
