import React, { memo } from 'react';
import { NUser } from '../../../../types';

interface UserRolesProps {
  selected: NUser.RoleAvailableForLogin;
  onClick: (role: NUser.RoleAvailableForLogin) => void;
}

export const UserRoles = memo<UserRolesProps>(({ selected, onClick }) => (
  <div
    className="mb-3 btn-group w-100 d-flex flex-row border border-light rounded-2"
    role="group"
    aria-label="Roles"
  >
    <button
      type="button"
      className={`w-100 btn ${
        selected === NUser.RoleAvailableForLogin.EMPLOYEE_ADMIN
          ? 'btn-primary'
          : 'btn-default'
      }`}
      onClick={() => onClick(NUser.RoleAvailableForLogin.EMPLOYEE_ADMIN)}
    >
      Admin
    </button>
    <button
      type="button"
      className={`w-100 btn ${
        selected === NUser.RoleAvailableForLogin.EMPLOYEE_FLEET_MANAGER
          ? 'btn-primary'
          : 'btn-default'
      }`}
      onClick={() => onClick(NUser.RoleAvailableForLogin.EMPLOYEE_FLEET_MANAGER)}
    >
      Fleet Manager
    </button>
  </div>
));
