import React, { memo } from 'react';
import { useChats } from '../../../../../../../../queries';
import { NChat } from '../../../../../../../../types';
import { useAuth } from '../../../../../../../../context/Auth';
import { UIScrollableContainer } from '../../../../../../../../components/ui';
import { Chat } from './Chat';

interface ChatTabProps {
  type: NChat.Type;
}

export const ChatTab = memo<ChatTabProps>(({ type }) => {
  const { profile } = useAuth();

  const { chats, loading } = useChats({ type });

  return (
    <>
      {loading && (
        <div
          style={{ position: 'absolute', left: 0, top: 56, right: 0 }}
          className="d-flex justify-content-center"
        >
          <span className="spinner-border spinner-border-sm text-primary" />
        </div>
      )}

      <UIScrollableContainer>
        <div className="chat-list">
          {chats.map((chat) => (
            <Chat key={chat.id} {...chat} profileId={profile?.id} />
          ))}
        </div>
      </UIScrollableContainer>
    </>
  );
});
