import React, { memo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import './assets/scss/theme.scss';
import './animations.scss';
import './main.scss';

import { NonAuthLayout, VerticalLayout } from './layouts';
import { authRoutes, userRoutes } from './routes';
import { AuthRoute, ProtectedRoute } from './routes/middleware';
import { ERoutes } from './constants/route.constant';
import { SubscriptionListener } from './listeners/SubscriptionListener';

import './utilities/app-height-listener';

export const App = memo(() => (
  <>
    <Router>
      <ToastContainer
        position="top-right"
        newestOnTop
        style={{ zIndex: 10010 }}
        autoClose={1000}
      />
      <SubscriptionListener />

      <Switch>
        {authRoutes.map(({ path, component: Component }, idx) => (
          <AuthRoute key={idx} path={path} exact>
            <NonAuthLayout>
              <Component />
            </NonAuthLayout>
          </AuthRoute>
        ))}

        {userRoutes.map(({ path, component: Component }, idx) => (
          <ProtectedRoute key={idx} path={path} exact>
            <VerticalLayout>
              <Component />
            </VerticalLayout>
          </ProtectedRoute>
        ))}

        {/* This routes should be at the end of all other routes */}
        <Route path={ERoutes.ERROR_404} component={() => <>Code 404</>} />
        <Route path="/" component={() => <Redirect to={ERoutes.MAP} />} />
        <Route
          path="*"
          exact={false}
          component={() => <Redirect to={ERoutes.ERROR_404} />}
        />
      </Switch>
    </Router>
  </>
));
