import { useMemo } from 'react';
import { GET_USER_VEHICLE_WAYPOINTS } from '../user-vehicles';
import { GetUserVehicleWaypointsInput, GetUserVehicleWaypointsOutput } from '../../types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<GetUserVehicleWaypointsOutput, GetUserVehicleWaypointsInput> {
  id: string;
}

export const useUserVehicleWaypoints = ({ id, ...options }: Params) => {
  const { data, loading } = useAppQuery<
    GetUserVehicleWaypointsOutput,
    GetUserVehicleWaypointsInput
  >(GET_USER_VEHICLE_WAYPOINTS, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    ...options,
  });

  const waypoints = useMemo(
    () => data?.userVehicle?.waypoints ?? [],
    [data?.userVehicle?.waypoints],
  );

  return { waypoints, loading };
};
