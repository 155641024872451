import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Chart } from 'chart.js';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_DYNAMIC_LOST_ORDERS_BY_REASON_PERCENT } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardChartLostOrdersByReason = memo(() => {
  const cnvRef = useRef<HTMLCanvasElement>(null);

  const [timePeriod, setTimePeriod] = useState(NAnalytics.TimePeriod.CURRENT_WEEK);

  const { data } = useQuery<
    NAnalytics.Dynamic.GetForLostOrdersByReasonPercent.Output,
    NAnalytics.Dynamic.GetForLostOrdersByReasonPercent.Input
  >(GET_ANALYTICS_DYNAMIC_LOST_ORDERS_BY_REASON_PERCENT, {
    variables: {
      input: {
        timePeriod,
      },
    },
  });

  useEffect(() => {
    if (!cnvRef.current || !data?.analyticsLostOrdersByReasonPercent) return;

    const { canceledOrdersPercent, rejectedOrdersPercent, expiredOrdersPercent } =
      data.analyticsLostOrdersByReasonPercent;

    const chart = new Chart(cnvRef.current, {
      type: 'pie',
      data: {
        labels: ['Canceled', 'Rejected', 'Expired'],
        datasets: [
          {
            data: [canceledOrdersPercent, rejectedOrdersPercent, expiredOrdersPercent],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(153, 102, 255)',
            ],
            hoverOffset: 4,
          },
        ],
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data?.analyticsLostOrdersByReasonPercent]);

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as NAnalytics.TimePeriod);
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>Lost Orders by Reason</CardTitle>

        <div className="d-flex">
          <select className="dashboard-select text-black" onChange={handleChange}>
            <option value={NAnalytics.TimePeriod.CURRENT_WEEK}>this week</option>
            <option value={NAnalytics.TimePeriod.CURRENT_MONTH}>this month</option>
            <option value={NAnalytics.TimePeriod.CURRENT_YEAR}>this year</option>
          </select>
          <span className="dashboard-select-icon fill-black">
            <SvgExpandMore />
          </span>
        </div>

        <div className="dashboard-pie-chart">
          <div className="dashboard-pie-chart__container">
            <canvas ref={cnvRef} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
});
