import React, { memo, useMemo } from 'react';
import { useSearchQuery } from '../../../../hooks';
import { OrderRoute } from './OrderRoute';
import { ESearchQuery } from '../../../../constants/query.constant';

export const OrderRoutes = memo(() => {
  const searchQuery = useSearchQuery();
  const routeOrderIds = useMemo(
    () => searchQuery.getAll(ESearchQuery.ORDER_ROUTE),
    [searchQuery],
  );

  return (
    <>
      {routeOrderIds.map((id: string) => (
        <OrderRoute key={id} orderId={id} />
      ))}
    </>
  );
});
