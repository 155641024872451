import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderReviewSidebar } from './RenderReviewSidebar';

export const ReviewSidebar = memo(() => (
  <Sidebar name={ESidebar.REVIEW} width={450}>
    <RenderReviewSidebar />
  </Sidebar>
));
