import { gql } from '@apollo/client';

export const REVIEW_ADDED_SUBSCRIPTION = gql`
  subscription {
    reviewAdded {
      id
      safety
      service
      recommendation
      reviewTags
      comment
      rating {
        score
      }
      customer {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
      }
      driver {
        id
        firstName
        lastName
        phoneNumber
        photoFileId
      }
      order {
        id
        uniqueCode
        orderStatus
        pickUpTime
        pickUpTimeType
        attachType
        paymentStatus
      }
    }
  }
`;
