import React, { useCallback, useState } from 'react';
import useConnector from '../hooks/useConnector';
import { useValidator } from '../hooks';
import classes from 'classnames';

interface IFSelectProps {
  name: string;
  data: any[];
  label?: string;
  extractor?: (item: any) => [string, string, string];
  getter?: (value: string) => any;
  defaultValue?: string;
  onChangeValue?: (value: string) => void;
  required?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
}

const FSelect: React.FC<IFSelectProps> = ({
  label = '',
  name,
  data = [],
  extractor = (item) => [item.id, item.id, item.title],
  getter = (value) => value,
  defaultValue = '',
  onChangeValue = () => {},
  required = false,
  errorMessage = '',
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleSetValue = useCallback(
    (value) => {
      setValue(value);
      onChangeValue(value);
    },
    [onChangeValue],
  );

  useConnector(name, getter(value), handleSetValue, defaultValue);

  const isValid = useValidator(name, () => {
    if (required) return value !== '';
    return true;
  });

  const handleChangeValue = useCallback(
    (e) => {
      handleSetValue(e.target.value);
    },
    [handleSetValue],
  );

  return (
    <div
      className={classes({
        'mb-3 w-100': true,
        'text-danger': !isValid,
      })}
    >
      {label && (
        <label className={'form-label'}>
          {label}{' '}
          {required ? (
            <span className="text-danger">*</span>
          ) : (
            <span className="text-secondary">(optional)</span>
          )}
        </label>
      )}
      <select
        className={classes({ 'form-select': true, 'is-invalid': !isValid })}
        value={value}
        onChange={handleChangeValue}
        placeholder={placeholder}
        {...props}
      >
        <option value="">Choose...</option>
        {data.map((item) => {
          const [key, value, title] = extractor(item);

          return (
            <option key={key} value={value}>
              {title}
            </option>
          );
        })}
      </select>
      {!isValid && (
        <div className="form-text text-danger fw-bold">
          {errorMessage || 'Please select one of the options'}
        </div>
      )}
    </div>
  );
};

export default FSelect;
