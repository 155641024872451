import React, { memo } from 'react';
import classNames from 'classnames';
import { NChat } from '../../../../../../../../types';
import { SvgMessageSent } from '../../../../../../../../assets/icons/SvgMessageSent';
import { SvgMessageViewed } from '../../../../../../../../assets/icons/SvgMessageViewed';

type MessageProps = Omit<NChat.Message.Entity, 'chat'> & { profileId: string };

export const Message = memo<MessageProps>(
  ({ createdAt, user, readersSeen, text, profileId }) => {
    const date = new Date(createdAt);
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');

    const isOwn = profileId === user.id;
    const isSeen = readersSeen.find(
      ({ userId, hasSeen }) => userId !== user.id && hasSeen,
    );

    return (
      <div
        className={classNames('message', {
          message_side_left: !isOwn,
          message_side_right: isOwn,
        })}
      >
        <div className="message__user-name">
          {user.firstName} {user.lastName}
        </div>
        <div className="message__text-content">{text}</div>
        <div className="message__footer">
          <span className="message__time">
            {hours}:{minutes}
          </span>
          <div className="message__status_sending">
            {isSeen ? <SvgMessageViewed /> : <SvgMessageSent />}
          </div>
        </div>
      </div>
    );
  },
);
