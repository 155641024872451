import React, { memo } from 'react';
import { NUser } from '../types';

export const UserRoleBadge = memo<{ role: NUser.Role }>(({ role }) => {
  if (role === NUser.Role.CUSTOMER) {
    return <span className="badge badge-soft-info"> Customer</span>;
  }
  if (role === NUser.Role.BUSINESS_CUSTOMER) {
    return <span className="badge badge-soft-pink">Business Customer</span>;
  }
  if (role === NUser.Role.EMPLOYEE_DRIVER) {
    return <span className="badge badge-soft-success">Driver</span>;
  }
  if (role === NUser.Role.EMPLOYEE_FLEET_MANAGER) {
    return <span className="badge badge-soft-warning">Fleet Manager</span>;
  }
  if (role === NUser.Role.EMPLOYEE_ADMIN) {
    return <span className="badge badge-soft-danger">Super Admin</span>;
  }
  return <>{role}</>;
});
