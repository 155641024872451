import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { NVehicleType } from '../../../../../../types';
import { CREATE_VEHICLE_TYPE } from '../../../../../../mutations';
import { VehicleTypeFields } from '../components/VehicleTypeFields';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';

export const RenderCreateVehicleTypeSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();

  const [createVehicleType, { loading }] = useMutation<
    NVehicleType.Create.Output,
    NVehicleType.Create.Input
  >(CREATE_VEHICLE_TYPE, {
    onCompleted() {
      toast('Vehicle type was successfully created!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when creating a vehicle type): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NVehicleType.Create.Input['input']) => {
      void createVehicleType({
        variables: {
          input: values,
        },
      });
    },
    [createVehicleType],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new vehicle type" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <VehicleTypeFields loading={loading} />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
