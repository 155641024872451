import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

type UIContainerOfItemsProps = { children?: ReactNode | undefined; selected?: boolean };

export const UIContainerOfItems = memo<UIContainerOfItemsProps>(
  ({ children, selected }) => (
    <div className={classNames('container-of-items', { selected })}>{children}</div>
  ),
);
