import { gql } from '@apollo/client';

export const GET_ALL_BARCODES = gql`
  query {
    getAllBarcodes {
      id
      isUsed
      uniqueCode
    }
  }
`;
