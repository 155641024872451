import { gql } from '@apollo/client';

export const GET_VEHICLE_FUEL_TYPE = gql`
  query vehicleFuelType($input: GetVehicleFuelTypeInput!) {
    vehicleFuelType(input: $input) {
      id
      titleEn
      titleDe
    }
  }
`;
