import { gql } from '@apollo/client';

export const ADD_FIRST_ORDER_PICK_UP_WAYPOINT_SUBSCRIPTION = gql`
  subscription {
    addFirstOrderPickWaypointSubscription {
      id
      lat
      lng
      orderId
      waypointType
    }
  }
`;
