import React, { memo, useCallback, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router';
import { useOrders } from '../../../queries';
import { ESearchQuery } from '../../../constants/query.constant';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { PickUpTimeType } from '../../../components/PickUpTimeTypeBadge';
import { OrderStatusBadge } from '../../../components/OrderStatusBadge';
import { PaymentStatusBadge } from '../../../components/PaymentStatusBadge';
import { parseDate } from '../../../utilities/parse-date';
import { NOrder } from '../../../types';

export const RenderOrdersPage = memo(() => {
  const history = useHistory();
  const { orders } = useOrders();

  const tableData = orders;

  const onRowClick = useCallback(
    (e, row: NOrder.Entity) => {
      if (e.target) {
        let currentNode: HTMLDivElement = e.target;

        if (currentNode.nodeName !== 'TD') {
          while (currentNode?.parentNode && currentNode.parentNode.nodeName !== 'TD') {
            currentNode = currentNode.parentNode as HTMLDivElement;
          }
          if (currentNode.dataset.hasOwnProperty('unclickable')) {
            return;
          }
        }
        history.push({
          search: `${ESearchQuery.ORDER_ID}=${row.id}&${ESidebarPath.ORDER}`,
        });
      }
    },
    [history],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'pickUpTime',
        order: 'desc',
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

const columns: ColumnDescription[] = [
  {
    dataField: 'uniqueCode',
    text: '#',
    sort: true,
  },
  {
    dataField: 'pickUpTimeType',
    text: 'Pick-up time type',
    sort: true,
    formatter(_, row) {
      return <PickUpTimeType type={row.pickUpTimeType} />;
    },
  },
  {
    dataField: 'pickUpTime',
    text: 'Pick-up time',
    sort: true,
    formatter(_, row) {
      return <>{parseDate(row.pickUpTime)}</>;
    },
  },
  {
    dataField: 'paymentStatus',
    text: 'Payment Status',
    sort: true,
    formatter(_, row) {
      return <PaymentStatusBadge paymentStatus={row.paymentStatus} />;
    },
  },
  {
    dataField: 'orderStatus',
    text: 'Order Status',
    sort: true,
    formatter(_, row) {
      return <OrderStatusBadge orderStatus={row.orderStatus} />;
    },
  },
];
