import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { DELETE_USER } from '../../../../mutations';
import { NUser } from '../../../../types';
import { GET_ALL_USERS } from '../../../../queries';
import { toast } from 'react-toastify';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);

  const [deleteUser, { loading }] = useMutation<NUser.Delete.Output, NUser.Delete.Input>(
    DELETE_USER,
    {
      update(cache, { data: response }) {
        if (!response) return;

        cache.updateQuery<NUser.GetAll.Output>({ query: GET_ALL_USERS }, (record) => {
          if (!record) return;

          return {
            getAllUsers: record.getAllUsers.filter((user) => user.id !== id),
          };
        });
      },
      onCompleted() {
        toast('User was successfully deleted!', {
          type: 'success',
        });
      },
      onError(err) {
        toast(`Error (when deleting a user): ${err.message}`, {
          type: 'error',
          autoClose: 2000,
        });
      },
    },
  );

  const handlePress = useCallback(() => {
    if (!unlock) {
      setUnlock(true);
    } else {
      void deleteUser({ variables: { input: { id } } });
    }
  }, [deleteUser, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center ms-1"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
