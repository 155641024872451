import { gql } from '@apollo/client';

export const GET_ALL_PARCEL_TYPES = gql`
  query {
    getAllParcelTypes {
      id
      title
      designation
      description
      height
      length
      width
      maxWeightInGrams
    }
  }
`;
