import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderCreateVehicleSidebar } from './RenderCreateVehicleSidebar';

export const CreateVehicleSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.CREATE_VEHICLE} onClose={handleClear}>
      <RenderCreateVehicleSidebar />
    </Sidebar>
  );
});
