import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHandleSubmit } from '../../../../../../libs/FForm';
import { CREATE_BARCODES } from '../../../../../../mutations';
import { FInput } from '../../../../../../libs/FForm/components';
import { UIBreadcrumbs, UIContainer } from '../../../../../../components/ui';
import { NBarcode } from '../../../../../../types';

export const RenderCreateBarcodeSidebar = memo(() => {
  const history = useHistory();
  const handleSubmit = useHandleSubmit();

  const [createBarcodes, { loading }] = useMutation<
    NBarcode.CreateMany.Output,
    NBarcode.CreateMany.Input
  >(CREATE_BARCODES, {
    onCompleted() {
      toast('Barcodes successfully created!', {
        type: 'success',
      });
      history.goBack();
    },
    onError(err) {
      toast(`Error (when creating barcodes): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handleValidSubmit = useCallback(
    (values: NBarcode.CreateMany.Input['input']) => {
      void createBarcodes({
        variables: {
          input: values,
        },
      });
    },
    [createBarcodes],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <UIBreadcrumbs title="new barcodes" />

      <UIContainer style={{ padding: '0 24px 24px 24px' }}>
        <FInput
          label="Number of barcodes"
          type="number"
          name="count"
          placeholder="Number of barcodes"
          required
          disabled={loading}
        />

        <div>
          <Button
            className="me-3"
            onClick={handleSubmit(handleValidSubmit)}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
          <Button variant="outline-light" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </UIContainer>
    </>
  );
});
