import { gql } from '@apollo/client';

export const VEHICLE_TYPE_ADDED_SUBSCRIPTION = gql`
  subscription {
    vehicleTypeAdded {
      id
      fuelType {
        id
        titleDe
        titleEn
      }
      photoFileId
      titleEn
      titleDe
      shortDescriptionDe
      shortDescriptionEn
      longDescriptionDe
      longDescriptionEn
      journeyInc5MinPrice
      over2kmPerKmPrice
      pickUpOver5MinPrice
      pickUpOverTimeInSeconds
      stopoverPrice
      cancelPrice
      outsideServiceHoursPercent
      minCoastInc2kmPrice
      bootCapacityLiters
      orderLimit
      volumePerOrder
    }
  }
`;
