export namespace NUser {
  export interface Entity {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isVerifyEmail: boolean;
    isVerifyPhoneNumber: boolean;
    phoneNumber: string;
    photoFileId: string | null;
    licenceID: string | null;
    userRole: Role;
    isBlocked: boolean;
    companyInfo: {
      companyName: string;
      companyAddress: string;
    } | null;
    rating: number | null;
  }

  export enum Role {
    BUSINESS_CUSTOMER = 'BUSINESS_CUSTOMER',
    CUSTOMER = 'CUSTOMER',
    EMPLOYEE_ADMIN = 'EMPLOYEE_ADMIN',
    EMPLOYEE_DRIVER = 'EMPLOYEE_DRIVER',
    EMPLOYEE_FLEET_MANAGER = 'EMPLOYEE_FLEET_MANAGER',
  }

  export enum RoleAvailableForLogin {
    EMPLOYEE_ADMIN = 'EMPLOYEE_ADMIN',
    EMPLOYEE_FLEET_MANAGER = 'EMPLOYEE_FLEET_MANAGER',
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { getUser: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { getAllUsers: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createUser: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Pick<Entity, 'id'> & Partial<Entity> };
    export type Output = { users: { update: Entity } };
  }

  /* Mutation */
  export namespace Delete {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { users: { delete: boolean } };
  }

  /* Mutation */
  export namespace Block {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { users: { block: boolean } };
  }

  /* Mutation */
  export namespace Unblock {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { users: { unblock: boolean } };
  }

  /* Subscriptions */
  export namespace On {
    export namespace Update {
      export type Output = { users: { update: NUser.Entity | null } };
    }
  }
}
