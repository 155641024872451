import { gql } from '@apollo/client';

export const ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription {
    orderUpdated {
      id
      downTimeInSeconds
      orderStatus
      attachType
      orderWaypointsLengthInKm
      paymentStatus
      pickUpTime
      pickUpTimeType
      rejectionReason
      uniqueCode
    }
  }
`;
