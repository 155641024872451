import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderBarcodeSidebar } from './RenderBarcodeSidebar';

export const BarcodeSidebar = memo(() => (
  <Sidebar name={ESidebar.BARCODE}>
    <RenderBarcodeSidebar />
  </Sidebar>
));
