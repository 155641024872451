import { useSubscription } from '@apollo/client';
import { NOrder } from '../../../types';
import { GET_ALL_ORDERS, GET_CHATS } from '../../../queries';
import { ORDER_ADDED_SUBSCRIPTION } from '../../../subscriptions';

export const useOrderAddedSubscription = (tokenIsValid: boolean) => {
  useSubscription<NOrder.On.Added.Output>(ORDER_ADDED_SUBSCRIPTION, {
    skip: !tokenIsValid,
    shouldResubscribe: true,
    onData({ client, data: { data } }) {
      if (!data) return;

      const { orderAdded } = data;

      client.cache.updateQuery<NOrder.GetAll.Output>(
        { query: GET_ALL_ORDERS },
        (record) => {
          if (!record) return;

          return {
            getAllOrders: [orderAdded, ...record.getAllOrders],
          };
        },
      );

      void client.refetchQueries({ include: [GET_CHATS] });
    },
  });
};
