export default (values) => {
  const obj = {};

  // TODO: do it with recursion
  Object.entries(values).forEach(([key, value]) => {
    const keys = key.split('.');

    if (keys.length === 2) {
      if (obj[keys[0]] === undefined) {
        obj[keys[0]] = {};
      }
      obj[keys[0]][keys[1]] = value;
    } else {
      obj[keys[0]] = value;
    }
  });

  return obj;
};
