import { useMemo } from 'react';
import { GetUserVehicleDetailsInput, GetUserVehicleDetailsOutput } from '../../types';
import { GET_USER_VEHICLE_DETAILS } from '../user-vehicles';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<GetUserVehicleDetailsOutput, GetUserVehicleDetailsInput> {
  id: string | null | undefined;
}

export const useUserVehicleDetails = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    GetUserVehicleDetailsOutput,
    GetUserVehicleDetailsInput
  >(GET_USER_VEHICLE_DETAILS, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const userVehicle = useMemo(() => data?.userVehicle ?? null, [data?.userVehicle]);

  return { userVehicle, loading, error };
};
