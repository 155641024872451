import React, { createContext, memo, useCallback, useMemo, useState } from 'react';

interface LayoutMethodsContextProps {
  menu: {
    toggle(): void;
    open(): void;
    close(): void;
  };
  disableBodyScrolling(): void;
  enableBodyScrolling(): void;
}

interface LayoutContextProps {
  menu: {
    isOpen: boolean;
  };
}

export const LayoutContext = createContext<LayoutContextProps | null>(null);
export const LayoutMethodsContext = createContext<LayoutMethodsContextProps | null>(null);

export const LayoutProvider = memo(({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const state = useMemo(() => {
    return {
      menu: {
        isOpen: menuIsOpen,
      },
    };
  }, [menuIsOpen]);

  const disableBodyScrolling = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const enableBodyScrolling = useCallback(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const methods = useMemo<LayoutMethodsContextProps>(() => {
    return {
      menu: {
        toggle: () => {
          setMenuIsOpen((prev) => {
            if (prev) enableBodyScrolling();
            else disableBodyScrolling();

            return !prev;
          });
        },
        open: () => {
          disableBodyScrolling();
          setMenuIsOpen(true);
        },
        close: () => {
          setMenuIsOpen(false);
          enableBodyScrolling();
        },
      },
      enableBodyScrolling,
      disableBodyScrolling,
    };
  }, [disableBodyScrolling, enableBodyScrolling]);

  return (
    <LayoutMethodsContext.Provider value={methods}>
      <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
    </LayoutMethodsContext.Provider>
  );
});
