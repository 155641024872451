import { gql } from '@apollo/client';

export const GET_ANALYTICS_STATIC_FOR_TOTAL_REVENUE = gql`
  query analyticsStaticForTotalRevenue($input: GetStaticForTotalsInput!) {
    analyticsStaticForTotalRevenue(input: $input) {
      currentPeriodTotalRevenue
      percentRevenuePreviousToCurrent
    }
  }
`;
