export namespace NReviewTag {
  export interface Entity {
    id: string;
    name: string;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { reviewTag: Entity | null };
  }

  /* Query */
  export namespace GetAll {
    export type Output = {
      reviewTags: Entity[];
    };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createReviewTag: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Pick<Entity, 'id'> & Partial<Entity> };
    export type Output = { updateReviewTag: Entity | null };
  }

  /* Mutation */
  export namespace Delete {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { deleteReviewTag: Entity | null };
  }
}
