import React, { memo, useCallback, useMemo } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { ProtectedImage } from '../../../components/ProtectedImage';
import { ESidebarPath } from '../../../constants/sidebar.constant';
import { useUsers } from '../../../queries';
import { ESearchQuery } from '../../../constants/query.constant';
import { DeleteButton } from './components';
import { useAuth } from '../../../context/Auth';
import { UserRoleBadge } from '../../../components/UserRoleBadge';
import { BlockButton } from './components/BlockButton';
import { NUser } from '../../../types';
import { SvgStar } from '../../../assets/icons';

export const RenderUsersPage = memo(() => {
  const { profile } = useAuth();
  const history = useHistory();
  const { users } = useUsers();

  const tableData = users;

  const onCreateNew = useCallback(() => {
    history.push({
      search: `${ESidebarPath.CREATE_USER}`,
    });
  }, [history]);

  const onRowClick = useCallback(
    (e, row: NUser.Entity) => {
      if (e.target) {
        let currentNode: HTMLDivElement = e.target;

        if (currentNode.nodeName !== 'TD') {
          while (currentNode?.parentNode && currentNode.parentNode.nodeName !== 'TD') {
            currentNode = currentNode.parentNode as HTMLDivElement;
          }
          if (currentNode.dataset.hasOwnProperty('unclickable')) {
            return;
          }
        }

        if (row.userRole === NUser.Role.EMPLOYEE_ADMIN) {
          return;
        }

        if (row.userRole === NUser.Role.EMPLOYEE_FLEET_MANAGER) {
          if (profile?.userRole !== NUser.Role.EMPLOYEE_ADMIN) {
            return;
          }
        }

        history.push({
          search: `${ESearchQuery.USER_ID}=${row.id}&${ESidebarPath.UPDATE_USER}`,
        });
      }
    },
    [history, profile?.userRole],
  );

  const columns = useMemo<ColumnDescription[]>(
    () => [
      {
        dataField: 'userRole',
        text: 'Role',
        sort: true,
        formatter: (_, row: NUser.Entity) => {
          const { userRole } = row;

          return <UserRoleBadge role={userRole} />;
        },
      },
      {
        dataField: 'rating',
        text: 'Rating',
        sort: true,
        formatter: (_, row: NUser.Entity) => {
          if (!row.rating) return <></>;

          return (
            <div className="d-flex align-items-center">
              {row.rating.toFixed(2)}
              <div style={{ width: 20, height: 20, marginLeft: '4px' }}>
                <SvgStar fill={row.rating > 2 ? '#FFC84E' : '#f46a6a'} />
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'firstName',
        text: 'Name',
        sort: true,
        formatter: (_, row: NUser.Entity) => {
          return (
            <>
              {row.firstName} {row.lastName}
            </>
          );
        },
      },
      {
        dataField: 'photoFileId',
        text: 'Photo',
        sort: true,
        formatter: (cellContent: any, row: NUser.Entity) => {
          return (
            <ProtectedImage
              id={row.photoFileId}
              style={{ borderRadius: '4px', width: 50, height: 50 }}
            />
          );
        },
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'phoneNumber',
        text: 'Phone number',
        sort: true,
      },
      {
        dataField: 'menu',
        isDummyField: true,
        text: 'Action',
        headerStyle: {
          width: '50px',
        },
        formatter: (cellContent: any, row: NUser.Entity) => {
          if (row.userRole === NUser.Role.EMPLOYEE_ADMIN) return <></>;

          if (profile?.userRole === NUser.Role.EMPLOYEE_ADMIN) {
            return (
              <div className="d-flex" style={{ height: '36px' }} data-unclickable="">
                <BlockButton id={row.id} isBlocked={row.isBlocked} />
                <DeleteButton id={row.id} />
              </div>
            );
          }

          if (profile?.userRole === NUser.Role.EMPLOYEE_FLEET_MANAGER) {
            if ([NUser.Role.EMPLOYEE_DRIVER].includes(row.userRole)) {
              return (
                <div className="d-flex" style={{ height: '36px' }} data-unclickable="">
                  <BlockButton id={row.id} isBlocked={row.isBlocked} />
                  <DeleteButton id={row.id} />
                </div>
              );
            }
          }
          return <></>;
        },
      },
    ],
    [profile],
  );

  const pageOptions = useMemo(
    () => ({
      sizePerPage: tableData.length,
      totalSize: tableData.length,
      custom: true,
      columns,
      data: tableData,
    }),
    [columns, tableData],
  );

  const defaultSorted: [{ dataField: any; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: 'id',
        order: 'desc',
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <Search.SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={onCreateNew}
                              >
                                <i className="mdi mdi-plus me-1" />
                                New user
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                rowEvents={{ onClick: onRowClick }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});
