import { useMemo } from 'react';
import { GET_ALL_PARCEL_TYPES } from '../parcel-types';
import { NParcelType } from '../../types';
import { useAppQuery } from '../../apollo';

export const useParcelTypes = () => {
  const { data, loading } = useAppQuery<NParcelType.GetAll.Output>(GET_ALL_PARCEL_TYPES);

  const parcelTypes = useMemo(
    () => data?.getAllParcelTypes ?? [],
    [data?.getAllParcelTypes],
  );

  return { parcelTypes, loading };
};
