import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { NQuestionAnswer } from '../../../../types';
import { GET_ALL_QUESTIONS_ANSWERS } from '../../../../queries';
import { REMOVE_QUESTION_ANSWER } from '../../../../mutations/questions-answers';

interface DeleteButtonProps {
  id: string;
}

export const DeleteButton = memo<DeleteButtonProps>(({ id }) => {
  const [unlock, setUnlock] = useState(false);

  const [removeQuestionAnswer, { loading }] = useMutation<
    NQuestionAnswer.Remove.Output,
    NQuestionAnswer.Remove.Input
  >(REMOVE_QUESTION_ANSWER, {
    update(cache, { data }) {
      if (!data) return;

      cache.updateQuery<NQuestionAnswer.GetAll.Output>(
        { query: GET_ALL_QUESTIONS_ANSWERS },
        (record) => {
          if (!record) return;

          return {
            questionsAnswers: record.questionsAnswers.filter(
              (questionAnswer) => questionAnswer.id !== id,
            ),
          };
        },
      );
    },
    onCompleted() {
      toast('Review tag was successfully deleted!', {
        type: 'success',
      });
    },
    onError(err) {
      toast(`Error (when deleting a review tag): ${err.message}`, {
        type: 'error',
        autoClose: 2000,
      });
    },
  });

  const handlePress = useCallback(() => {
    if (!unlock) {
      setUnlock(true);
    } else {
      void removeQuestionAnswer({ variables: { input: { id } } });
    }
  }, [removeQuestionAnswer, id, unlock]);

  return (
    <Button
      color="danger"
      className="d-flex align-items-center justify-content-center ms-1"
      style={{ height: '36px', width: '36px', borderRadius: '18px' }}
      onClick={handlePress}
      disabled={loading}
    >
      {unlock ? (
        <i className="fas fa-check text-white" />
      ) : (
        <i className="fas fa-trash text-white" />
      )}
    </Button>
  );
});
