import { useMemo } from 'react';
import { GET_VEHICLE_AVAILABLE_USERS } from '../vehicles';
import { GetVehicleAvailableUsersOutput } from '../../types';
import { useAppQuery } from '../../apollo';

export const useAvailableVehicleUsers = () => {
  const { data, loading, error } = useAppQuery<GetVehicleAvailableUsersOutput>(
    GET_VEHICLE_AVAILABLE_USERS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const drivers = useMemo(
    () => data?.getVehicleAvailableUsers ?? [],
    [data?.getVehicleAvailableUsers],
  );

  return { drivers, loading, error };
};
