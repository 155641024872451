import React, { memo } from 'react';
import { Marker } from '@react-google-maps/api';
import { SSvgCar } from '../../../../assets/icons';
import { Colors } from '../../../../constants/color.constant';
import { NVehicle } from '../../../../types';

interface VehicleLocationProps extends NVehicle.Location.Entity {
  isSelectedExists: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export const VehicleLocation = memo<VehicleLocationProps>(
  ({ userVehicleLinkId, lat, lng, isSelected, isSelectedExists, onClick }) => {
    if (lat === null || lng === null) {
      return null;
    }

    return (
      <Marker
        key={userVehicleLinkId}
        position={{ lat, lng }}
        opacity={isSelectedExists && !isSelected ? 0.7 : 1}
        icon={SSvgCar({ fill: isSelected ? Colors.SELECTED_VEHICLE : Colors.VEHICLE })}
        onClick={onClick}
      />
    );
  },
);
