import React, { memo } from 'react';
import { Sidebar } from '../../../Sidebar';
import { useHandleClear } from '../../../../../../libs/FForm';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUpdateFuelTypeSidebar } from './RenderUpdateFuelTypeSidebar';

export const UpdateFuelTypeSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.UPDATE_VEHICLE_FUEL_TYPE} onClose={handleClear}>
      <RenderUpdateFuelTypeSidebar />
    </Sidebar>
  );
});
