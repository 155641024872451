export namespace NVehicleFuelType {
  export interface Entity {
    id: string;
    titleEn: string;
    titleDe: string;
  }

  /* Query */
  export namespace GetOne {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { vehicleFuelType: Entity };
  }

  /* Query */
  export namespace GetAll {
    export type Output = { vehicleFuelTypes: Entity[] };
  }

  /* Mutation */
  export namespace Create {
    export type Input = { input: Omit<Entity, 'id'> };
    export type Output = { createVehicleFuelType: Entity };
  }

  /* Mutation */
  export namespace Update {
    export type Input = { input: Entity };
    export type Output = { updateVehicleFuelType: Entity };
  }

  /* Mutation */
  export namespace Remove {
    export type Input = { input: Pick<Entity, 'id'> };
    export type Output = { removeVehicleFuelType: Pick<Entity, 'id'> };
  }
}
