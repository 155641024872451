import { gql } from '@apollo/client';

export const CREATE_USER_VEHICLE_USER_LINK_SUBSCRIPTION = gql`
  subscription {
    createUserVehicleLinkSubscription {
      id
      vehicle {
        id
        title
        vehicleTypeId
      }
    }
  }
`;
