import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { useSearchParam, useSearchQuery } from '../../../../../../hooks';
import { ESearchQuery } from '../../../../../../constants/query.constant';
import { useOrder, useAttachUserVehicles } from '../../../../../../queries';
import { ESidebarPath } from '../../../../../../constants/sidebar.constant';
import { DataIsFetching } from '../../../../../../components/DataIsFetching';
import { NoData } from '../../../../../../components/NoData';
import {
  UIBreadcrumbs,
  UIContainer,
  UIHSeparator,
  UIUser,
} from '../../../../../../components/ui';
import { OrderStatusBadge } from '../../../../../../components/OrderStatusBadge';
import { UIWaypoint } from '../../../../../../components/ui';
import { ERoutes } from '../../../../../../constants/route.constant';
import { ConfirmOrderRejectionModal, ConfirmOrderRejectionModalRef } from './components';
import { UIDownloadFile } from '../../../../../../components/ui/UIDownloadFile';
import { NOrder } from '../../../../../../types';

export const RenderOrderSidebar = memo(() => {
  const { push, location } = useHistory();
  const searchQuery = useSearchQuery();

  const orderId = useSearchParam(searchQuery, ESearchQuery.ORDER_ID);

  const confirmOrderRejectionModalRef = useRef<ConfirmOrderRejectionModalRef>(null);

  const { order, loading, error } = useOrder({
    id: orderId,
  });

  const { attachUserVehicles } = useAttachUserVehicles({ orderId });

  const handleAttachOrderToUserVehicle = useCallback(() => {
    const query = attachUserVehicles
      .map(
        ({ userVehicleLinkId }) =>
          `${ESearchQuery.USER_VEHICLE_ROUTE}=${userVehicleLinkId}`,
      )
      .join('&');

    const search = `${ESearchQuery.ORDER_ID}=${orderId}&${ESearchQuery.ORDER_ROUTE}=${orderId}&${ESidebarPath.ATTACH_ORDER}&${query}`;

    push({ search });
  }, [attachUserVehicles, orderId, push]);

  const handleReattachOrderToUserVehicle = useCallback(() => {
    const query = attachUserVehicles
      .map(
        ({ userVehicleLinkId }) =>
          `${ESearchQuery.USER_VEHICLE_ROUTE}=${userVehicleLinkId}`,
      )
      .join('&');

    const search = `${ESearchQuery.ORDER_ID}=${orderId}&${ESearchQuery.ORDER_ROUTE}=${orderId}&${ESidebarPath.REATTACH_ORDER}&${query}`;
    push({ search });
  }, [attachUserVehicles, orderId, push]);

  useEffect(() => {
    if (!order || location.pathname !== ERoutes.MAP) return;

    if (
      [
        NOrder.Status.COMPLETED,
        NOrder.Status.CANCELED,
        NOrder.Status.REJECTED,
        NOrder.Status.EXPIRED,
      ].includes(order.orderStatus)
    ) {
      const searchParams = new URLSearchParams(searchQuery);
      searchParams.delete(ESearchQuery.ORDER_ROUTE);
      push({ search: searchParams.toString() });
    }
  }, [location.pathname, order, push, searchQuery]);

  if (loading) {
    return <DataIsFetching />;
  }

  if (!order) {
    return <NoData title={`No order data: ${error?.message}`} />;
  }

  return (
    <>
      <UIBreadcrumbs title={`Order #${order.uniqueCode}`} />

      <UIContainer style={{ padding: '0 12px 24px 12px' }}>
        <div className="field" style={{ marginLeft: '12px' }}>
          <span className="field__title">Status:</span>
          <span className="field__value">
            <OrderStatusBadge orderStatus={order.orderStatus} />
          </span>
        </div>

        {order.orderStatus === NOrder.Status.REJECTED && (
          <div className="field" style={{ marginLeft: '12px' }}>
            <span className="field__title">Rejection reason:</span>
            <span className="field__value">{order.rejectionReason}</span>
          </div>
        )}

        {order.downTimeInSeconds !== null && (
          <div className="field" style={{ marginLeft: '12px' }}>
            <span className="field__title">Downtime (s):</span>
            <span className="field__value">{order.downTimeInSeconds.toFixed(2)}</span>
          </div>
        )}

        <UIHSeparator title="created by" />

        <UIUser {...order.customer} />

        {order.driver && (
          <>
            <UIHSeparator title="accepted by" />
            <UIUser {...order.driver} />
          </>
        )}

        {order.orderSummaryFileId && (
          <>
            <UIHSeparator title="files" />

            <UIDownloadFile
              fileId={order.orderSummaryFileId}
              filename={`Order summary #${order.uniqueCode}.pdf`}
            />
          </>
        )}

        <UIHSeparator title="waypoints" />

        {order.waypoints.map((waypoint) => (
          <div key={waypoint.id} className="mb-1">
            <UIWaypoint {...waypoint} orderUniqueCode={order.uniqueCode} />
          </div>
        ))}

        {order.orderStatus === NOrder.Status.AVAILABLE && (
          <>
            <UIHSeparator />
            <div className="d-flex">
              <Button
                style={{ marginLeft: '12px', flexGrow: 1, flexBasis: 0 }}
                color={'primary'}
                onClick={handleAttachOrderToUserVehicle}
              >
                Assign
              </Button>
              <Button
                style={{ marginLeft: '12px', flexGrow: 1, flexBasis: 0 }}
                color={'danger'}
                onClick={() => confirmOrderRejectionModalRef.current?.open()}
              >
                Reject
              </Button>
            </div>
          </>
        )}

        {[NOrder.Status.ACCEPTED, NOrder.Status.PROCESSING].includes(
          order.orderStatus,
        ) && (
          <>
            <UIHSeparator />
            <Button
              style={{ marginLeft: '12px' }}
              color={'warning'}
              onClick={handleReattachOrderToUserVehicle}
            >
              Reassign
            </Button>
          </>
        )}
      </UIContainer>

      <ConfirmOrderRejectionModal
        ref={confirmOrderRejectionModalRef}
        orderId={order.id}
      />
    </>
  );
});
