import { IRoute } from './interfaces';
import { ERoutes } from '../constants/route.constant';
import {
  DashboardPage,
  MapPage,
  OrdersPage,
  UsersPage,
  VehiclesPage,
  SettingsPage,
  ParcelTypesPage,
  VehicleTypesPage,
  ChatsPage,
  ReviewTagsPage,
  BarcodesPage,
  AssignedVehiclesPage,
  ReviewsPage,
  QuestionsAnswersPage,
} from '../pages';

export const userRoutes: IRoute[] = [
  { path: ERoutes.DASHBOARD, component: DashboardPage },
  { path: ERoutes.MAP, component: MapPage },
  { path: ERoutes.ORDERS, component: OrdersPage },
  { path: ERoutes.BARCODES, component: BarcodesPage },
  { path: ERoutes.USERS, component: UsersPage },
  { path: ERoutes.VEHICLES, component: VehiclesPage },
  { path: ERoutes.ASSIGNED_VEHICLES, component: AssignedVehiclesPage },
  { path: ERoutes.PARCEL_TYPES, component: ParcelTypesPage },
  { path: ERoutes.VEHICLE_TYPES, component: VehicleTypesPage },
  { path: ERoutes.SETTINGS, component: SettingsPage },
  { path: ERoutes.CHATS, component: ChatsPage },
  { path: ERoutes.CHATS, component: ChatsPage },
  { path: ERoutes.REVIEW_TAGS, component: ReviewTagsPage },
  { path: ERoutes.FAQ, component: QuestionsAnswersPage },
  { path: ERoutes.REVIEWS, component: ReviewsPage },
];
