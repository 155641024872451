import { NParcel } from './parcels';

export namespace NWaypoint {
  export interface Entity {
    id: string;
    address: string;
    comment: string | null;
    contactName: string;
    contactPhoneNumber: string;
    isReceiverSignature: boolean | null;
    isVerifyReceiverID: boolean | null;
    lat: number;
    lng: number;
    waypointType: Type;
    waypointStatus: Status;
    history: History.Entity[];
    parcels: NParcel.Entity[];
    deliveryConfirmationFileId: string | null;
    createdAt: number;
  }

  export enum Type {
    DROP_OFF = 'DROP_OFF',
    PICK_UP = 'PICK_UP',
  }

  export enum Status {
    TO_WAYPOINT = 'TO_WAYPOINT',
    ON_WAYPOINT = 'ON_WAYPOINT',
    COMPLETED = 'COMPLETED',
  }

  export namespace History {
    export interface Entity {
      id: string;
      waypointStatus: Status;
      createdAt: number;
    }
  }
}
