import { useMemo } from 'react';
import { NVehicleFuelType } from '../../types';
import { GET_VEHICLE_FUEL_TYPE } from '../vehicle-fuel-types';
import { AppQueryOptions, useAppQuery } from '../../apollo';

interface Params
  extends AppQueryOptions<NVehicleFuelType.GetOne.Output, NVehicleFuelType.GetOne.Input> {
  id: string | null | undefined;
}

export const useVehicleFlueType = ({ id, ...options }: Params) => {
  const { data, loading, error } = useAppQuery<
    NVehicleFuelType.GetOne.Output,
    NVehicleFuelType.GetOne.Input
  >(GET_VEHICLE_FUEL_TYPE, {
    skip: !id,
    variables: {
      input: {
        id: id ?? '',
      },
    },
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const vehicleFuelType = useMemo(
    () => data?.vehicleFuelType ?? null,
    [data?.vehicleFuelType],
  );

  return { vehicleFuelType, loading, error };
};
