import React, { memo, TransitionEvent, useCallback } from 'react';

export const Preloader = memo(() => {
  const handleTransitionEnd = useCallback((e: TransitionEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.display = 'none';
    }
  }, []);

  return (
    <div id="preloader" onTransitionEnd={handleTransitionEnd}>
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  );
});
