import React, {
  KeyboardEvent,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

interface MessageInputProps {
  onSend(text: string): void;
}

export interface MessageInputRef {
  value: string;
  clear(): void;
}

export const MessageInput = memo(
  forwardRef<MessageInputRef, MessageInputProps>(({ onSend }, ref) => {
    const [value, setValue] = useState('');

    useImperativeHandle(ref, () => ({
      value,
      clear() {
        setValue('');
      },
    }));

    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
          onSend(value);
        }
      },
      [onSend, value],
    );

    return (
      <input
        placeholder="Enter message..."
        className="chat-room__input"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    );
  }),
);
