import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { UIBreadcrumbs } from '../../../components/ui';
import { RenderReviewsPage } from './RenderReviewsPage';
import { pageTitle } from '../../../utilities/pageTitle';

export const ReviewsPage = memo(() => (
  <>
    <Helmet>
      <title>{pageTitle('Reviews')}</title>
    </Helmet>

    <UIBreadcrumbs title="Reviews" />

    <RenderReviewsPage />
  </>
));
