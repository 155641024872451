import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearchQuery } from '../../../../../../../hooks';
import { NChat } from '../../../../../../../types';
import { ESearchQuery } from '../../../../../../../constants/query.constant';
import { useChats } from '../../../../../../../queries';
import { UIBadge, UIButton, UIButtonGroup } from '../../../../../../../components/ui';
import { findUnreadMessages } from '../../../../../../../utilities/findUnreadMessages';
import { useAuth } from '../../../../../../../context/Auth';

export const TabHeader = memo(() => {
  const history = useHistory();
  const { profile } = useAuth();

  const { chats: orderSupportChats } = useChats({ type: NChat.Type.ORDER_SUPPORT });
  const { chats: employeeChats } = useChats({ type: NChat.Type.EMPLOYEE });

  const searchQuery = useSearchQuery();
  const chatTab = searchQuery.get(ESearchQuery.CHAT_TAB);

  const navigateTo = useCallback(
    (type: NChat.Tab) => {
      searchQuery.set(ESearchQuery.CHAT_TAB, type);
      history.push({ search: searchQuery.toString() });
    },
    [history, searchQuery],
  );

  useEffect(() => {
    if (!chatTab) {
      navigateTo(NChat.Tab.ORDER_SUPPORT);
    }
  }, [chatTab, navigateTo]);

  const numberOfUnreadMessagesInSupportChats = useMemo(() => {
    return orderSupportChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.ORDER_SUPPORT) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [profile?.id, orderSupportChats]);

  const numberOfUnreadMessagesInEmployeeChats = useMemo(() => {
    return employeeChats.reduce((prev, { type, messages }) => {
      if (type === NChat.Type.EMPLOYEE) {
        return prev + findUnreadMessages(messages, profile?.id).length;
      }
      return prev;
    }, 0);
  }, [employeeChats, profile?.id]);

  return (
    <UIButtonGroup className="chat-list__header">
      <UIButton
        selected={chatTab === NChat.Tab.ORDER_DRIVER}
        onClick={() => navigateTo(NChat.Tab.ORDER_DRIVER)}
      >
        Or. Driver
      </UIButton>
      <UIButton
        selected={chatTab === NChat.Tab.ORDER_SUPPORT}
        onClick={() => navigateTo(NChat.Tab.ORDER_SUPPORT)}
      >
        Or. Support
        <UIBadge
          value={numberOfUnreadMessagesInSupportChats}
          position="right-top"
          size="small"
        />
      </UIButton>
      <UIButton
        selected={chatTab === NChat.Tab.EMPLOYEE}
        onClick={() => navigateTo(NChat.Tab.EMPLOYEE)}
      >
        Employee
        <UIBadge
          value={numberOfUnreadMessagesInEmployeeChats}
          position="right-top"
          size="small"
        />
      </UIButton>
    </UIButtonGroup>
  );
});
