import { gql } from '@apollo/client';

export const DELETE_REVIEW_TAG = gql`
  mutation deleteReviewTag($input: RemoveReviewTagInput!) {
    removeReviewTag(input: $input) {
      id
      name
    }
  }
`;
