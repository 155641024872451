import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_EXPECTED_REVENUE } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardCardLostOrders = memo(() => {
  const [timePeriod, setTimePeriod] = useState(NAnalytics.TimePeriod.CURRENT_WEEK);

  const { data, loading } = useQuery<
    NAnalytics.Static.GetForLostOrdersExpectedRevenue.Output,
    NAnalytics.Static.GetForLostOrdersExpectedRevenue.Input
  >(GET_ANALYTICS_STATIC_FOR_LOST_ORDERS_EXPECTED_REVENUE, {
    variables: {
      input: {
        timePeriod,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as NAnalytics.TimePeriod);
  }, []);

  let value =
    data?.analyticsStaticForLostOrdersExpectedRevenue.currentPeriodExpectedRevenue.toFixed(
      2,
    );
  let percent =
    data?.analyticsStaticForLostOrdersExpectedRevenue
      .percentExpectedRevenuePreviousToCurrent;

  return (
    <Card className="dashboard-card bg_red">
      <CardBody>
        <CardTitle className="dashboard-card__title">Lost Orders</CardTitle>

        <div className="dashboard-card__content">
          {loading ? (
            <div className="spinner-border spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="dashboard-card__value">
              {value !== undefined ? `${value} Euro` : 'No data'}
            </span>
          )}
          <span className="dashboard-card__percentages">
            {percent
              ? percent === 0
                ? '0%'
                : percent > 0
                ? `+${percent.toFixed(2)}%`
                : `${percent.toFixed(2)}%`
              : 'N/A'}
          </span>
        </div>

        <div className="d-flex">
          <select className="dashboard-select" onChange={handleChange}>
            <option value={NAnalytics.TimePeriod.CURRENT_WEEK}>this week</option>
            <option value={NAnalytics.TimePeriod.CURRENT_MONTH}>this month</option>
            <option value={NAnalytics.TimePeriod.CURRENT_YEAR}>this year</option>
          </select>
          <span className="dashboard-select-icon">
            <SvgExpandMore />
          </span>
        </div>
      </CardBody>
    </Card>
  );
});
