import React, { memo } from 'react';
import { FProvider } from '../../../../../libs/FForm';
import { CreateBarcodeSidebar } from './CreateBarcode';
import { BarcodeSidebar } from './Barcode';

export const BarcodeSidebars = memo(() => (
  <>
    <BarcodeSidebar />
    <FProvider>
      <CreateBarcodeSidebar />
    </FProvider>
  </>
));
