import React, { memo } from 'react';
import { useHandleClear } from '../../../../../../libs/FForm';
import { Sidebar } from '../../../Sidebar';
import { ESidebar } from '../../../../../../constants/sidebar.constant';
import { RenderUserVehicleSidebar } from './RenderUserVehicleSidebar';

export const UserVehicleSidebar = memo(() => {
  const handleClear = useHandleClear();

  return (
    <Sidebar name={ESidebar.USER_VEHICLE} onClose={handleClear} width={400}>
      <RenderUserVehicleSidebar />
    </Sidebar>
  );
});
