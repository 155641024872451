import { gql } from '@apollo/client';

export const CREATE_QUESTION_ANSWER = gql`
  mutation createQuestionAnswer($input: CreateQuestionAnswerInput!) {
    createQuestionAnswer(input: $input) {
      id
      answerDe
      answerEn
      questionDe
      questionEn
      descriptionDe
      descriptionEn
      backgroundColor
      titleColor
      descriptionColor
      buttonTitleColor
      circleColor
    }
  }
`;
