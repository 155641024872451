import React, { memo } from 'react';
import { GoogleMapDirections } from '../../../../components/GoogleMapDirections';
import { Colors } from '../../../../constants/color.constant';
import { useOrderWaypoints } from '../../../../queries';

interface OrderRouteProps {
  orderId: string;
}

export const OrderRoute = memo<OrderRouteProps>(({ orderId }) => {
  const { waypoints } = useOrderWaypoints({
    orderId,
  });

  if (waypoints.length) {
    return (
      <GoogleMapDirections
        cacheRecordId={orderId}
        waypoints={waypoints}
        strokeColor={Colors.ORDER_ROUTE}
      />
    );
  }

  return null;
});
