import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Chart } from 'chart.js';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_DYNAMIC_FOR_COMPLETED_VS_LOST_ORDERS } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardChartCompletedOrdersVsLostOrders = memo(() => {
  const cnvRef = useRef<HTMLCanvasElement>(null);

  const [timePeriod, setTimePeriod] = useState(NAnalytics.TimePeriod.CURRENT_WEEK);

  const { data } = useQuery<
    NAnalytics.Dynamic.GetForCompletedVsLostOrders.Output,
    NAnalytics.Dynamic.GetForCompletedVsLostOrders.Input
  >(GET_ANALYTICS_DYNAMIC_FOR_COMPLETED_VS_LOST_ORDERS, {
    variables: {
      input: {
        timePeriod,
      },
    },
  });

  useEffect(() => {
    if (!cnvRef.current || !data?.analyticsDynamicForCompletedVsLostOrders) return;

    const value = [
      ...data.analyticsDynamicForCompletedVsLostOrders.dynamicCompletedVsLostOrdersStats,
    ];

    sortByMonth(value);

    const chart = new Chart(cnvRef.current, {
      type: 'bar',
      data: {
        labels: value.map((x) => x.period),
        datasets: [
          {
            label: 'Completed Orders',
            data: value.map((x) => x.completedOrders),
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Lost Orders',
            data: value.map((x) => x.lostOrders),
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
        ],
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data?.analyticsDynamicForCompletedVsLostOrders]);

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as NAnalytics.TimePeriod);
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>Completed Orders vs Lost Orders</CardTitle>

        <div className="d-flex">
          <select className="dashboard-select text-black" onChange={handleChange}>
            <option value={NAnalytics.TimePeriod.CURRENT_WEEK}>this week</option>
            <option value={NAnalytics.TimePeriod.CURRENT_MONTH}>this month</option>
            <option value={NAnalytics.TimePeriod.CURRENT_YEAR}>this year</option>
          </select>
          <span className="dashboard-select-icon fill-black">
            <SvgExpandMore />
          </span>
        </div>

        <div className="dashboard-bar-chart">
          <canvas ref={cnvRef} />
        </div>
      </CardBody>
    </Card>
  );
});

const sortByMonth = (
  arr: NAnalytics.Dynamic.GetForCompletedVsLostOrders.Output['analyticsDynamicForCompletedVsLostOrders']['dynamicCompletedVsLostOrdersStats'],
) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  arr.sort((a, b) => months.indexOf(a.period) - months.indexOf(b.period));
};
