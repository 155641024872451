import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      email
      firstName
      lastName
      isVerifyEmail
      isVerifyPhoneNumber
      phoneNumber
      photoFileId
      licenceID
      userRole
      isBlocked
      companyInfo {
        companyName
        companyAddress
      }
    }
  }
`;
