import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { NAnalytics } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_STATIC_FOR_AVERAGE_ORDERS } from '../../../../../queries';
import { SvgExpandMore } from '../../../../../assets/icons';

export const DashboardCardAverageNumberOfOrders = memo(() => {
  const [unit, setUnit] = useState(NAnalytics.OrdersPerUnit.WEEK);

  const { data, loading } = useQuery<
    NAnalytics.Static.GetForAverageOrders.Output,
    NAnalytics.Static.GetForAverageOrders.Input
  >(GET_ANALYTICS_STATIC_FOR_AVERAGE_ORDERS, {
    variables: {
      input: {
        unit,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.target.value as NAnalytics.OrdersPerUnit);
  }, []);

  let value = data?.analyticsStaticForAverageOrders.averageOrders.toFixed(2);

  return (
    <Card className="dashboard-card bg_blue">
      <CardBody>
        <CardTitle className="dashboard-card__title">Average Number of Orders</CardTitle>

        <div className="dashboard-card__content">
          {loading ? (
            <div className="spinner-border spinner-border-sm text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="dashboard-card__value">{value ?? 'No data'}</span>
          )}
        </div>

        <div className="d-flex">
          <select className="dashboard-select" onChange={handleChange}>
            <option value={NAnalytics.OrdersPerUnit.DAY}>per day</option>
            <option value={NAnalytics.OrdersPerUnit.WEEK}>per week</option>
            <option value={NAnalytics.OrdersPerUnit.MONTH}>per month</option>
            <option value={NAnalytics.OrdersPerUnit.YEAR}>per year</option>
            <option value={NAnalytics.OrdersPerUnit.CUSTOMER}>per user</option>
            <option value={NAnalytics.OrdersPerUnit.DRIVER}>per driver</option>
          </select>
          <span className="dashboard-select-icon">
            <SvgExpandMore />
          </span>
        </div>
      </CardBody>
    </Card>
  );
});
