import React from 'react';

const SvgPackage = () => (
  <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none">
    <g clipPath="url(#clip0_1654_188)">
      <path
        d="M19.4349 0.12923L1.5233 8.77626C1.29869 8.8847 1.15604 9.11204 1.15604 9.36142V30.6386C1.15604 30.888 1.29877 31.1153 1.5233 31.2238L19.4349 39.8708C19.7919 40.0431 20.208 40.0431 20.5649 39.8708L38.4766 31.2238C38.7012 31.1153 38.8438 30.888 38.8438 30.6386V9.36142C38.8438 9.11204 38.7011 8.8847 38.4766 8.77626L20.5649 0.12923C20.208 -0.0430359 19.7919 -0.0430359 19.4349 0.12923Z"
        fill="#FFE17D"
      />
      <path
        d="M1.26408 9.00571C1.1958 9.10969 1.15604 9.23211 1.15604 9.36141V30.6386C1.15604 30.888 1.29877 31.1153 1.5233 31.2238L19.4349 39.8707C19.6134 39.9569 19.8067 39.9999 19.9999 39.9999V18.0506L1.26408 9.00571Z"
        fill="#FFC350"
      />
      <path
        d="M38.7359 9.00571C38.8041 9.10969 38.8439 9.23211 38.8439 9.36141V30.6386C38.8439 30.888 38.7012 31.1153 38.4766 31.2238L20.565 39.8707C20.3866 39.9569 20.1933 40 20 40V18.0506L38.7359 9.00571Z"
        fill="#FFD164"
      />
      <path
        d="M6.51634 29.5044C6.42181 29.5044 6.32533 29.4835 6.23462 29.4397L3.46923 28.1047C3.14564 27.9486 3.01048 27.5602 3.16658 27.2372C3.32205 26.9143 3.71165 26.7797 4.034 26.9346L6.79939 28.2697C7.12298 28.4258 7.25814 28.8142 7.10205 29.1372C6.99041 29.3693 6.75814 29.5044 6.51634 29.5044Z"
        fill="#FFE17D"
      />
      <path
        d="M9.06147 27.8476C8.96694 27.8476 8.87046 27.8267 8.77975 27.7829L3.47288 25.2212C3.14991 25.0651 3.01413 24.6768 3.17022 24.3538C3.32569 24.0315 3.71593 23.8963 4.03765 24.0511L9.34444 26.6129C9.66741 26.769 9.80319 27.1573 9.6471 27.4803C9.53554 27.7124 9.30327 27.8476 9.06147 27.8476Z"
        fill="#FFF6D8"
      />
      <path
        d="M32.3867 12.0604L13.585 2.97733L8.62875 5.37967L27.4727 14.4768L32.3867 12.0604Z"
        fill="#FF8087"
      />
      <path
        d="M27.4727 14.4768V21.5242C27.4727 21.7637 27.7231 21.9209 27.9389 21.8168L32.0195 19.8469C32.2441 19.7384 32.3868 19.5111 32.3868 19.2617V12.0605L27.4727 14.4768Z"
        fill="#E6646E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1654_188">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPackage;
